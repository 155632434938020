import { dispatch } from 'redux/hooks';

import { API_TENANT } from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';

import slice from './slice';

const { getLoading, getLogoSuccess, hasError } = slice.actions;

export const fetchGetLogo = () => async () => {
  try {
    dispatch(getLoading(true));

    const response = await DataService.getJson(API_TENANT());

    dispatch(getLogoSuccess(response.logo));
    dispatch(getLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(getLoading(false));
  }
};

export default {
  fetchGetLogo,
};
