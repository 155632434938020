import { useState } from 'react';
import { Box, Dialog, Switch, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { CustomButton } from '../../shared';

import usePauseModal from './hooks/usePauseModal';

export interface IPauseModalProps {
  index: number;
}

const PauseModal = () => {
  const { onClose, onConfirm } = usePauseModal();
  const [pause, setPause] = useState<boolean>(true);

  return (
    <Dialog data-test="pause-modal" open onClose={onClose} maxWidth="lg">
      <Box padding="20px 40px">
        <Box display="flex" alignItems="center" justifyContent="center" marginBottom="20px">
          <Switch
            data-test="pause-modal-switch-pause"
            checked={pause}
            onChange={() => {
              setPause((prev) => !prev);
            }}
            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
          />
          <Typography>Pause this filter</Typography>
        </Box>
        <Permission permission={Permissions.changeDecisionTreeFilter}>
          <Box display="flex" alignItems="center">
            <CustomButton data-test="pause-modal-button-cancel" onClick={onClose} variant="outlined">
              Cancel
            </CustomButton>
            <CustomButton data-test="pause-modal-button-save" onClick={onConfirm}>
              Save
            </CustomButton>
          </Box>
        </Permission>
      </Box>
    </Dialog>
  );
};

export default PauseModal;
