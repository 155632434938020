import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { IValidationTreeFormValues } from '../../../components/dataModels/validationTree';
import { useValidationProviderState } from '../../../Providers/ValidationTreeProvider';

import classes from './validationTree.module.scss';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 314,
      left: 79,
    },
  },
};

const FilterSubItem = ({
  dataTest,
  register,
  rootName,
  control,
  index,
  rootId,
  serviceName,
  lists,
  campaignFields,
  // externalServices, @deprecated
  externalServiceResponseFields,
  // experianFields,
  conditions,
  listConditions,
  watch,
  // onExternalServiceSelect, @deprecated
  mockFieldData,
}: // TODO integrate interface for VT sub values
any) => {
  const { setRemove, setAppend, setUpdate } = useValidationProviderState();

  const getPlaceholderText = () =>
    // eslint-disable-next-line no-nested-ternary
    serviceName === 'ExternalServices' ? 'Response Fields' : serviceName === 'ListIntegration' ? 'Lists' : 'Field';

  const getFilterTypeData = (id: string) => {
    const responseFieldsValue = externalServiceResponseFields?.find(
      // TODO - Types should be removed after integration component interface
      (item: { id: number | string }) => item.id === +id,
    )?.value;

    switch (serviceName) {
      case 'ExternalServices':
        return responseFieldsValue.length ? responseFieldsValue : mockFieldData;
      case 'ListIntegration':
        return lists.length ? lists : mockFieldData;
      case 'CampaignFields':
        return campaignFields.length ? campaignFields : mockFieldData;
      default:
        return mockFieldData;
    }
  };

  const { fields, remove, append, update } = useFieldArray<IValidationTreeFormValues>({
    control,
    name: `${rootName}.${index}.filters`,
  });

  const getFieldHeader = (service: string) => {
    switch (service) {
      case 'ExternalServices':
        return 'Response Fields';
      case 'ListIntegration':
        return 'List Integration';
      case 'CampaignFields':
        return 'Product Fields';
      case 'Experian':
        return 'Response Fields';
      default:
        return 'Fields';
    }
  };

  const getConditionsFields = (type?: number) => {
    if (type === 2) {
      return listConditions;
    }

    return conditions;
  };

  useEffect(() => {
    register(`${rootName}.${index}.filters`);
  }, [register, index]);

  useEffect(() => {
    if (append) {
      // FIXME type
      // @ts-ignore
      setAppend(append, rootId);
    }
  }, [append, rootId]);

  useEffect(() => {
    if (update) {
      // FIXME type
      // @ts-ignore
      setUpdate(update, rootId);
    }
  }, [update, rootId]);

  useEffect(() => {
    setRemove(remove, rootId);
  }, [remove, rootId]);

  return (
    <Box className={fields.length ? classes.filtersRoot : ''} key={rootId}>
      {fields.length ? (
        <Box
          className={classes.subHeader}
          sx={
            serviceName === 'ExternalServices'
              ? {
                  gridTemplateColumns: '1.6fr 1.6fr 1.2fr 1.4fr 1.8fr;',
                }
              : { gridTemplateColumns: '2fr 1.5fr 1.5fr 1.5fr' }
          }
        >
          {serviceName === 'ExternalServices' ? (
            <Typography className={classes.headerTitle}>Service Name</Typography>
          ) : null}

          {serviceName !== 'Expression' ? (
            <Typography className={classes.headerTitle}>{getFieldHeader(serviceName)}</Typography>
          ) : null}
          {serviceName === 'ListIntegration' ? (
            <Typography className={classes.headerTitle}>Product Fields</Typography>
          ) : null}
          {serviceName !== 'Expression' ? <Typography className={classes.headerTitle}>Condition</Typography> : null}
          {serviceName !== 'ListIntegration' ? <Typography className={classes.headerTitle}>Value</Typography> : null}
        </Box>
      ) : null}
      {fields?.map((item, filterIndex) => (
        <Box
          key={item.id}
          className={classes.filters}
          sx={
            serviceName !== 'Expression'
              ? {
                  gridTemplateColumns: '2.4fr 1.4fr 1.5fr 1.2fr',
                }
              : {
                  gridTemplateColumns: '5fr 1.5fr',
                }
          }
        >
          {filterIndex === 0 ? (
            <Typography className={classes.filtersTitle}>All of the following are true…</Typography>
          ) : (
            ''
          )}
          <FormControl
            className={classes.item}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: ' #1E1E2D',
                fontSize: '15px',
                marginRight: '10px',
              }}
            >
              And
            </Typography>
            <>
              {/* {serviceName === 'ExternalServices' ? ( @deprecated
                <Select
                  placeholder="Service Name"
                  fullWidth
                  {...register(`${rootName}.${index}.filters.${filterIndex}.Name`)}
                  defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.Name`)}
                  MenuProps={MenuProps}
                >
                  {externalServices?.map((option: { id: string; name: string }) => (
                    <MenuItem
                      onClick={() => {
                        onExternalServiceSelect(option, `${index}-${filterIndex}`);
                      }}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : null} */}
              {serviceName === 'Expression' ? (
                <FormControl fullWidth className={classes.subItem}>
                  <TextField
                    data-test={dataTest ? `${dataTest}-expression-value` : ''}
                    id={`${rootName}.${index}.filters.${filterIndex}.Expression`}
                    placeholder="Enter a value"
                    sx={{ borderRight: '1px solid #E2E8F0' }}
                    {...register(`${rootName}.${index}.filters.${filterIndex}.Expression`)}
                    fullWidth
                    disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                  />
                </FormControl>
              ) : (
                <Select
                  data-test={dataTest ? `${dataTest}-select-${serviceName}` : ''}
                  placeholder={getPlaceholderText()}
                  sx={{ whiteSpace: 'nowrap' }}
                  fullWidth
                  {...register(`${rootName}.${index}.filters.${filterIndex}.Field`)}
                  defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.Field`)}
                  MenuProps={MenuProps}
                  disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                >
                  {getFilterTypeData(watch(`${rootName}.${index}.filters.${filterIndex}.Name`)).map(
                    (filterItem: { id: string; name: string }) => {
                      if (filterItem.id === '0') {
                        return (
                          <MenuItem disabled key={filterItem.id}>
                            {filterItem.name}
                          </MenuItem>
                        );
                      }

                      return <MenuItem value={filterItem.id}>{filterItem.name}</MenuItem>;
                    },
                  )}
                </Select>
              )}
            </>
          </FormControl>
          {serviceName === 'ListIntegration' ? (
            <FormControl className={classes.item}>
              <Select
                data-test={dataTest ? `${dataTest}-select-product-field` : ''}
                placeholder="Product Field"
                sx={{ whiteSpace: 'nowrap' }}
                fullWidth
                {...register(`${rootName}.${index}.filters.${filterIndex}.CampaignField`)}
                defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.CampaignField`)}
                MenuProps={MenuProps}
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              >
                {(campaignFields.length ? campaignFields : mockFieldData)?.map(
                  (campaignItem: { id: string; name: string }) => (
                    <MenuItem value={campaignItem.id} disabled={campaignItem.id === '0'}>
                      {campaignItem.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          ) : null}
          {serviceName !== 'Expression' && (
            <FormControl className={classes.item}>
              <Select
                data-test={dataTest ? `${dataTest}-select-conditions` : ''}
                placeholder="Conditions"
                sx={{ whiteSpace: 'nowrap' }}
                defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.Condition`)}
                fullWidth
                {...register(`${rootName}.${index}.filters.${filterIndex}.Condition`)}
                MenuProps={MenuProps}
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              >
                {getConditionsFields(watch(`DecisionTree[${index}].FilterType`))?.map(
                  (condition: { id: string; name: string }) => (
                    <MenuItem value={condition.id}>{condition.name}</MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
          {serviceName !== 'ListIntegration' && serviceName !== 'Expression' && (
            <FormControl fullWidth className={classes.subItem}>
              <TextField
                data-test={dataTest ? `${dataTest}-${serviceName}-value` : ''}
                id={`${rootName}.${index}.filters.${filterIndex}.Value`}
                placeholder="Enter a value"
                sx={{ borderRight: '1px solid #E2E8F0' }}
                {...register(`${rootName}.${index}.filters.${filterIndex}.Value`)}
                fullWidth
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              />
            </FormControl>
          )}
          <Permission permission={Permissions.changeDecisionTreeFilter}>
            <FormControl
              className={classes.subItem}
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 0.5fr 0.5fr',
                flexWrap: 'nowrap',
                flexDirection: 'row',
              }}
              fullWidth
            >
              <Box
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRight: '1px solid #E2E8F0',
                }}
              >
                <IconButton
                  data-test={dataTest ? `${dataTest}-button-remove` : ''}
                  onClick={() => {
                    remove(filterIndex);
                  }}
                >
                  <DeleteOutlineOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </IconButton>
              </Box>
            </FormControl>
          </Permission>
        </Box>
      ))}
    </Box>
  );
};

export default FilterSubItem;
