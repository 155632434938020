import { ApplicationTabs, ILeadsState } from '../../../types';

export const getInitialState = (): ILeadsState => ({
  error: null,
  isLeadsListLoading: false,
  isLeadsDetailsLoading: false,
  leadsDetails: null,
  leads: null,
  leadsCount: 0,
  leadsFilters: null,
  isLeadsJourneyLoading: false,
  journey: null,
  journeyStatus: false,
  applicationJourney: null,
  applicationJourneyLoading: false,
  logs: [],
  logsLoading: false,
  funds: [],
  fundsLoading: false,
  rerunLoading: false,
  rerunSteps: [],
  documentFile: undefined,
  fetchFileLoading: false, // deprecated remove later
  leadDetailsInitialTab: ApplicationTabs.generalInfo,
  documentsMenu: [],
  documentsMenuLoading: false,
  documentsList: [],
  documentsListLoading: false,
  documentsUploadTypes: [],
  documentsUploadLoading: null,
  filePreviewLoading: {},
  fileDownloadLoading: {},
  revertLoading: false,
  resendEmailLoading: false,
  resendEmail: '',
  notesAddOrEditLoading: false,
  notesList: null,
  notesListLoading: false,
  notesUsers: null,
  generalInformationData: null,
});
