import { Box, FormHelperText, InputLabel, TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface ICustomTextAreaProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  name?: string;
  errorMessage?: string;
}

const StyledTextareaAutosize = styled(TextareaAutosize)({
  padding: '10px 10px',
  minWidth: '100%',
  maxWidth: '100%',
  border: '1px solid #E2E8F0',
  borderRadius: '12px',
  minHeight: '94px',
  backgroundColor: '#F4F7FE',
  maxHeight: '300px',
  overflow: 'auto',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '15px',
  '&::placeholder': {
    opacity: 0.6,
  },
});

const CustomTextArea = ({
  onChange,
  label,
  placeholder,
  minRows,
  maxRows,
  name,
  errorMessage,
  ...props
}: ICustomTextAreaProps) => (
  <Box>
    <InputLabel sx={{ fontSize: '0.875rem!important', color: '#616161!important', fontWeight: '400!important' }}>
      {label}
    </InputLabel>
    <StyledTextareaAutosize
      {...props}
      minRows={minRows}
      maxRows={maxRows}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    />
    <FormHelperText error>{errorMessage}</FormHelperText>
  </Box>
);

export default CustomTextArea;
