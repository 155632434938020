import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { dispatch, useAppSelector } from '../redux/hooks';
import { campaignsSelector } from '../redux/slices/campaigns';
import { systemFieldsSelector } from '../redux/slices/systemFields';
import { viewsMiddleware } from '../redux/slices/views';
import { ICampaignField, ICampaignForm } from '../types/campaigns';
import { SeveritiesType } from '../types/snackbar';

import { Permissions } from './Permissions/constants';
import Permission, { hasPermission } from './Permissions/Permission';
import FormSelect, { IControlledSelectOptions } from './shared/Select/FormSelect';
import FormTextField from './shared/TextField/FormTextField';
import { CustomPlusButton } from './shared';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 314,
    },
  },
};

export const CreateCampaignForm = ({
  sectionName,
  isRequiredAll,
  isHashAll,
  data,
  mode,
  isUploadedFile,
  type,
}: {
  sectionName: string;
  isRequiredAll: boolean | null;
  isHashAll: boolean | null;
  data: ICampaignField[];
  mode: 'CREATE' | 'EDIT';
  setIsTableLoading: (value: boolean) => void;
  isUploadedFile?: boolean;
  type: 'reset' | 'update';
}) => {
  const validators = useAppSelector(campaignsSelector.validators);
  const timeFormats = useAppSelector(campaignsSelector.timeFormats);
  const isCampaignFieldsLoading = useAppSelector(campaignsSelector.isCampaignFieldsLoading);

  const systemFieldsList = useAppSelector(systemFieldsSelector.systemFieldsList);

  const {
    control,
    setValue,
    reset,
    watch,
    register,
    formState: { errors },
  } = useFormContext<ICampaignForm>();

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: sectionName,
  });

  const [systemFields, setSystemFields] = useState<IControlledSelectOptions[]>([{ label: 'None', value: 'None' }]);
  const currentCampaignById = useAppSelector(campaignsSelector.currentCampaignById);
  const createCampaignsWithStepper = useAppSelector(campaignsSelector.createCampaignsWithStepper);
  const getSystemFieldDefaultValue = (databaseField: string) =>
    systemFields?.find((item) => item.value === databaseField);

  const handleValidatorChange = (value: string, index: number) => {
    if (value.toString() === '4' || value.toString() === '3') {
      setValue(`${sectionName}.${index}.validatorSettings.0`, {
        name: 'DateTimeFormat',
        value: 'MM-dd-yyyy',
      });
    } else {
      setValue(`${sectionName}.${index}.validatorSettings`, []);
    }
  };

  const getDataTypeDefaultValue = (field: ICampaignField) => {
    if (field.validator && validators) {
      return field.validator;
    }

    return validators?.[0]?.id;
  };

  const onRowDelete = (index: number) => {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Row deleted successfully',
        },
      }),
    );
    remove(index);
  };

  const appendCampaign = () => {
    append({
      campaignId: (mode === 'CREATE' ? createCampaignsWithStepper?.campaignName : currentCampaignById?.id) ?? '',
      templateField: '',
      databaseField: '',
      parentField: sectionName,
      description: '',
      isRequired: false,
      isHash: false,
      validator: 0,
      name: '',
      status: '',
      validatorSettings: [],
      id: '0',
    });
  };

  useEffect(() => {
    const newFields = systemFieldsList?.length ? systemFieldsList.map((el) => ({ label: el, value: el })) : [];

    setSystemFields([...systemFields, ...newFields]);
  }, []);

  useEffect(() => {
    if (register && sectionName) {
      register(`${sectionName}`);
    }
  }, [register, sectionName]);

  useEffect(() => {
    if (data && data?.length) {
      if (type === 'reset') {
        reset({ [sectionName]: data });
      }

      if (type === 'update') {
        data?.forEach((item, index) => {
          update(index, data[index]);
        });
      }
    }
  }, [isUploadedFile, isCampaignFieldsLoading]);

  useEffect(() => {
    if (typeof isRequiredAll === 'boolean') {
      for (let i = 0; i < fields.length; i += 1) {
        setValue(`${sectionName}.${i}.isRequired`, isRequiredAll);
      }
    }
  }, [isRequiredAll, fields.length, sectionName, setValue]);

  useEffect(() => {
    if (typeof isHashAll === 'boolean') {
      for (let i = 0; i < fields.length; i += 1) {
        setValue(`${sectionName}.${i}.isHash`, isHashAll);
      }
    }
  }, [isHashAll, fields.length, sectionName, setValue]);

  return (
    <>
      <TableRow
        sx={{
          height: 'auto !important',
        }}
      >
        <TableCell sx={{ borderBottom: 'none' }}>
          <Typography sx={{ marginTop: '30px' }}>{sectionName.toUpperCase()}</Typography>
        </TableCell>
      </TableRow>
      {(fields as ICampaignField[]).map((field: ICampaignField, index: number) => (
        <TableRow
          data-test={`campaign-details-page-tab-integrations-table-section-${sectionName}-id-${field.id}`}
          key={field?.id as keyof ICampaignField}
          sx={{
            height: '61px !important',
          }}
        >
          <TableCell sx={{ whiteSpace: 'nowrap', position: 'relative' }}>
            <Box component="span" className="vertical-campaign-line" sx={{ right: 0 }} />
            <FormControl>
              <FormTextField
                name={`${sectionName}.${index}.templateField`}
                permission={Permissions.editIntegrationFields}
                placeholder="Template Field"
                required
              />
              <FormHelperText error>
                {errors[sectionName]?.[index]?.templateField ? 'This field is required' : ''}
              </FormHelperText>
            </FormControl>
          </TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <Box component="span" className="vertical-campaign-line" sx={{ right: 0 }} />
            <FormSelect
              options={systemFields}
              name={`${sectionName}.${index}.databaseField`}
              permission={Permissions.editIntegrationFields}
              defaultValue={getSystemFieldDefaultValue(field.databaseField) ?? systemFields[0]}
              menuItemsSx={{
                maxHeight: '200px',
              }}
            />
          </TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <Box component="span" className="vertical-campaign-line" sx={{ right: 0 }} />
            <FormControl>
              <Select
                placeholder="Data Type"
                defaultValue={getDataTypeDefaultValue(field)}
                disabled={!hasPermission(Permissions.editIntegrationFields)}
                IconComponent={ExpandMoreIcon}
                inputProps={{
                  style: { display: 'none' },
                }}
                {...register(`${sectionName}.${index}.validator` as const, {
                  required: false,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleValidatorChange(event.target.value, index),
                })}
                className={errors[sectionName]?.[index]?.dataType ? 'error' : ''}
                MenuProps={MenuProps}
              >
                {validators?.map((item: { id: number; name: string }) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {/* eslint-disable-next-line no-nested-ternary */}
              {watch(`${sectionName}.${index}.validator`) === 4 || watch(`${sectionName}.${index}.validator`) === 3 ? (
                timeFormats && timeFormats?.length ? (
                  <FormControl>
                    <Select
                      disabled={!hasPermission(Permissions.editIntegrationFields)}
                      defaultValue={watch(`${sectionName}.${index}.validatorSettings.0.value`)}
                      {...register(`${sectionName}.${index}.validatorSettings.0.value`, {
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          setValue(`${sectionName}.${index}.validatorSettings.0`, {
                            name: 'DateTimeFormat',
                            value: event.target.value,
                          });
                        },
                      } as const)}
                      sx={{
                        height: '38px',
                        padding: '0',
                        margin: '4px 10px',
                        color: 'rgba(0, 0, 0, 0.8)',
                      }}
                    >
                      {timeFormats?.map((item: string) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              ) : null}
              <FormHelperText error>{errors[sectionName]?.[index]?.dataType ? 'error' : ' '}</FormHelperText>
            </FormControl>
          </TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', position: 'relative' }}>
            <Box component="span" className="vertical-campaign-line" sx={{ right: 0 }} />
            <FormControl>
              <FormTextField
                name={`${sectionName}.${index}.description`}
                permission={Permissions.editIntegrationFields}
                placeholder="Description"
              />
              <FormHelperText error>{errors[sectionName]?.[index]?.description ? 'error' : ''}</FormHelperText>
            </FormControl>
          </TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap', paddingLeft: '15px' }}>
            <Box component="span" className="vertical-campaign-line" sx={{ right: 0 }} />
            <FormControlLabel
              sx={{ marginLeft: '16px' }}
              control={
                <Checkbox
                  disabled={!hasPermission(Permissions.editIntegrationFields)}
                  checked={watch(`${sectionName}.${index}.isRequired`)}
                  {...register(`${sectionName}.${index}.isRequired`)}
                />
              }
              label="Required"
            />
          </TableCell>
          {/* eslint-disable-next-line react/no-array-index-key */}
          <TableCell sx={{ whiteSpace: 'nowrap', paddingLeft: '15px' }} key={`${sectionName}.${index}.isHash`}>
            <FormControl id={`${sectionName}.${index}.isHash`}>
              <Box alignItems="center" display="flex" justifyContent="start">
                <FormControlLabel
                  sx={{ marginLeft: '16px' }}
                  control={
                    <Checkbox
                      disabled={!hasPermission(Permissions.editIntegrationFields)}
                      checked={watch(`${sectionName}.${index}.isHash`)}
                      {...register(`${sectionName}.${index}.isHash`)}
                    />
                  }
                  label="Is Hash"
                />
              </Box>
              <FormHelperText error>{errors[sectionName]?.[index]?.isHash ? 'error' : ''}</FormHelperText>
            </FormControl>
          </TableCell>
          <Permission permission={Permissions.editIntegrationFields}>
            <TableCell>
              <Box component="span" className="vertical-campaign-line" sx={{ left: 0 }} />
              <FormControl
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <IconButton
                  data-test={`campaign-details-page-tab-integrations-table-section-${sectionName}-delete-button}`}
                  color="inherit"
                  size="large"
                  onClick={() => onRowDelete(index)}
                >
                  <DeleteOutlineOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </IconButton>
              </FormControl>
            </TableCell>
          </Permission>
        </TableRow>
      ))}
      <Permission permission={Permissions.editIntegrationFields}>
        <TableRow
          sx={{
            padding: '0 !important',
            height: 'auto !important',
            display: 'flex',
            border: 'none !important',
            marginTop: '16px',
            '&:last-child': {
              marginBottom: '30px',
            },
          }}
        >
          <TableCell sx={{ borderBottom: 'none', display: 'flex' }}>
            <CustomPlusButton
              sx={{ padding: 0, margin: 0, display: 'flex', justifyContent: 'flex-start', width: '100%' }}
              size="small"
              onClick={() => appendCampaign()}
            />
          </TableCell>
        </TableRow>
      </Permission>
    </>
  );
};
