import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import AffiliateChannelHistory from 'components/AffiliateChannelHistory';
import { Permissions } from 'components/Permissions/constants';
import TitleWithBreadcrumbs from 'components/shared/TitleWithBreadcrumbs';
import Loading from 'components/UI/Loading';
import BaseTabs from 'components/UI/Tabs';
import AffiliatesChannelCreateOrEditPage from 'pages/Affiliates/AffiliateChannelCreateOrEditPage';
import { affiliatesSelector } from 'redux/slices/affiliates';

import PostingDetails from '../../../../components/PostingDetails';
import { dispatch, useAppSelector } from '../../../../redux/hooks';
import { affiliatesMiddleware } from '../../../../redux/slices/affiliates';
import { countriesMiddleware, countriesSelector } from '../../../../redux/slices/countries';
import ValidationTreeTable from '../../Validation/ValidationTreeTable';

export enum ChannelTabs {
  information = 'INFORMATION',
  postingDetails = 'POSTING_DETAILS',
  decisionTrees = 'DECISION_TREES',
  history = 'HISTORY',
}

const AffiliatesChannelDetails = () => {
  const [tabValue, setTabValue] = useState<number | string>(ChannelTabs.information);
  const channel = useAppSelector(affiliatesSelector.channel);
  const isLoading = useAppSelector(affiliatesSelector.isLoading);
  const { id: affiliateId } = useParams();

  const countries = useAppSelector(countriesSelector.countries);

  useEffect(() => {
    if (!countries || !countries.length) {
      dispatch(countriesMiddleware.fetchCountries());
    }
  }, [countries]);

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchCurrentAffiliateChannelById(affiliateId ?? '0'));
  }, [affiliateId]);

  if (isLoading) {
    return <Loading />;
  }

  if (!channel) {
    return (
      <Box height="100%" width="100%">
        <Typography variant="h1">Data not Found</Typography>
      </Box>
    );
  }

  return (
    <Box height="100%" width="100%">
      <Box display="flex" alignItems="start" flexDirection="column" height="100%">
        <TitleWithBreadcrumbs
          title="Source Channels List"
          dataTest="affiliate-channel-details-header-source-channels-list"
          link="/sources-channel"
          linkTitle="Source Channels List"
          currentTitle={`Source Channel ${affiliateId}`}
        />
        <Grid
          item
          container
          xs={12}
          sx={{
            backgroundColor: 'white',
            borderRadius: '17px',
            padding: '20px 30px',
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BaseTabs
            component="div"
            tabs={[
              { id: 'INFORMATION', label: 'Information', permission: Permissions.viewSourceChannelInformation },
              {
                id: 'POSTING_DETAILS',
                label: 'Posting Details',
                permission: Permissions.viewSourceChannelPostingDetails,
              },
              { id: 'DECISION_TREES', label: 'Decision Trees', permission: Permissions.viewSourceChannelDecisionTree },
              { id: 'HISTORY', label: 'History', permission: Permissions.viewSourceChannelHistory },
            ]}
            dataTest={[
              'affiliate-channel-details-button-tab-information',
              'affiliate-channel-details-button-tab-posting-details',
              'affiliate-channel-details-button-tab-decision-trees',
              'affiliate-channel-details-button-tab-history',
            ]}
            tabValue={tabValue}
            sx={{ width: '100%', maxHeight: '50px' }}
            setTabValue={setTabValue}
          />
          {tabValue === ChannelTabs.information && (
            <AffiliatesChannelCreateOrEditPage
              mode="EDIT"
              defaultValue={{
                campaignId: `${channel?.campaignId}`,
                affiliateId: `${channel?.affiliateId}`,
                channelName: channel?.name ?? '',
                dataFormat: channel?.dataFormat ?? 1,
                overallCAP: channel?.overall ?? false,
                CAP: channel?.cap ?? 0,
                timeout: channel?.timeout ?? '',
                notes: channel?.notes ?? '',
                status: Number(channel?.status) ?? 0,
              }}
            />
          )}
          {tabValue === ChannelTabs.postingDetails ? (
            <PostingDetails
              dataTest="affiliate-channel-details-tab-posting-details"
              campaignId={`${channel.campaignId}`}
            />
          ) : null}
          {tabValue === ChannelTabs.decisionTrees ? <ValidationTreeTable /> : null}
          {tabValue === ChannelTabs.history ? <AffiliateChannelHistory /> : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default AffiliatesChannelDetails;
