import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { IDocumentList, ILeadsDetails } from '@types';

import DocumentsTab from '../../components/LeadsDocuments/DocumentsTab';
import SideTab from '../../components/LeadsDocuments/SideTab';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';

export const DocumentTabs: { [key: string]: string } = {
  LoanDocument: 'Loan Document',
  CreditReport: 'Credit Report',
  AdverseAction: 'Adverse Action',
  Misc: 'Misc',
  IncomeVerification: 'Income Verification',
  TitleVerification: 'Property Title Verification',
  IdentityVerification: 'Identity Verification',
  CarterCreditUnion: 'Carter Credit Union',
  FundingDocument: 'Funding Document',
  ProjectApproval: 'Project Approval',
};

export const DocumentTypes: { [key: string]: string } = {
  WageEarner: 'Wage Earner',
  SelfEmployedContractIncome: 'Self Employed Contract Income',
  RetirementIncome: 'Retirement Income',
  OtherIncomeVerification: 'Other (Rental, Child Support, Alimony, etc.)',
  ID: 'ID',
  Photo: 'Photo',
  SolarInstallmentContract: 'Solar Installment Contract',
  DetailedProjectInvoice: 'Detailed Project Invoice',
  PropertyTitle: 'Property Title',
  PropertyAppraisal: 'Property Appraisal',
};

const Documents = ({ lead }: { lead: ILeadsDetails | null }) => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [tabData, setTabData] = useState<IDocumentList[]>([]);
  const documentsMenu = useAppSelector(leadsSelector.documentsMenu);
  const documentsMenuLoading = useAppSelector(leadsSelector.documentsMenuLoading);
  const documentsList = useAppSelector(leadsSelector.documentsList);
  const documentListLoading = useAppSelector(leadsSelector.documentsListLoading);

  useEffect(() => {
    dispatch(leadsMiddleware.fetchDocumentsMenuItems());
    dispatch(leadsMiddleware.fetchDocumentsList(`${lead?.id}`));
  }, []);

  useEffect(() => {
    if (documentsList) {
      const array = documentsList.filter((list) => list.documentCategoryId === activeTab);

      setTabData(array);
    }
  }, [activeTab, documentsList]);

  useEffect(() => {
    if (documentsMenu && documentsMenu[0]) {
      setActiveTab(documentsMenu[0].id);
    }
  }, [documentsMenu]);

  if (documentsMenuLoading || documentListLoading) {
    return <Loading />;
  }

  if (!documentsMenu?.length) {
    return (
      <Box>
        <Title title="No Data" />
      </Box>
    );
  }

  return (
    <Grid container>
      <Grid item xs={2} marginTop="58px">
        {documentsMenu.map((el: { id: string; name: string }) => (
          <SideTab
            active={activeTab === el.id}
            label={DocumentTabs[el.name] ?? el.name}
            key={DocumentTabs[el.name] ?? el.name}
            onClick={(tabId: string) => setActiveTab(tabId)}
            tabId={el.id}
          />
        ))}
      </Grid>
      <Grid item xs={10} paddingLeft="32px" marginTop="38px">
        {documentsMenu ? (
          documentsMenu.map(
            (item) => activeTab === item.id && <DocumentsTab key={item?.id} data={tabData} leadId={lead?.id ?? ''} />,
          )
        ) : (
          <Box>No Data</Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Documents;
