import { ISettingsState } from '../../../types';

export const getInitialState = (): ISettingsState => ({
  error: null,
  qrInfo: null,
  settingsLoading: false,
  verificationError: '',
  verificationEnabled: false,
  settingsStatus: false,
  initializationLoading: false,
  platformSettings: null,
  platformSettingsLoading: false,
  tenantSettings: null,
  tenantSettingsLoading: false,
});
