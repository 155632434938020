import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { CustomButton } from '../../../components';
import { useGenerateTableData } from '../../../components/Hooks';
import { PagesSearch } from '../../../components/Layout/MainLayout/Header/SearchSection';
import { DenseTable } from '../../../components/Tables';
import { IActionMenuItem } from '../../../components/Tables/CustomDynamicTableDense';
import Loading from '../../../components/UI/Loading';
import MainCard from '../../../components/UI/MainCard';
import Title from '../../../components/UI/Title';
import { gridSpacing } from '../../../lib/constants/grid';
import { DecisionTreeTableHeaders } from '../../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../../redux/hooks';
import { affiliatesSelector } from '../../../redux/slices/affiliates';
import { validationTreeMiddleware, validationTreeSelector } from '../../../redux/slices/validationTree';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { IValidationTree } from '../../../types';

const ValidationTreeTable = () => {
  const [actionState, setActionState] = useState<string>('-1');

  const validationTreesById = useAppSelector(validationTreeSelector.validationTreesById);
  const isValidationTreesByIdLoading = useAppSelector(validationTreeSelector.isValidationTreesByIdLoading);
  const isValidationTreeDeleteLoading = useAppSelector(validationTreeSelector.isValidationTreeDeleteLoading);

  const channel = useAppSelector(affiliatesSelector.channel);
  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: validationTreesById.data,
    paginationCount: validationTreesById.itemsCount,
  });
  const navigate = useNavigate();

  const handleSearch = (text: string) => {
    if (channel) {
      if (text.length) {
        dispatch(validationTreeMiddleware.fetchSearchValidationTrees(text, `${channel?.id}`));
      } else {
        dispatch(validationTreeMiddleware.fetchAffiliateChannelTreesById(`${channel?.id}`));
      }
    }
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      validationTreeMiddleware.fetchAffiliateChannelTreesById(
        `${channel?.id}`,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );

    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      validationTreeMiddleware.fetchAffiliateChannelTreesById(
        `${channel?.id}`,
        1,
        newRowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );

    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      validationTreeMiddleware.fetchAffiliateChannelTreesById(
        `${channel?.id}`,
        page + 1,
        rowsPerPage,
        property,
        newOrder,
      ),
    );
  };

  const onCreateTree = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateChannelRuleNameModal,
        props: { id: channel?.id },
      }),
    );
  };

  const actionMenuItemsData: IActionMenuItem[] = [
    ...(hasPermission(Permissions.changeDecisionTreeFilter)
      ? [
          {
            title: 'Edit',
            icon: <EditOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
            action: (id: string | number = actionState) => {
              if (validationTreesById?.data && validationTreesById?.data.length) {
                if (typeof id === 'string') {
                  dispatch(
                    validationTreeMiddleware.fetchSetValidationTree(
                      validationTreesById?.data?.find((el: { id: string }) => el.id === id) as IValidationTree,
                    ),
                  );
                  dispatch(validationTreeMiddleware.fetchGetValidationTreeById(`${id}`));
                  navigate(`decision-rules/${id}`);
                } else if (actionState !== '-1') {
                  dispatch(
                    validationTreeMiddleware.fetchSetValidationTree(
                      validationTreesById?.data?.find((el: { id: string }) => el.id === actionState) as IValidationTree,
                    ),
                  );
                  dispatch(validationTreeMiddleware.fetchGetValidationTreeById(`${actionState}`));
                  navigate(`decision-rules/${actionState}`);
                }
              }
            },
          },
        ]
      : []),
    ...(hasPermission(Permissions.deleteDecisionTreeFilter)
      ? [
          {
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
            action: (e: string | number | Event) => {
              // @ts-ignore
              if ('stopPropagation' in e) {
                e.stopPropagation();
              }

              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.ConfirmAffiliateChannelTreeDeleteModal,
                  props: {
                    id: actionState,
                    affiliateChannelId: channel?.id,
                  },
                }),
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (channel?.id) {
      dispatch(validationTreeMiddleware.fetchAffiliateChannelTreesById(`${channel?.id}`));
    }
  }, [channel?.id]);

  return (
    <MainCard contentSX={{ padding: 0 }} sx={{ width: '100%', paddingTop: '24px' }} border={null} spacing={gridSpacing}>
      <Grid container item display="flex" xs={12} justifyContent="space-between" alignItems="center">
        <Grid item alignItems="center">
          <PagesSearch
            dataTest="affiliate-channel-edit-page-tab-decision-trees-input-search"
            handleSearch={handleSearch}
            minChar={1}
          />
        </Grid>
        <Permission permission={Permissions.createDecisionTreeFilter}>
          <Grid item>
            <CustomButton
              data-test="affiliate-channel-edit-page-tab-decision-trees-button-create-decision-tree"
              onClick={onCreateTree}
            >
              Create Decision Tree
            </CustomButton>
          </Grid>
        </Permission>
      </Grid>
      {isValidationTreesByIdLoading || isValidationTreeDeleteLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: '100%',
            marginTop: '24px',
            '& .MuiTableContainer-root': {
              padding: 0,
            },
          }}
        >
          {validationTreesById?.data?.length ? (
            <DenseTable
              dataTest="affiliate-channel-details-page-tab-decision-trees"
              actionState={actionState}
              setActionState={setActionState}
              selectedTableHeaders={DecisionTreeTableHeaders}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              sortTableData={sortTableData}
              data={tableData}
              isAction
              isDeleted={false}
              editButton={false}
              isDropdownButtons
              setTableData={setTableData}
              order={order as string}
              orderBy={orderBy}
              isMoreDropDown
              action={hasPermission(Permissions.viewDecisionTreeFilter) ? { type: 'Link' } : null}
              actionMenuItems={actionMenuItemsData}
              page={page}
              rowsPerPage={rowsPerPage}
              handleRequestSort={handleRequestSort}
              createSortHandler={createSortHandler}
              handleChangeRowsPerPage={handleChangeRowsPerPageServer}
              handleChangePage={handleChangePageServer}
              handleSort={handleSortServer}
              paginationCount={paginationCount}
              handleSortCount={handleSortCount}
            />
          ) : (
            <Title sx={{ padding: 0, marginTop: '30px' }} title="No Decision Trees" />
          )}
        </Box>
      )}
    </MainCard>
  );
};

export default ValidationTreeTable;
