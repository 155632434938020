import React, { MutableRefObject, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, FormHelperText, TextField, Typography } from '@mui/material';

import { IInputTag } from '@types';

import { emailValidation } from '../../types/regExp';

const Tags = ({ data, handleDelete }: { data: string; handleDelete: (value: string) => void }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F4F7FE',
      color: '#454256',
      margin: '5px 20px',
    }}
  >
    <Typography>{data}</Typography>
    <CloseIcon
      fontSize="small"
      sx={{ cursor: 'pointer', color: '#95A1B1' }}
      onClick={() => {
        handleDelete(data);
      }}
    />
  </Box>
);

const InputWithTags = ({
  disabled,
  tags,
  setTags,
}: {
  disabled: boolean;
  tags: IInputTag[];
  setTags: (value: IInputTag[]) => void;
}) => {
  const tagRef: MutableRefObject<HTMLInputElement | undefined> = useRef();
  const [isError, setIsError] = useState(false);

  const handleDelete = (value: string) => {
    const newTags = tags.filter((val) => val.email !== value);

    setTags(newTags);
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tagRef.current && tagRef.current.value) {
      const isValidEmail = emailValidation.test(tagRef.current.value);

      if (isValidEmail) {
        setTags([...tags, { email: tagRef.current.value }]);
        tagRef.current.value = '';
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  };

  return (
    <Box overflow="hidden">
      <form onSubmit={onSubmit}>
        <TextField
          disabled={disabled}
          inputRef={tagRef}
          fullWidth
          placeholder="Type email"
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}
          InputProps={{
            startAdornment: (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                {tags.map((data) => (
                  <Tags data={data.email} handleDelete={handleDelete} key={data.email} />
                ))}
              </Box>
            ),
          }}
        />
        {isError && <FormHelperText error>Must be valid email</FormHelperText>}
      </form>
    </Box>
  );
};

export default InputWithTags;
