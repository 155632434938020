import { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ApplicationTabs } from 'types';

import { ManualReviewStatuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware } from '../../redux/slices/leads';
import { manualReviewMiddleware, manualReviewSelector } from '../../redux/slices/manualReviews';
import { useGenerateTableData } from '../Hooks';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';
import Title from '../UI/Title';

// const Statuses = [
//   { label: 'Rejected', value: 0 },
//   { label: 'Accepted', value: 1 },
//   { label: 'Waiting For Review', value: 2 }
// ];

interface IManualReviewProps {
  setSelectedRow: (id: string) => void;
  setMode: (mode: string) => void;
  leadId: string | undefined;
}

const ManualReviewTable = ({ setSelectedRow, setMode, leadId }: IManualReviewProps) => {
  const manualReviews = useAppSelector(manualReviewSelector.manualReviews);
  const manualReviewsLoading = useAppSelector(manualReviewSelector.manualReviewsLoading);

  const { tableData, setTableData, order, orderBy, sortTableData, setOrder, setOrderBy, handleRequestSort } =
    useGenerateTableData({
      dataIsAlreadyThere: manualReviews?.reasons,
    });

  const handleRowClick = (id: string) => {
    setSelectedRow(id);
    setMode('edit');
  };

  useEffect(() => {
    if (leadId) {
      dispatch(manualReviewMiddleware.fetchManualReviewsByLeadId(leadId));
    }

    return () => {
      dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.generalInfo));
      dispatch(manualReviewMiddleware.clearManualReviews());
    };
  }, [leadId]);

  if (manualReviewsLoading) {
    return <Loading />;
  }

  if (!manualReviews) {
    return <Title title="No Data" />;
  }

  return (
    <Grid container marginTop="24px">
      <Grid item xs={1}>
        <Box>
          <Typography>DTI</Typography>
          <Typography sx={{ fontWeight: 700 }}>{manualReviews?.dti} %</Typography>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box>
          <Typography>PTI</Typography>
          <Typography sx={{ fontWeight: 700 }}>{manualReviews?.pti} %</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <Typography>Loan Amount</Typography>
          <Typography sx={{ fontWeight: 700 }}>$ {manualReviews?.loanAmount}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: '24px',
          '& .MuiTableContainer-root': {
            padding: '0',
          },
        }}
      >
        <DenseTable
          data={tableData}
          editButton={false}
          setTableData={setTableData}
          order={order as string}
          selectedTableHeaders={[
            { value: 'name', title: 'Reason', sortDisabled: true },
            { value: 'date', title: 'Date', sortDisabled: true },
            { value: 'status', title: 'Status', sortDisabled: true },
            { value: 'reviewerName', title: 'Reviewer', sortDisabled: true },
          ]}
          orderBy={orderBy}
          isAction={false}
          isDeleted={false}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          sortTableData={sortTableData}
          Statuses={ManualReviewStatuses}
          statusMode="BADGE"
          handleChangePage={() => {}}
          handleChangeRowsPerPage={() => {}}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Grid>
    </Grid>
  );
};

export default ManualReviewTable;
