import React, { useEffect, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';

export interface IDropdownItem {
  value: number;
  label: string;
}

export interface INestedDropdownItem {
  value: 'IncomeVerification' | 'PropertyTitleChecks' | 'IdentityVerification';
  key: string;
}

export enum ManualReviewItems {
  'IncomeVerification' = 'Income Verification',
  'IdentityVerification' = 'Identity Verification',
  'PropertyVerification' = 'Property Verification',
  'ProjectApproval' = 'Project Approval',
}

export interface INestedSelectProps {
  data: IDropdownItem[];
  dataTest?: string;
  nestedId: number;
  nestedData: INestedDropdownItem[];
  onSelect?: (data: string, value: number, index: number) => void;
  index: number;
  defaultValue: string;
  defaultNestedValue: string;
}

const NestedSelectManualReview = ({
  data,
  dataTest,
  nestedId,
  nestedData,
  onSelect,
  index,
  defaultValue,
  defaultNestedValue,
}: INestedSelectProps) => {
  const [showItems, setShowItems] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>(`${data[0].value}`);
  const [selectedNestedItems, setSelectedNestedItems] = useState<string>('');

  const handleClick = (key: string) => {
    if (key !== selectedNestedItems) {
      setSelectedNestedItems(`${key}`);
      setSelectedItem(`${nestedId}`);
    }
  };

  const handleSave = () => {
    setOpen(false);
    setSelectedItem(`${nestedId}`);
    onSelect?.(selectedNestedItems, nestedId, index);
  };

  const handleMenuItemClick = (value: number) => {
    setSelectedItem(`${value}`);
    setShowItems(false);
    setSelectedNestedItems('');
    onSelect?.('', value, index);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(`${defaultValue}`);
    } else {
      setSelectedItem(`${data[0].value}`);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultNestedValue) {
      setSelectedNestedItems(`${defaultNestedValue}`);
    }
  }, [defaultNestedValue]);

  return (
    <Box width="200px">
      <Select
        data-test={dataTest ?? ''}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(item) => data.find((el) => `${el.value}` === item)?.label}
        sx={{ width: '100%' }}
        value={selectedItem}
      >
        {data?.map((el) => {
          if (el.value === nestedId) {
            return (
              <Box key={el.value}>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => setShowItems(!showItems)}
                >
                  <MenuItem key={el.value} value="123" data-test={dataTest ? `${dataTest}-item-123` : ''}>
                    {el.label}
                  </MenuItem>
                  {!showItems ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                </Box>
                {showItems &&
                  (nestedData?.length ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', paddingX: '10px' }}>
                      <FormControl>
                        <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                          {nestedData?.map((item: INestedDropdownItem) => (
                            <FormControlLabel
                              data-test={dataTest ? `${dataTest}-item-radio-${item.key}` : ''}
                              control={<Radio checked={item.key === selectedNestedItems} />}
                              onClick={() => handleClick(item.key)}
                              label={ManualReviewItems[item.value as keyof typeof ManualReviewItems] ?? item.value}
                              value={item.key}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      {selectedNestedItems ? (
                        <Button
                          sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                          onClick={handleSave}
                          data-test={dataTest ? `${dataTest}-button-save` : ''}
                        >
                          Save
                        </Button>
                      ) : null}
                    </Box>
                  ) : (
                    <Box sx={{ padding: '10px 16px' }}>No Data</Box>
                  ))}
              </Box>
            );
          }

          return (
            <MenuItem
              key={el.value}
              value={el.value}
              onClick={() => handleMenuItemClick(el.value)}
              data-test={dataTest ? `${dataTest}-item-${el.value}` : ''}
            >
              {el.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default NestedSelectManualReview;
