import { useEffect } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { TableCell, TableRow } from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { leadsMiddleware, leadsSelector } from 'redux/slices/leads';

import { IDocumentList } from '@types';

import { LeadsDocumentsStatuses } from '../../lib/constants/statuses';
import { DocumentTypes } from '../../pages/Leads/Documents';
import CustomIconButton from '../shared/Button/IconButton';
import { StatusBadgeTitle } from '../StatusBadgeTitle';

let shouldRevokeUrl: string;

const DocumentListItem = ({ item }: { item: IDocumentList }) => {
  const filePreviewLoading = useAppSelector(leadsSelector.filePreviewLoading);
  const fileDownloadLoading = useAppSelector(leadsSelector.fileDownloadLoading);

  useEffect(
    () => () => {
      if (shouldRevokeUrl) {
        URL.revokeObjectURL(shouldRevokeUrl);
      }
    },
    [],
  );

  const handleOnDownloadClick = (id: string, name: string) => {
    dispatch(leadsMiddleware.downloadFile(id, name));
  };

  const handleOnPreviewClick = (id: string, name: string) => {
    dispatch(
      leadsMiddleware.previewFile(id, name, (url) => {
        shouldRevokeUrl = url;
      }),
    );
  };

  return (
    <TableRow key={item.id} sx={{ cursor: 'default !important' }}>
      <TableCell>{item.date}</TableCell>
      <TableCell>{DocumentTypes[item.documentTypeName] ?? item.documentTypeName}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        {item.status ? <StatusBadgeTitle Statuses={LeadsDocumentsStatuses} status={`${item.status}`} /> : null}
      </TableCell>
      <TableCell>{item.reviewerName}</TableCell>
      <TableCell>
        <CustomIconButton loading={!!filePreviewLoading[item.id]}>
          <RemoveRedEyeOutlinedIcon
            sx={{ color: '#A3AED0' }}
            onClick={() => handleOnPreviewClick(item.id, item.name)}
          />
        </CustomIconButton>
        <CustomIconButton loading={!!fileDownloadLoading[item.id]}>
          <SaveAltIcon sx={{ color: '#A3AED0' }} onClick={() => handleOnDownloadClick(item.id, item.name)} />
        </CustomIconButton>
      </TableCell>
    </TableRow>
  );
};

export default DocumentListItem;
