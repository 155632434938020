import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.validationTrees;

const validationTreeActions = createSelector([selector], (state) => state.validationTreeActions);

const validationTree = createSelector([selector], (state) => state.validationTree);
const error = createSelector([selector], (state) => state.error);

const validationTreeSuccessfully = createSelector([selector], (state) => state.validationTreeSuccessfully);

const validationTreeLoading = createSelector([selector], (state) => state.validationTreeLoading);

const conditions = createSelector([selector], (state) => state.conditions);

const listConditions = createSelector([selector], (state) => state.listConditions);

const validationTreesById = createSelector([selector], (state) => state.validationTreesById);

const singleValidationTree = createSelector([selector], (state) => state.singleValidationTree);

const isValidationTreesByIdLoading = createSelector([selector], (state) => state.isValidationTreesByIdLoading);

const isValidationTreeDeleteLoading = createSelector([selector], (state) => state.isValidationTreeDeleteLoading);

const validationTreeUpdateLoading = createSelector([selector], (state) => state.validationTreeUpdateLoading);

const manualReviewReasonsList = createSelector([selector], (state) => state.manualReviewReasonsList);

const manualReviewReasonsListLoading = createSelector([selector], (state) => state.manualReviewReasonsListLoading);

const experianFields = createSelector([selector], (state) => state.experianFields);

const validationTreeTypes = createSelector([selector], (state) => state.validationTreeTypes);

const validationTreeTypesLoading = createSelector([selector], (state) => state.validationTreeTypesLoading);

export default {
  validationTreeActions,
  validationTree,
  validationTreeSuccessfully,
  validationTreeLoading,
  conditions,
  error,
  listConditions,
  validationTreesById,
  singleValidationTree,
  validationTreeUpdateLoading,
  isValidationTreesByIdLoading,
  isValidationTreeDeleteLoading,
  manualReviewReasonsList,
  manualReviewReasonsListLoading,
  experianFields,
  validationTreeTypes,
  validationTreeTypesLoading,
};
