import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import moment from 'moment/moment';

import { ILead } from '@types';

import { dateTime, hidePiiDisabledFields } from '../../../helpers';
import { renderAddress, renderUserName } from '../../../helpers/renderValue';

export const LeadsDataChanger = (response: any) => {
  const changedData = response?.data?.map((item: ILead) => ({
    ...item,
    borrower: renderUserName(item?.firstname, item?.lastname),
    createdUtc: dateTime(item.createdUtc),
    lastUpdatedDate: item.lastUpdatedDate ? dateTime(item.lastUpdatedDate) : '',
    approvedLoanAmount: item.approvedLoanAmount ? `$ ${item.approvedLoanAmount}` : '',
    loanAmount: item.loanAmount ? `$ ${item.loanAmount}` : '',
  }));

  const properties = response.piiProperties.length ? [...response.piiProperties, 'borrower'] : [];

  return changedData.map((el: ILead) => hidePiiDisabledFields({ object: el, properties }));
};

export const LeadsDetailsDataChanger = (response: any) => {
  const properties = response.piiProperties.length ? [...response.piiProperties, 'borrower'] : [];

  const data = hidePiiDisabledFields({ object: response, properties });

  return {
    'Personal Details': [
      {
        title: 'Borrower’s Name',
        value: renderUserName(data.firstname, data.lastname, properties.includes('borrower')),
      },
      {
        title: 'Address',
        value: renderAddress(
          data.address,
          data.unit,
          data.city,
          data.state,
          data.zip,
          data.country,
          properties.includes('address'),
        ),
      },
      { title: 'Email', value: data.email },
      {
        title: 'DOB',
        value: properties.includes('dob')
          ? 'Hidden'
          : `${moment(data.dob).format('MMM Do YYYY')} (${moment().diff(data.dob, 'years')} y.o.)`,
      },
      { title: 'Phone', value: data.phoneNumber },
      { title: 'Credit score', value: data.vantageScore },
    ],
    'Application Details': [
      { title: 'Application ID', value: data.applicationId },
      {
        title: 'Product',
        value: data.campaignName,
        color: '#7B61FF',
        link: hasPermission(Permissions.viewProductList) ? `/products/${data.campaignId}` : '',
      },
      {
        title: 'Source Channel ID',
        value: data.affiliateChannelId,
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSourceChannelsList) ? `/sources-channel/${data.affiliateChannelId}` : '',
      },
      { title: 'Date and Time', value: dateTime(data.createdUtc) },
      {
        title: 'Source Channel',
        value: data.affiliateChannelName,
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSourceChannelsList) ? `/sources-channel/${data.affiliateChannelId}` : '',
      },
      {
        title: 'Source Name',
        value: data.affiliateName,
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSources) ? `/sources/${data.affiliateId}` : '',
      },
      { title: 'IP Address', value: data.ip },
      {
        title: 'Source ID',
        value: data.affiliateId,
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSources) ? `/sources/${data.affiliateId}` : '',
      },
    ],
    'Financial Details': [
      { title: 'DTI', value: data.dti ? `${data.dti}%` : '-' },
      { title: 'Modeled Income', value: data.modeledIncome },
      { title: 'Income Used', value: data.incomeUsed },
      { title: 'PTI', value: data.pti ? `${data.pti}%` : '-' },
      {
        title: 'Min Required Income',
        value: data.requiredIncome ? `$${new Intl.NumberFormat('en-US').format(data.requiredIncome)}` : '-',
      },
      {
        title: 'Min Required Property Value',
        value: data.requiredPropertyValue
          ? `$${new Intl.NumberFormat('en-US').format(data.requiredPropertyValue)}`
          : '-',
      },
      { title: 'cLTV', value: data.cltv, color: '#7B61FF' },
      {
        title: 'Stated Income',
        value: data.statedIncome ? `$${new Intl.NumberFormat('en-US').format(data.statedIncome)}` : '-',
      },
      {
        title: 'Total Debt (Incl. Loan Payment)',
        value: data.totalDebt ? `$${new Intl.NumberFormat('en-US').format(data.totalDebt)}` : '-',
      },
    ],
    'Property Details': [
      { title: 'Mortgage balance', value: data.mortgageBalance },
      { title: 'AVM value', value: data.avm },
      { title: 'Type of property', value: data.typeOfProperty },
    ],
  };
};
