import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ILeadsState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<ILeadsState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  setLeadsListLoading(state, action) {
    state.isLeadsListLoading = action.payload;
  },
  setLeadsDetailsLoading(state, action) {
    state.isLeadsDetailsLoading = action.payload;
  },
  getLeadsList(state, action) {
    state.leads = action.payload;
  },
  setLeadsListCount(state, action) {
    state.leadsCount = action.payload;
  },
  setLeadsFilters(state, action) {
    state.leadsFilters = action.payload;
  },
  setLeadsDetails(state, action) {
    state.leadsDetails = action.payload;
  },
  setLeadsJourneyLoading(state, action) {
    state.isLeadsJourneyLoading = action.payload;
  },
  setLeadsJourney(state, action) {
    state.journey = action.payload;
  },
  setLeadsJourneyStatus(state, action) {
    state.journeyStatus = action.payload;
  },
  setLogs(state, action) {
    state.logs = action.payload;
  },
  setLogsLoading(state, action) {
    state.logsLoading = action.payload;
  },
  setFunds(state, action) {
    state.funds = action.payload;
  },
  setFundsLoading(state, action) {
    state.fundsLoading = action.payload;
  },
  setRerunLoading(state, action) {
    state.rerunLoading = action.payload;
  },
  setRerunSteps(state, action) {
    state.rerunSteps = action.payload;
  },
  setApplicationJourney(state, action) {
    state.applicationJourney = action.payload;
  },
  setApplicationJourneyLoading(state, action) {
    state.applicationJourneyLoading = action.payload;
  },
  setFile(state, action) {
    state.documentFile = action.payload;
  },
  setFetchFileLoading(state, action) {
    state.fetchFileLoading = action.payload;
  },
  setLeadDetailsInitialTab(state, action) {
    state.leadDetailsInitialTab = action.payload;
  },
  setDocumentsMenu(state, action) {
    state.documentsMenu = action.payload;
  },
  setDocumentsMenuLoading(state, action) {
    state.documentsMenuLoading = action.payload;
  },
  setDocumentsList(state, action) {
    state.documentsList = action.payload;
  },
  setDocumentsListLoading(state, action) {
    state.documentsListLoading = action.payload;
  },
  setDocumentsUploadTypes(state, action) {
    state.documentsUploadTypes = action.payload;
  },
  setDocumentsUploadLoading(state, action) {
    state.documentsUploadLoading = action.payload;
  },
  setFilePreviewLoading(state, action) {
    state.filePreviewLoading = action.payload;
  },
  setFileDownloadLoading(state, action) {
    state.fileDownloadLoading = action.payload;
  },
  setRevertLoading(state, action) {
    state.revertLoading = action.payload;
  },
  setResendEmailLoading(state, action) {
    state.resendEmailLoading = action.payload;
  },
  setResendEmail(state, action) {
    state.resendEmail = action.payload;
  },
  setNotesAddOrEditLoading(state, action) {
    state.notesAddOrEditLoading = action.payload;
  },
  setNotesList(state, action) {
    state.notesList = action.payload;
  },
  setNotesListLoading(state, action) {
    state.notesListLoading = action.payload;
  },
  setNotesUsers(state, action) {
    state.notesUsers = action.payload;
  },
  setGeneralInformationData(state, action) {
    state.generalInformationData = action.payload;
  },
});

export default reducers;
