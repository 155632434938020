import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ISelectOption } from 'components/UI/CustomAutocomplete';
import SelectTableHeaders from 'components/UI/SelectTableHeaders';
import FormAutocomplete from 'formComponents/FormAutocomplete';
import moment from 'moment';
import { dispatch, useAppSelector } from 'redux/hooks';

import { CustomButton } from '../../components';
import CustomRangePicker from '../../components/CustomRangePicker/CustomRangePicker';
import { APPLICATIONS_KEY, PersistManager } from '../../helpers/PersistManager';
import { defaultFilterValues } from '../../helpers/PersistManager/utils';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { LeadsTableHeaders } from '../../lib/constants/tableHeaders';
import { affiliatesSelector } from '../../redux/slices/affiliates';
import { campaignsSelector } from '../../redux/slices/campaigns';
import { leadsMiddleware } from '../../redux/slices/leads';
import { manualReviewSelector } from '../../redux/slices/manualReviews';
import { tagsSelector } from '../../redux/slices/tags';
import { DateRange, TableHeader } from '../../types';

const statuses = [
  { value: 0, label: 'All' },
  { value: 2, label: 'Processing' },
  { value: 3, label: 'Success' },
  { value: 4, label: 'Reject' },
];

interface ILeadsFiltersProps {
  setPage: (value: number) => void;
  selectedTableHeaders: TableHeader[];
  setSelectedTableHeaders: Dispatch<SetStateAction<TableHeader[]>>;
}

export const LeadsFilters = ({ setPage, selectedTableHeaders, setSelectedTableHeaders }: ILeadsFiltersProps) => {
  const [defaultFilters] = useState(PersistManager.getData(APPLICATIONS_KEY) || {});
  const affiliateList = useAppSelector(affiliatesSelector.simplifiedAffiliates);
  const affiliateChannelsList = useAppSelector(affiliatesSelector.simplifiedAffiliateChannels);
  const campaignsList = useAppSelector(campaignsSelector.simplifiedCampaigns);
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: defaultFilters.dateFrom ?? moment().subtract(1, 'year').format(),
    endDate: defaultFilters.dateTo ?? moment().format(),
  });
  const resetFiltersFlag = useRef(false);
  const isDisabled = !(dateTimeState?.startDate && dateTimeState?.endDate);
  const merchantNames = useAppSelector(manualReviewSelector.merchantNames);
  const tags = useAppSelector(tagsSelector.tags);

  const filterSendingText = (text: string | string[]) => {
    if (text?.length > 0) {
      if (Array.isArray(text)) {
        return [...text];
      }

      return [text];
    }

    return [];
  };

  const defaultValues = {
    id: [],
    email: [],
    status: 0,
    borrower: '',
    merchantName: [],
    source: [],
    sourceChannel: [],
    product: [],
    tags: [],
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
  };

  const setupDefaultValues = () => ({
    id: defaultFilters.filterLeadIds ?? [],
    email: defaultFilters.filterEmail ?? [],
    status: defaultFilters.status ?? 0,
    borrower: defaultFilters.filterBorrowerName ?? '',
    merchantName: !defaultFilters.filterMerchantNames
      ? []
      : defaultFilters.filterMerchantNames.map((name: string) => ({ id: name, name })),
    source: defaultFilterValues(affiliateList ?? [], defaultFilters.filterAffiliateIds) ?? [],
    sourceChannel: defaultFilterValues(affiliateChannelsList ?? [], defaultFilters.filterAffiliateChannelIds) ?? [],
    product: defaultFilterValues(campaignsList ?? [], defaultFilters.filterCampaignIds) ?? [],
    tags: defaultFilterValues(tags ?? [], defaultFilters.filterTags, 'name') ?? [],
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
    searchModel: {
      pageNumber: 1,
      pageSize: DEFAULT_ROWS_PAGE,
      sortOrder: { key: 'Id', value: false },
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: setupDefaultValues(),
  });

  const { control, handleSubmit, reset, setValue } = methods;

  const applyFilters = (filtersData: any) => {
    setValue('borrower', (filtersData.borrower = filtersData.borrower.trim()));

    if (typeof filtersData.id === 'string') {
      setValue('id', (filtersData.id = filtersData.id.trim()));
    }

    if (typeof filtersData.email === 'string') {
      setValue('email', (filtersData.email = filtersData.email.trim()));
    }

    const filterAffiliateIds = filtersData.source.map((data: ISelectOption) => `${data.id}`);
    const filterAffiliateChannelIds = filtersData.sourceChannel.map((data: ISelectOption) => `${data.id}`);
    const filterCampaignIds = filtersData.product.map((data: ISelectOption) => `${data.id}`);
    const filterTagsIds = filtersData.tags.map((data: { name: string }) => data.name);
    const merchantNamesIds = filtersData.merchantName?.map((el: { id: string }) => el.id);
    const orderData = PersistManager.getData(APPLICATIONS_KEY).searchModel;

    dispatch(
      leadsMiddleware.fetchLeadsList({
        dateFrom: moment(dateTimeState?.startDate).startOf('day').format() ?? '',
        dateTo: moment(dateTimeState?.endDate).endOf('day').format() ?? '',
        status: filtersData.status ?? 0,
        filterBorrowerName: filtersData.borrower ?? '',
        filterAffiliateIds: filterAffiliateIds.length ? filterAffiliateIds : [],
        filterAffiliateChannelIds: filterAffiliateChannelIds.length ? filterAffiliateChannelIds : [],
        filterCampaignIds: filterCampaignIds.length ? filterCampaignIds : [],
        filterLeadIds: filterSendingText(filtersData.id),
        filterEmail: filterSendingText(filtersData.email),
        filterTags: filterTagsIds.length ? filterTagsIds : [],
        filterMerchantNames: merchantNamesIds.length ? merchantNamesIds : [],
        searchModel: {
          pageNumber: 1,
          pageSize: orderData.pageSize,
          sortOrder: {
            key: 'Id',
            value: false,
          },
        },
      }),
    );
    setPage(0);
  };

  const resetFilters = () => {
    resetFiltersFlag.current = true;
    setDateTimeState({
      startDate: moment().subtract(1, 'year').format(),
      endDate: moment().format(),
    });
  };

  useEffect(() => {
    if (affiliateChannelsList && affiliateList && tags && campaignsList && merchantNames) {
      reset(setupDefaultValues());
    }
  }, [affiliateChannelsList, affiliateList, tags, campaignsList, merchantNames]);

  useEffect(() => {
    if (resetFiltersFlag.current) {
      resetFiltersFlag.current = false;
      dispatch(leadsMiddleware.resetLeadsFilters());
      reset(defaultValues);
      applyFilters(defaultValues);
    }
  }, [dateTimeState]);

  return (
    <FormProvider {...methods}>
      <Box onSubmit={handleSubmit(applyFilters)} component="form">
        <Grid item container xs={12} gap="30px" alignItems="flex-end">
          <Grid item>
            <Box display="flex" alignItems="flex-end" height="100%">
              <CustomRangePicker
                key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
                dataTest="leads-page-input-range"
                dateTimeState={dateTimeState}
                setDateTimeState={setDateTimeState}
              />
            </Box>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="borrower"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Borrower</InputLabel>
                  <TextField {...field} placeholder="Borrower" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Merchant Name"
              options={merchantNames ?? []}
              name="merchantName"
              data-test="manual-reviews-page-dropdown-merchant-name"
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="id"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Application ID</InputLabel>
                  <TextField {...field} placeholder="Application ID" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Email</InputLabel>
                  <TextField {...field} placeholder="Email" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Status</InputLabel>
                  <Select {...field}>
                    {statuses?.map((el) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} marginTop="16px" gap="30px">
          <Grid item>
            <FormAutocomplete
              dataTest="leads-page-dropdown-affiliate"
              title="Source"
              options={affiliateList ?? []}
              name="source"
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              dataTest="leads-page-dropdown-affiliate-channel"
              title="Source Channel"
              options={affiliateChannelsList ?? []}
              name="sourceChannel"
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              dataTest="leads-page-dropdown-campaign"
              title="Product"
              options={campaignsList ?? []}
              name="product"
            />
          </Grid>
          <Grid item>
            <FormAutocomplete dataTest="leads-page-dropdown-tags" title="Tags" options={tags ?? []} name="tags" />
          </Grid>
          <Grid item>
            <SelectTableHeaders
              tableHeaders={LeadsTableHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
            />
          </Grid>
          <Grid item display="flex" gap="30px">
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
              <CustomButton data-test="leads-page-button-apply" type="submit" disabled={isDisabled}>
                Apply
              </CustomButton>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
              <CustomButton variant="outlined" data-test="leads-page-button-apply" type="reset" onClick={resetFilters}>
                Reset
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};
