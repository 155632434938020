import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import { dateTime } from 'helpers';
import { FUND_LIST_KEY, PersistManager } from 'helpers/PersistManager';
import { renderUserName } from 'helpers/renderValue';
import { SeveritiesType } from 'types/snackbar';

import { IFunds } from '@types';

import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import { FileManager } from '../../../helpers/FileManager';
import { UpdateFundingsTableData } from '../../../helpers/UpdateTableData';
import {
  API_ACTIVE_USERS,
  API_ASSIGN_FUNDER,
  API_DOWNLOAD_FUNDING_REPORT,
  API_FUNDING_DOCUMENT_DELETE,
  API_FUNDINGS,
} from '../../../lib/constants/api.constants.simple';
import { IFundingReportFilters } from '../../../pages/Report/FundingReport';
import DataService from '../../../service/data.service';
import { IFundingFilters } from '../../../types/funding';
import { dispatch, getStateBySelector } from '../../hooks';
import { leadsMiddleware } from '../leads';
import manualReviewSlice from '../manualReviews/slice';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { fundingMiddleware } from './index';
import { IFundingList } from './initialState';
import slice from './slice';

const {
  setFundingList,
  setFundingLoading,
  setFundingListFilters,
  setFundersList,
  setFundersListLoading,
  setIsDownloadFundingReportLoading,
} = slice.actions;

const { setDeleteFileLoading } = manualReviewSlice.actions;

const fetchGetFundingList = (filterData?: IFundingFilters) => async () => {
  try {
    let data = filterData;

    if (!data) {
      data = PersistManager.getData(FUND_LIST_KEY);
    }

    if (!data) {
      dispatch(setFundingListFilters(null));
    } else {
      dispatch(setFundingLoading(true));

      const oldFilters = getStateBySelector('funding').fundingListFilters;

      const filters = { ...oldFilters, ...data };

      const sortedFilters = {
        ...filters,
        applicationId: filters.applicationId ? filters.applicationId : null,
        status: filters.status !== '2' ? filters.status : null,
      };

      const { data: response } = await DataService.post(API_FUNDINGS(), sortedFilters);

      PersistManager.setData(FUND_LIST_KEY, sortedFilters);

      dispatch(setFundingListFilters(filters));
      response?.data?.forEach(
        (item: { date: string; requestAmount: string; borrower?: string; firstname?: string; lastname?: string }) => {
          item.date = dateTime(item.date);
          item.requestAmount = item.requestAmount ? `$ ${item.requestAmount}` : '';
          item.borrower = renderUserName(
            item?.firstname,
            item?.lastname,
            response?.piiProperties?.includes('firstname') || response?.piiProperties?.includes('lastname'),
          );
        },
      );

      dispatch(setFundingList(response));
    }
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      dispatch(setFundingLoading(false));
    }, 100);
  }
};

const updateFundingList = (data: IFundingList[]) => async () => {
  const { fundingList } = getStateBySelector('funding');

  dispatch(setFundingList({ ...fundingList, data }));
};

const getFundersList = (userId: string) => async () => {
  try {
    dispatch(setFundersListLoading(true));

    let fundersList = [];

    if (hasPermission(Permissions.changeFunder)) {
      const response = await DataService.getData(API_ACTIVE_USERS(4));

      fundersList = response.map((el: { id: string; firstName: string; lastName: string }) => ({
        id: el.id,
        value: `${el.firstName} ${el.lastName}`,
      }));
    }

    dispatch(setFundersList([{ id: userId, value: 'Assign to me' }, ...fundersList]));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setFundersListLoading(false));
  }
};

const updateFunderAssign =
  (
    fundId: string,
    userId: number | string,
    setLoading: (value: boolean) => void,
    mode: 'list' | 'request',
    userName?: string,
  ) =>
  async () => {
    try {
      let fundingListData;

      if (mode === 'list') {
        fundingListData = getStateBySelector('funding').fundingList?.data;
      } else {
        fundingListData = getStateBySelector('leads').funds;
      }

      dispatch(setFundersListLoading(true));

      await DataService.post(API_ASSIGN_FUNDER(fundId, userId), {});

      if (mode === 'list') {
        dispatch(
          fundingMiddleware.updateFundingList(
            UpdateFundingsTableData(fundingListData, fundId, userId, setLoading, userName) as IFundingList[],
          ),
        );
      } else {
        dispatch(
          leadsMiddleware.updateLeadsFundsList(
            UpdateFundingsTableData(fundingListData, fundId, userId, setLoading, userName) as IFunds[],
          ),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setFundersListLoading(false));
    }
  };

const downloadFile = async (filters: IFundingReportFilters) => {
  try {
    const response = await DataService.getFileWithFilters(API_DOWNLOAD_FUNDING_REPORT(), filters);

    return response?.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

const deleteFundingFile = (id: string, leadId: string) => async () => {
  try {
    dispatch(setDeleteFileLoading(true));
    await DataService.delete(API_FUNDING_DOCUMENT_DELETE(id));
    dispatch(leadsMiddleware.fetchFunds(leadId));
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteFileModal));
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'File successfully deleted.',
        },
      }),
    );
  } catch (error) {
    responseErrorHandler(error);
  } finally {
    dispatch(setDeleteFileLoading(false));
  }
};

const fetchDownloadFundingReport = (filters: IFundingReportFilters) => async () => {
  dispatch(setIsDownloadFundingReportLoading(true));

  const response = await downloadFile(filters);

  if (response) {
    FileManager.download(response as File, 'Funds-List-Report.xlsx');
  }

  dispatch(setIsDownloadFundingReportLoading(false));
};

export default {
  fetchGetFundingList,
  updateFundingList,
  getFundersList,
  updateFunderAssign,
  fetchDownloadFundingReport,
  deleteFundingFile,
};
