import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import TitleWithBreadcrumbs from 'components/shared/TitleWithBreadcrumbs';
import { buttonsWrapper } from 'styles/styles';

import { CustomButton } from '../../components';
import CampaignsAffiliateChannel from '../../components/CampaignsAffiliateChannel';
import { Permissions } from '../../components/Permissions/constants';
import Permission from '../../components/Permissions/Permission';
import CustomSelect from '../../components/shared/Select/CustomSelect';
import CustomTextField from '../../components/shared/TextField/CustomTextField';
import Loading from '../../components/UI/Loading';
import BaseTabs from '../../components/UI/Tabs';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { ICampaignDetails } from '../../types/campaigns';

import CampaignCreatePage from './CampaignCreatePage';
import HistoryPage from './History';

export enum CampaignTabs {
  generalInformation = 'GENERAL_INFORMATION',
  integration = 'INTEGRATION',
  sourceChannels = 'SOURCE_CHANNELS',
  history = 'HISTORY',
}

const CampaignDetails = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<number | string>(CampaignTabs.generalInformation);
  const currentCampaignById = useAppSelector(campaignsSelector.currentCampaignById);
  const isCurrentCampaignByIdLoading = useAppSelector(campaignsSelector.isCurrentCampaignByIdLoading);
  const isUpdateCampaignDetailsLoading = useAppSelector(campaignsSelector.isUpdateCampaignDetailsLoading);
  const { Id: campaignId } = useParams();

  const [campaignName, setCampaignName] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  useEffect(() => {
    if (campaignId) {
      dispatch(campaignsMiddleware.fetchCurrentCampaignById(campaignId));
    }
  }, [campaignId]);

  const updateCampaignDetails = () => {
    const updatedCampaign = {
      ...currentCampaignById,
      name: campaignName,
      status,
    };

    dispatch(campaignsMiddleware.fetchUpdateCampaignDetails(updatedCampaign as unknown as ICampaignDetails));
  };

  useEffect(() => {
    setCampaignName(currentCampaignById?.name ?? '');
    setStatus(`${currentCampaignById?.status ?? '0'}`);
  }, [currentCampaignById]);

  useEffect(() => {
    if (tabValue === CampaignTabs.integration && campaignId) {
      dispatch(campaignsMiddleware.fetchCampaignFieldsByCampaignId(campaignId));
    }
  }, [campaignId, tabValue]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <TitleWithBreadcrumbs
        title="Products List"
        dataTest="campaign-details-page-header-products-list"
        link="/products"
        linkTitle="Products List"
        currentTitle={`Product ${campaignId}`}
      />
      <Box
        sx={{
          flex: 1,
          height: '100%',
          backgroundColor: 'white',
          borderRadius: '17px',
          padding: '20px 30px',
        }}
      >
        <BaseTabs
          component="div"
          tabs={[
            {
              id: 'GENERAL_INFORMATION',
              label: 'General Information',
              permission: Permissions.viewProductGeneralInformation,
            },
            { id: 'INTEGRATION', label: 'Integration', permission: Permissions.viewProductIntegration },
            {
              id: 'SOURCE_CHANNELS',
              label: 'Source Channels',
              permission: Permissions.viewProductSourceChannelDetails,
            },
            { id: 'HISTORY', label: 'History', permission: Permissions.viewProductHistory },
            // { label: '3rd party API Integration', permission: Permissions.viewIntegrations }
          ]}
          dataTest={[
            'campaign-details-page-button-tab-general-information',
            'campaign-details-page-button-tab-integration',
            'campaign-details-page-button-tab-affiliate-channel',
            'campaign-details-page-button-tab-history',
            // 'campaign-details-page-button-tab-3rd-party-api-integration'
          ]}
          tabValue={tabValue}
          sx={{ width: '100%' }}
          setTabValue={setTabValue}
        />
        {tabValue === CampaignTabs.generalInformation &&
          (isCurrentCampaignByIdLoading ? (
            <Loading />
          ) : (
            <Grid container item justifyContent="center" alignItems="center" flexDirection="column" marginTop="9px">
              <Grid item sx={{ width: '100%', maxWidth: '469px' }}>
                <CustomTextField
                  permission={Permissions.editProduct}
                  onChange={setCampaignName}
                  label="Product Name"
                  defaultValue={currentCampaignById?.name ?? ''}
                  value={campaignName}
                  labelSx={{
                    mt: '10px',
                    mb: '10px',
                    fontWeight: 'bold',
                    color: '#707D9D',
                    fontSize: '16px',
                  }}
                  dataTest="campaign-details-tab-general-information-input-campaign-name"
                />
                <CustomSelect
                  options={[
                    { value: '1', label: 'Active' },
                    { value: '0', label: 'Inactive' },
                  ]}
                  value={status}
                  labelSx={{
                    mt: '10px',
                    mb: '10px',
                    fontWeight: 'bold',
                    color: '#707D9D',
                    fontSize: '16px',
                  }}
                  label="Status"
                  width="100%"
                  permission={Permissions.editProduct}
                  onSelect={(item) => handleStatusChange(`${item.value}`)}
                  dataTest="campaign-details-tab-general-information-dropdown-status"
                />
                <Permission permission={Permissions.editProduct}>
                  <Box sx={buttonsWrapper}>
                    <CustomButton
                      data-test="campaign-details-tab-general-information-button-cancel"
                      variant="outlined"
                      onClick={() => {
                        navigate('/products', { replace: true });
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      data-test="campaign-details-tab-general-information-button-save-changes"
                      disabled={isUpdateCampaignDetailsLoading}
                      loading={isUpdateCampaignDetailsLoading}
                      onClick={updateCampaignDetails}
                    >
                      Save changes
                    </CustomButton>
                  </Box>
                </Permission>
              </Grid>
            </Grid>
          ))}
        {tabValue === CampaignTabs.integration && <CampaignCreatePage mode="EDIT" />}
        {tabValue === CampaignTabs.sourceChannels && <CampaignsAffiliateChannel />}
        {tabValue === CampaignTabs.history && <HistoryPage />}
        {/* {tabValue === 4 && <CampaignsExternalServiceIntegration setTabValue={setTabValue} />} @deprecated */}
      </Box>
    </Box>
  );
};

export default CampaignDetails;
