import {
  API_DELETE_USER,
  API_GET_PERMISSIONS,
  API_GET_PERMISSIONS_BY_ID,
  API_GET_ROLE_BY_ID,
  API_GET_ROLES,
  API_TENANT_USERS,
  API_USER_ACTIVATE,
  API_USER_DEACTIVATE,
  API_USER_EDIT_ROLE,
  API_USER_FORCE_RESET_PASSWORD,
  API_USER_INFO_BY_TOKEN,
  API_USER_INVITE,
  API_USER_INVITE_RESEND,
} from 'lib/constants/api.constants.simple';
import { dispatch, getStateBySelector } from 'redux/hooks';
import dataService from 'service/data.service';

import { dateTime } from '../../../helpers';
import { CookieManager } from '../../../helpers/CookieManager';
import { SeveritiesType } from '../../../types/snackbar';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { userManagementMiddleware } from './index';
import { IPermission, IPermissionsList, IRolesList, IUser, IUserDto, userStatuses } from './initialState';
import slice from './slice';
import { IUserInfo } from './types';

const DOMAINS = 'domains'; // @TODO should be added into new API constants

const {
  setIsLoading,
  setUsersList,
  setErrorMessage,
  setIsUserValid,
  setDeactivateLoading,
  setEditUserRoleLoading,
  setSendInvitationLoading,
  setResendInvitationLoading,
  setResetPasswordLoading,
  setDeleteLoading,
  setRestrictDomainsLoading,
  setDomainsData,
  setUserPermissionsLoading,
  setRolesListLoading,
  setPermissionsList,
  setPermissionsListLoading,
  setRolePermissions,
  setRolesList,
  setRoleDeleteLoading,
  setRoleDeleteId,
} = slice.actions;

const restrictDomains = (request: { restrictionEnabled: boolean; domains: string[] }) => async () => {
  try {
    dispatch(setRestrictDomainsLoading(true));

    await dataService.post(DOMAINS, request);
    dispatch(viewsMiddleware.closeModal(ModalName.DomainRestrictionsModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Failed to save.',
        },
      }),
    );
  } finally {
    dispatch(setRestrictDomainsLoading(false));
  }
};

const resetRestrictedDomains = () => () => {
  dispatch(setDomainsData(null));
};

const getRestrictDomainsData = () => async () => {
  try {
    dispatch(setRestrictDomainsLoading(true));

    const response = await dataService.getData(DOMAINS);

    dispatch(setDomainsData(response));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Failed to fetch.',
        },
      }),
    );
  } finally {
    dispatch(setRestrictDomainsLoading(false));
  }
};

const fetchUsersList = () => async () => {
  try {
    dispatch(setIsLoading(true));

    const response = await dataService.getData(API_TENANT_USERS());

    dispatch(setUsersList(response.map((user: IUserDto) => new IUser(user))));
  } catch (error) {
    setErrorMessage('Failed to fetch users list');
  } finally {
    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 100);
  }
};

const sendInvitation = (payload: IUserInfo) => async () => {
  try {
    dispatch(setSendInvitationLoading(true));
    await dataService.post(API_USER_INVITE(), payload);

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Invitation Successfully Sent',
        },
      }),
    );

    dispatch(fetchUsersList());

    dispatch(viewsMiddleware.closeModal(ModalName.InviteNewUserModal));
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response.data,
        },
      }),
    );
    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setSendInvitationLoading(false));
  }
};

const resendInvitation = (email: string) => async () => {
  try {
    dispatch(setResendInvitationLoading(true));
    await dataService.post(API_USER_INVITE_RESEND(), { email });

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Invitation Successfully Sent',
        },
      }),
    );

    dispatch(fetchUsersList());

    dispatch(viewsMiddleware.closeModal(ModalName.ResendInviteUserModal));
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response.data,
        },
      }),
    );
    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setResendInvitationLoading(false));
  }
};

const resetPassword = (userEmail: string) => async () => {
  try {
    dispatch(setResetPasswordLoading(true));
    await dataService.post(API_USER_FORCE_RESET_PASSWORD(), { userEmail });

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: `Reset password link was successfully sent to ${userEmail}`,
        },
      }),
    );

    dispatch(fetchUsersList());

    dispatch(viewsMiddleware.closeModal(ModalName.ResetPasswordModal));
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response.data,
        },
      }),
    );
    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setResetPasswordLoading(false));
  }
};

const editUserRole =
  ({ id, roleId }: { roleId: string; id: string }) =>
  async () => {
    try {
      dispatch(setEditUserRoleLoading(true));
      await dataService.patch(API_USER_EDIT_ROLE(id), { roleId });

      dispatch(fetchUsersList());

      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: 'User role successfully changed.',
          },
        }),
      );
      dispatch(viewsMiddleware.closeModal(ModalName.EditUserRoleModal));
    } catch (error: any) {
      if (error.response.status === 500 || error.response.data.Message) {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.error,
              description: 'Something went wrong',
            },
          }),
        );
      } else {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.error,
              description: error?.response.data,
            },
          }),
        );
      }

      setErrorMessage('Failed to change user role.');
    } finally {
      dispatch(setEditUserRoleLoading(false));
    }
  };

const deactivateUser = (id: string) => async () => {
  try {
    dispatch(setDeactivateLoading(true));
    await dataService.post(API_USER_DEACTIVATE(id), {});

    const users = getStateBySelector('userManagement').usersList.map((user: IUser) => {
      if (user.id === id) {
        user.userStatus = userStatuses['1'];
      }

      return user;
    });

    dispatch(setUsersList(users));

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Deactivation has been done successfully.',
        },
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.DeactivateUserModal));
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response.data,
        },
      }),
    );
    dispatch(setSendInvitationLoading(false));
    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setDeactivateLoading(false));
  }
};
const activateUser = (id: string) => async () => {
  try {
    dispatch(setDeactivateLoading(true));
    await dataService.post(API_USER_ACTIVATE(id), {});

    const users = getStateBySelector('userManagement').usersList.map((user: IUser) => {
      if (user.id === id) {
        user.userStatus = userStatuses['2'];
      }

      return user;
    });

    dispatch(setUsersList(users));

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Activation has been done successfully.',
        },
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.DeactivateUserModal));
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response.data,
        },
      }),
    );
    dispatch(setSendInvitationLoading(false));
    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setDeactivateLoading(false));
  }
};

const getNewAddedUserInfoByToken = (token: string) => async () => {
  // need to be added correct error handling
  // dev in progress for this function
  try {
    await dataService.getData(API_USER_INFO_BY_TOKEN(token));

    dispatch(setIsUserValid(true));
  } catch (error) {
    dispatch(setIsUserValid(false));

    setErrorMessage('Failed to send invitation');
  } finally {
    dispatch(setIsLoading(false));
  }
};

const deleteUser = (id: string) => async () => {
  try {
    dispatch(setDeleteLoading(true));

    await dataService.delete(API_DELETE_USER(id));

    dispatch(viewsMiddleware.closeModal(ModalName.DeleteUserModal));
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'User deleted successfully',
        },
      }),
    );
    dispatch(fetchUsersList());
  } catch (error: any) {
    if (error?.response?.data) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error.response.data,
          },
        }),
      );
    } else {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: 'Something went wrong',
          },
        }),
      );
    }
  } finally {
    dispatch(setDeleteLoading(false));
  }
};

const getUserPermissions = () => async () => {
  try {
    const user = CookieManager.getUser();

    if (user?.role) {
      const response = await dataService.getData(API_GET_PERMISSIONS_BY_ID(`${user?.role}`));

      localStorage.setItem('permissions', response);
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setUserPermissionsLoading(false));
  }
};

const getRolesList = () => async () => {
  try {
    dispatch(setRolesListLoading(true));

    const response = await dataService.getData(API_GET_ROLES());

    response?.forEach((item: IRolesList) => {
      if (item.latestUpdate) {
        item.latestUpdate = dateTime(item.latestUpdate);
      }
    });

    dispatch(setRolesList(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setRolesListLoading(false));
  }
};

const getPermissionsList = () => async () => {
  try {
    dispatch(setPermissionsListLoading(true));

    const response = await dataService.getData(API_GET_PERMISSIONS());

    if (response) {
      const changedData: IPermissionsList = {};

      response.forEach((item: IPermission) => {
        if (changedData[item.type]) {
          changedData[item.type] = [...changedData[item.type], item];
        } else {
          changedData[item.type] = [item];
        }
      });

      dispatch(setPermissionsList(changedData));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setPermissionsListLoading(false));
  }
};

const getRolePermissions = (id: string) => async () => {
  try {
    dispatch(setPermissionsListLoading(true));

    const response = await dataService.getData(API_GET_PERMISSIONS_BY_ID(id));

    dispatch(setRolePermissions(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setPermissionsListLoading(false));
  }
};

const createRole = (data: { name: string; piiEnabled: boolean; permissions: string[] }) => async () => {
  try {
    dispatch(setPermissionsListLoading(true));

    await dataService.post(API_GET_ROLES(), data);

    dispatch(viewsMiddleware.closeModal(ModalName.CreateRoleModal));
    dispatch(userManagementMiddleware.clearRolePermissions());
    dispatch(userManagementMiddleware.getRolesList());
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response?.data ? error?.response?.data : 'Something went wrong.',
        },
      }),
    );
    console.log(error);
  } finally {
    dispatch(setPermissionsListLoading(false));
  }
};

const clearRolePermissions = () => () => {
  dispatch(setRolePermissions(null));
};

const editRole = (id: string, name: string, piiEnabled: boolean, permissions: string[]) => async () => {
  try {
    dispatch(setPermissionsListLoading(true));

    await dataService.put(API_GET_ROLES(), { id, name, piiEnabled, permissions });
    dispatch(viewsMiddleware.closeModal(ModalName.EditRoleModal));
    dispatch(userManagementMiddleware.clearRolePermissions());
    dispatch(userManagementMiddleware.getRolesList());
  } catch (error: any) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: error?.response?.data ? error?.response?.data : 'Something went wrong.',
        },
      }),
    );
    console.log(error);
  } finally {
    dispatch(setPermissionsListLoading(false));
  }
};

const deleteRole = (role: IRolesList) => async () => {
  try {
    dispatch(setRoleDeleteLoading(true));

    await dataService.delete(API_GET_ROLE_BY_ID(role.id));
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteRoleModal));
    dispatch(userManagementMiddleware.getRolesList());
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: `Role ${role.name} deleted successfully`,
        },
      }),
    );
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setRoleDeleteLoading(false));
  }
};

const changeRoleDeleteId = (role: IRolesList | null) => () => {
  dispatch(setRoleDeleteId(role));
};

export default {
  editUserRole,
  fetchUsersList,
  sendInvitation,
  resendInvitation,
  resetPassword,
  activateUser,
  deactivateUser,
  getNewAddedUserInfoByToken,
  deleteUser,
  restrictDomains,
  getRestrictDomainsData,
  resetRestrictedDomains,
  getUserPermissions,
  getRolesList,
  getRolePermissions,
  getPermissionsList,
  createRole,
  clearRolePermissions,
  editRole,
  deleteRole,
  changeRoleDeleteId,
};
