import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputLabel, Switch, TextField, Typography } from '@mui/material';

import { IPlatformSettings } from '@types';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../redux/slices/settings';
import { platformSettingsValidationSchema } from '../../validation/settings/platformSettings';
import Loading from '../UI/Loading';

const PlatformSettings = () => {
  const platformSettings = useAppSelector(settingsSelector.platformSettings);
  const platformSettingsLoading = useAppSelector(settingsSelector.platformSettingsLoading);
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      isSlackAppInstalled: false,
      inAppNotifications: false,
      emailNotifications: false,
      slackNotifications: false,
      email: '',
      slackChannelName: '',
    },
    resolver: yupResolver(platformSettingsValidationSchema),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods;

  const onSubmit = (data: IPlatformSettings) => {
    dispatch(settingsMiddleware.updatePlatformSettings(data));
  };

  const onSlackDownload = () => {
    dispatch(settingsMiddleware.installSlack());
  };

  useEffect(() => {
    if (platformSettings) {
      reset(platformSettings);
    }
  }, [platformSettings]);

  useEffect(() => {
    dispatch(settingsMiddleware.fetchPlatformSettings());
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        p: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
      }}
    >
      {platformSettingsLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize="20px" fontWeight={700}>
              Platform Settings
            </Typography>
            <Button
              sx={{ minWidth: '80px !important', margin: '0 !important', visibility: isDirty ? 'visible' : 'hidden' }}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gap="20px" marginY="10px">
            Notifications
          </Box>
          <Box marginY="10px">
            <Controller
              control={control}
              name="inAppNotifications"
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px' }}
                >
                  <Switch color="secondary" checked={watch('inAppNotifications')} {...field} />
                  <Typography>Receive In App Notifications</Typography>
                </FormControl>
              )}
            />
          </Box>
          <Box marginY="10px">
            <Controller
              control={control}
              name="emailNotifications"
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px' }}
                >
                  <Switch color="secondary" checked={watch('emailNotifications')} {...field} />
                  <Typography>Receive Notifications via email</Typography>
                </FormControl>
              )}
            />
          </Box>
          {watch('emailNotifications') && (
            <Box paddingLeft="80px">
              <Controller
                control={control}
                name="email"
                render={({ field, formState }) => (
                  <FormControl fullWidth>
                    <InputLabel>Email</InputLabel>
                    <TextField
                      sx={{ minWidth: '10px !important', marginBottom: '10px' }}
                      placeholder="Email"
                      helperText={formState.errors.email?.message ?? ''}
                      error={Boolean(formState.errors.email?.message)}
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </Box>
          )}
          <Box marginY="10px">
            <Controller
              control={control}
              name="slackNotifications"
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px' }}
                >
                  <Switch color="secondary" checked={watch('slackNotifications')} {...field} />
                  <Typography>Receive Notifications via Slack Channel</Typography>
                </FormControl>
              )}
            />
          </Box>
          {watch('slackNotifications') && (
            <Box paddingLeft="80px">
              {watch('isSlackAppInstalled') ? (
                <Controller
                  control={control}
                  name="slackChannelName"
                  render={({ field, formState }) => (
                    <FormControl fullWidth>
                      <InputLabel>Slack Channel</InputLabel>
                      <TextField
                        sx={{ minWidth: '10px !important', marginBottom: '10px' }}
                        placeholder="Channel URL"
                        helperText={formState.errors.slackChannelName?.message ?? ''}
                        error={Boolean(formState.errors.slackChannelName?.message)}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              ) : (
                <Box sx={{ cursor: 'pointer' }} onClick={onSlackDownload}>
                  <img
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    alt="Slack logo"
                  />
                </Box>
              )}
            </Box>
          )}
        </form>
      )}
    </Box>
  );
};

export default PlatformSettings;
