import { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, Typography } from '@mui/material';
import { IFile } from 'components/FileUploadModal/FileAttachment';
import SolarPanelDetailsTable from 'components/ManualReview/SolarPanelDetailsTable';
import UploadFileButton from 'components/shared/Button/UploadFileButton';
import { ManualReviewItems } from 'components/shared/NestedSelect/NestedSelectManualReview';
import { isMe } from 'helpers';

import { canAccept } from '../../helpers/manualReviewReasonValidations';
import { ManualReviewStatuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsSelector } from '../../redux/slices/leads';
import { manualReviewMiddleware, manualReviewSelector } from '../../redux/slices/manualReviews';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { Permissions } from '../Permissions/constants';
import Permission, { hasPermission } from '../Permissions/Permission';
import { CustomButton } from '../shared';
import CustomTextArea from '../shared/CustomInputs/CustomTextArea';
import { StatusBadgeTitle } from '../StatusBadgeTitle';
import Loading from '../UI/Loading';

import ExternalDocumentGroup from './ExternalDocumentGroup';
import ManualReviewDetailsAssignButton from './ManualReviewDetailsAssignButton';
import SingleFileAttachment from './SingleFileAttachment';

interface IManualReviewEdit {
  id: string;
  setMode: (mode: string) => void;
  leadId: string | undefined;
}

const ManualReviewDetails = ({ id, setMode, leadId }: IManualReviewEdit) => {
  const [note, setNote] = useState<string>('');
  const manualReviewReasonLoading = useAppSelector(manualReviewSelector.manualReviewReasonLoading);
  const documentsMenuLoading = useAppSelector(leadsSelector.documentsMenuLoading);
  const manualReviewReason = useAppSelector(manualReviewSelector.manualReviewReason);
  const canReviewReason = isMe(manualReviewReason?.reviewerId ?? '0');

  const onUpload = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ReasonUploadFilesModal,
        props: { leadId, reason: manualReviewReason },
      }),
    );
  };

  const onSolarPanelEdit = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.SolarPanelEditModal,
        props: { reasonId: manualReviewReason?.id, solarPanelDetails: manualReviewReason?.solarPanelDetails },
      }),
    );
  };

  useEffect(() => {
    dispatch(manualReviewMiddleware.fetchManualReviewsReasonByLeadId(`${id}`));
  }, [id]);

  useEffect(() => {
    if (manualReviewReason) {
      setNote(manualReviewReason.notes);
    }
  }, [manualReviewReason]);

  useEffect(() => {
    if (manualReviewReason?.reason) {
      dispatch(manualReviewMiddleware.getManualReviewDocumentsTypes(`${manualReviewReason.reason}`));
    }
  }, [manualReviewReason?.reason]);

  const resolve = (status: number) => {
    dispatch(manualReviewMiddleware.resolveManualReviewReason(leadId!, manualReviewReason!, status, note, []));
  };

  const onRejectClick = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.RejectApplicantModal,
        props: { id: leadId, manualReviewReason, mainNote: note },
      }),
    );
  };

  const onDeleteFile = (file: IFile) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DeleteFileModal,
        props: { file, reasonId: id, modalType: 'ManualReview' },
      }),
    );
  };

  if (manualReviewReasonLoading || documentsMenuLoading) {
    return <Loading />;
  }

  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item xs={2}>
        <Box
          data-test="lead-details-page-tab-manual-review-edit-button-back"
          onClick={() => setMode('table')}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <ArrowBackIosIcon />
          <Typography marginLeft="5px">Back to the review list</Typography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box display="flex" justifyContent="space-between" marginTop="24px">
          <Box display="flex" alignItems="center" gap="20px">
            <Typography sx={{ width: '110px' }}>Assignee:</Typography>
            {(hasPermission(Permissions.assignReason) || hasPermission(Permissions.manualReviewSelfAssignUnassign)) &&
            (manualReviewReason?.status === 3 || manualReviewReason?.status === 4) ? (
              <ManualReviewDetailsAssignButton id={id} />
            ) : (
              manualReviewReason?.reviewerName ?? 'Unassigned'
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mb="15px" marginTop="5px">
          <Box display="flex" alignItems="center" gap="20px">
            <Typography sx={{ width: '110px' }}>Status:</Typography>
            <StatusBadgeTitle Statuses={ManualReviewStatuses} status={`${manualReviewReason?.status}`} />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="20px" mb="20px">
          <Typography sx={{ width: '110px' }}>Reason:</Typography>
          <Typography fontWeight={700}>
            {ManualReviewItems[manualReviewReason?.name as keyof typeof ManualReviewItems]}
          </Typography>
        </Box>
        {manualReviewReason?.reviewLink ? (
          <Box display="flex" alignItems="center" gap="20px" mb="20px">
            <Typography sx={{ width: '110px' }}>Review Link:</Typography>
            <Typography fontWeight={700}>{manualReviewReason?.reviewLink}</Typography>
          </Box>
        ) : null}
        {manualReviewReason?.requiredIncome || manualReviewReason?.requiredIncome === 0 ? (
          <Box display="flex" alignItems="center" gap="20px" mb="20px">
            <Typography sx={{ width: '110px' }}>Required Income:</Typography>
            <Typography fontWeight={700}>${manualReviewReason?.requiredIncome}</Typography>
          </Box>
        ) : null}
        {manualReviewReason?.solarPanelDetails && manualReviewReason?.solarPanelDetails.length ? (
          <Box marginBottom="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ fontWeight: 700, marginTop: '16px', marginBottom: '16px' }}>
                Solar Panel Details
              </Typography>
              {(manualReviewReason?.status === 3 || manualReviewReason?.status === 4) &&
                isMe(manualReviewReason.reviewerId) && <CustomButton onClick={onSolarPanelEdit}>Edit</CustomButton>}
            </Box>
            <SolarPanelDetailsTable panelDetails={manualReviewReason.solarPanelDetails} />
          </Box>
        ) : null}
        {manualReviewReason?.aanReasons && manualReviewReason?.aanReasons?.length ? (
          <Box display="flex" alignItems="flex-start" gap="20px" mb="20px">
            <Typography sx={{ width: '110px' }}>Reject Reason:</Typography>
            <Box>
              {manualReviewReason?.aanReasons.map((el) => (
                <Typography fontWeight={700}>{el}</Typography>
              ))}
            </Box>
          </Box>
        ) : null}
        <CustomTextArea
          onChange={({ target }) => setNote(target.value)}
          label="Notes"
          defaultValue={manualReviewReason?.notes}
          disabled={(manualReviewReason?.status !== 3 && manualReviewReason?.status !== 4) || !canReviewReason}
          placeholder="Type a note..."
        />
        {manualReviewReason ? (
          <Box>
            <Typography sx={{ fontWeight: 700, marginTop: '16px', marginBottom: '8px' }}>
              Manually Uploaded Files
            </Typography>
            <Box>
              <Box
                sx={{ border: '1px solid #E2E8F0', borderRadius: '12px', padding: '20px' }}
                className="last_block_m_0"
              >
                {manualReviewReason?.documents?.length !== 0 ? (
                  manualReviewReason?.documents?.map((file) => (
                    <SingleFileAttachment
                      key={file.id}
                      file={file}
                      showStatus={false}
                      deleteFile={
                        manualReviewReason?.status === 3 || manualReviewReason?.status === 4 ? onDeleteFile : undefined
                      }
                      canView
                    />
                  ))
                ) : (
                  <Box textAlign="center">
                    <Typography sx={{ color: '#95A1B1', fontSize: '16px', fontWeight: 400 }}>
                      No files have been uploaded yet
                    </Typography>
                  </Box>
                )}
                {canReviewReason &&
                  hasPermission(Permissions.manualReviewUploadDocument) &&
                  (manualReviewReason?.status === 3 || manualReviewReason?.status === 4) && (
                    <Box display="flex" justifyContent="center">
                      <UploadFileButton onClick={onUpload} />
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
        ) : null}
        {manualReviewReason ? (
          <Box>
            <Typography sx={{ fontWeight: 700, marginTop: '20px', marginBottom: '-8px' }}>Received Files</Typography>
            <Box key={manualReviewReason?.reviewerId}>
              {manualReviewReason?.externalDocuments?.length !== 0 ? (
                manualReviewReason?.externalDocuments?.map((fileGroup) => (
                  <ExternalDocumentGroup
                    key={fileGroup.dateTime}
                    fileGroup={fileGroup}
                    reasonId={id}
                    canReviewReason={canReviewReason && manualReviewReason?.name !== 'ProjectApproval'}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    border: '1px solid #E2E8F0',
                    borderRadius: '12px',
                    padding: '20px',
                    textAlign: 'center',
                    marginTop: '16px',
                  }}
                >
                  <Typography sx={{ color: '#95A1B1', fontSize: '16px', fontWeight: 400 }}>
                    No files have been received yet
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : null}
        {(manualReviewReason?.status === 3 || manualReviewReason?.status === 4) && isMe(manualReviewReason.reviewerId) && (
          <Permission permission={Permissions.manualReviewAcceptReject}>
            <Box display="flex" justifyContent="space-between" marginTop="24px">
              <CustomButton
                color="error"
                variant="contained"
                sx={{ backgroundColor: '#FF497F', borderColor: '#FF497F' }}
                onClick={onRejectClick}
              >
                Reject Application
              </CustomButton>
              <CustomButton
                disabled={
                  manualReviewReason?.name !== 'ProjectApproval' && // reason based handling
                  canAccept(manualReviewReason?.externalDocuments)
                }
                onClick={() => resolve(1)}
                variant="contained"
              >
                Accept Reason
              </CustomButton>
            </Box>
          </Permission>
        )}
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default ManualReviewDetails;
