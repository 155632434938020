import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.leads;

const error = createSelector([selector], (state) => state.error);
const journeyStatus = createSelector([selector], (state) => state.journeyStatus);
const journey = createSelector([selector], (state) => state.journey);
const isLeadsJourneyLoading = createSelector([selector], (state) => state.isLeadsJourneyLoading);
const leadsDetails = createSelector([selector], (state) => state.leadsDetails);
const isLeadsDetailsLoading = createSelector([selector], (state) => state.isLeadsDetailsLoading);
const leads = createSelector([selector], (state) => state.leads);
const isLeadsListLoading = createSelector([selector], (state) => state.isLeadsListLoading);
const leadsFilters = createSelector([selector], (state) => state.leadsFilters);
const leadsCount = createSelector([selector], (state) => state.leadsCount);
const logs = createSelector([selector], (state) => state.logs);
const logsLoading = createSelector([selector], (state) => state.logsLoading);
const funds = createSelector([selector], (state) => state.funds);
const fundsLoading = createSelector([selector], (state) => state.fundsLoading);
const rerunLoading = createSelector([selector], (state) => state.rerunLoading);
const rerunSteps = createSelector([selector], (state) => state.rerunSteps);
const applicationJourney = createSelector([selector], (state) => state.applicationJourney);
const applicationJourneyLoading = createSelector([selector], (state) => state.applicationJourneyLoading);
const documentFile = createSelector([selector], (state) => state.documentFile);
const fetchFileLoading = createSelector([selector], (state) => state.fetchFileLoading);
const leadDetailsInitialTab = createSelector([selector], (state) => state.leadDetailsInitialTab);
const documentsMenu = createSelector([selector], (state) => state.documentsMenu);
const documentsMenuLoading = createSelector([selector], (state) => state.documentsMenuLoading);
const documentsList = createSelector([selector], (state) => state.documentsList);
const documentsListLoading = createSelector([selector], (state) => state.documentsListLoading);
const documentsUploadTypes = createSelector([selector], (state) => state.documentsUploadTypes);
const documentsUploadLoading = createSelector([selector], (state) => state.documentsUploadLoading);
const filePreviewLoading = createSelector([selector], (state) => state.filePreviewLoading);
const fileDownloadLoading = createSelector([selector], (state) => state.fileDownloadLoading);
const revertLoading = createSelector([selector], (state) => state.revertLoading);
const resendEmailLoading = createSelector([selector], (state) => state.resendEmailLoading);
const resendEmail = createSelector([selector], (state) => state.resendEmail);
const notesAddOrEditLoading = createSelector([selector], (state) => state.notesAddOrEditLoading);
const notesList = createSelector([selector], (state) => state.notesList);
const notesListLoading = createSelector([selector], (state) => state.notesListLoading);
const notesUsers = createSelector([selector], (state) => state.notesUsers);
const generalInformationData = createSelector([selector], (state) => state.generalInformationData);

export default {
  error,
  isLeadsDetailsLoading,
  journeyStatus,
  journey,
  isLeadsJourneyLoading,
  leadsDetails,
  leads,
  isLeadsListLoading,
  leadsCount,
  leadsFilters,
  logs,
  logsLoading,
  funds,
  fundsLoading,
  rerunLoading,
  rerunSteps,
  applicationJourney,
  applicationJourneyLoading,
  documentFile,
  fetchFileLoading,
  leadDetailsInitialTab,
  documentsMenu,
  documentsMenuLoading,
  documentsList,
  documentsListLoading,
  documentsUploadTypes,
  documentsUploadLoading,
  filePreviewLoading,
  fileDownloadLoading,
  revertLoading,
  resendEmailLoading,
  resendEmail,
  notesAddOrEditLoading,
  notesList,
  notesListLoading,
  notesUsers,
  generalInformationData,
};
