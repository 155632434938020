import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MainCard from 'components/UI/MainCard';

import { CustomButton } from '../../components';
import { CreateCampaignForm } from '../../components/CreateCampaignForm';
import XmlModal from '../../components/Modal/XmlModal';
import { Permissions } from '../../components/Permissions/constants';
import Permission, { hasPermission } from '../../components/Permissions/Permission';
import Loading from '../../components/UI/Loading';
import { CampaignFieldsUpdate } from '../../helpers/CampaignFieldsUpdate';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { systemFieldsMiddleware, systemFieldsSelector } from '../../redux/slices/systemFields';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { ICampaignField, ICampaignForm } from '../../types/campaigns';

import classes from '../../assets/scss/campaigns.module.scss';

const CreateCampaign = ({ mode }: { mode: 'EDIT' | 'CREATE' }) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRequiredAll, setIsRequiredAll] = useState<boolean | null>(null);
  const [isHashAll, setIsHashAll] = useState<boolean | null>(null);
  const [isUploadedFile, setIsUploadedFile] = useState<boolean>(false);
  const [isAllHashSelected, setIsAllHashSelected] = useState<boolean>(false);
  const [isAllRequiredSelected, setIsAllRequiredSelected] = useState<boolean>(false);

  const isSystemFieldsLoading = useAppSelector(systemFieldsSelector.isLoading);

  const fieldByDataType = useAppSelector(campaignsSelector.fieldByDataType);
  const currentCampaignById = useAppSelector(campaignsSelector.currentCampaignById);
  const campaignFields = useAppSelector(campaignsSelector.campaignFields);
  const createCampaignsWithStepper = useAppSelector(campaignsSelector.createCampaignsWithStepper);

  const isValidatorsLoading = useAppSelector(campaignsSelector.isValidatorsLoading);
  const isCampaignFieldsLoading = useAppSelector(campaignsSelector.isCampaignFieldsLoading);
  const isFieldByDataTypeLoading = useAppSelector(campaignsSelector.isFieldByDataTypeLoading);
  const isUpdateCampaignLoading = useAppSelector(campaignsSelector.isUpdateCampaignLoading);

  const isDispatchHistoryLoading = useAppSelector(campaignsSelector.isLoading);

  const [isCampaignPageLoading, setIsCampaignPageLoading] = useState(
    !isValidatorsLoading && !isDispatchHistoryLoading && !isSystemFieldsLoading && !isLoading,
  );

  const [isTableLoading, setIsTableLoading] = useState(false);

  const form = useForm<ICampaignForm>({
    mode: 'onChange',
  });

  const { handleSubmit, reset, watch } = form;

  const onSubmit = (data: ICampaignForm) => {
    dispatch(campaignsMiddleware.fetchSetFieldsByDataTypeSuccess(data as unknown as ICampaignField[]));

    const campaignFieldsArr: ICampaignField[] = Object.values(data).flat() as ICampaignField[];

    // TODO Fix form returning checkbox value as boolean and remove this part of code
    const campaignFieldsArrUpdated = campaignFieldsArr.map((campaignField) => {
      const { validatorSettingsXml, ...newCampaignField } = campaignField;

      return {
        ...newCampaignField,
        validatorSettings:
          campaignField.validator === 1
            ? [
                { name: 'MinLength', value: '0' },
                { name: 'MaxLength', value: '100' },
              ]
            : campaignField.validatorSettings,
        campaignId: mode === 'CREATE' ? createCampaignsWithStepper?.id : currentCampaignById?.id,
        isRequired: (newCampaignField.isRequired as unknown) === 'false' ? true : newCampaignField.isRequired,
        isHash: (newCampaignField.isHash as unknown) === 'false' ? true : newCampaignField.isHash,
        isDeleted: false,
      };
    });

    let updatedCampaignFields = [];

    if (campaignFields) {
      updatedCampaignFields = [...CampaignFieldsUpdate(campaignFields, campaignFieldsArrUpdated as ICampaignField[])];
    } else {
      updatedCampaignFields = [...campaignFieldsArrUpdated];
    }

    if (mode === 'CREATE' && createCampaignsWithStepper?.id) {
      dispatch(
        campaignsMiddleware.fetchUpdateCampaignFields(
          updatedCampaignFields as ICampaignField[],
          createCampaignsWithStepper.id,
          mode,
        ),
      );
    } else if (currentCampaignById?.id) {
      dispatch(
        campaignsMiddleware.fetchUpdateCampaignFields(
          updatedCampaignFields as ICampaignField[],
          currentCampaignById.id,
          mode,
        ),
      );
    }
  };

  const handleCloneClick = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmCampaignCloneModal,
        props: {
          mode,
        },
      }),
    );
  };

  useEffect(() => {
    const arr: ICampaignField[] = [];

    Object.values(watch()).forEach((item) => {
      if (Array.isArray(item)) {
        arr.push(...item);
      } else {
        arr.push(item);
      }
    });

    if (arr.every((item) => item.isHash)) {
      setIsAllHashSelected(true);
    } else {
      setIsAllHashSelected(false);
    }

    if (arr.every((item) => item.isRequired)) {
      setIsAllRequiredSelected(true);
    } else {
      setIsAllRequiredSelected(false);
    }
  }, [watch()]);

  useEffect(() => {
    setIsCampaignPageLoading(!isValidatorsLoading && !isDispatchHistoryLoading && !isSystemFieldsLoading && !isLoading);
  }, [isValidatorsLoading, isDispatchHistoryLoading, isSystemFieldsLoading, isLoading]);

  useEffect(() => {
    dispatch(campaignsMiddleware.fetchTimeFormatSuccess());
    dispatch(campaignsMiddleware.fetchValidators());
    dispatch(systemFieldsMiddleware.fetchGetSystemFields());
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (fieldByDataType && !Object.keys(fieldByDataType)?.length) {
      reset({});
    }
  }, [fieldByDataType]);

  useEffect(() => {
    if (!(createCampaignsWithStepper?.id || currentCampaignById?.id)) {
      navigate(`/products`);
    }

    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
      dispatch(campaignsMiddleware.fetchEmptyCampaignFields());
    };
  }, [mode]);

  if (!isCampaignPageLoading || isCampaignFieldsLoading) {
    return <Loading />;
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Box sx={{ border: 0, width: '100%', marginTop: '24px' }}>
        <MainCard
          contentClass={classes.root}
          sx={{ border: 0, paddingBottom: '0 !important' }}
          contentSX={
            mode === 'CREATE'
              ? { height: 'calc(100vh - 139px)', padding: '20px 30px' }
              : { padding: 0, paddingBottom: '0 !important' }
          }
          border={false}
        >
          <Grid container item display="flex" xs={12} alignItems="center">
            <Grid container item alignItems="center" justifyContent="space-between">
              <Grid container item>
                <Grid container item gap={2} justifyContent="flex-end" alignItems="end" marginBottom="20px">
                  <Permission permission={Permissions.integrationFileUpload}>
                    <Grid item>
                      <Typography color="#454256">Upload XML or JSON</Typography>
                      <CustomButton
                        data-test={
                          mode === 'EDIT'
                            ? 'campaign-details-tab-general-information-button-insert-file'
                            : 'campaign-create-page-button-insert-file'
                        }
                        onClick={() => setOpen((prev) => !prev)}
                      >
                        Insert File
                      </CustomButton>
                    </Grid>
                  </Permission>
                  <Permission permission={Permissions.cloneProduct}>
                    <Grid item>
                      <Typography color="#454256" />
                      <CustomButton
                        data-test={
                          mode === 'EDIT'
                            ? 'campaign-details-tab-general-information-button-clone'
                            : 'campaign-create-page-button-clone'
                        }
                        onClick={handleCloneClick}
                      >
                        Clone
                      </CustomButton>
                    </Grid>
                  </Permission>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {(campaignFields && Object.keys(campaignFields)?.length) ||
          (fieldByDataType && Object.keys(fieldByDataType)?.length) ? (
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TableContainer
                  sx={{
                    maxHeight: mode === 'CREATE' ? 'calc(100vh - 332px)' : 'calc(100vh - 462px)',
                    minHeight: '0 !important',
                    padding: '0 !important',
                  }}
                >
                  <Table data-test="campaigns-page-creat-campaign-table" stickyHeader aria-label="sticky table">
                    <TableHead
                      sx={{
                        backgroundColor: 'white',
                      }}
                    >
                      <TableRow sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
                        <TableCell>Product Field</TableCell>
                        <TableCell>System Field</TableCell>
                        <TableCell>Data Type</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>
                          <FormControl
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              flexWrap: 'nowrap',
                              flexDirection: 'row',
                            }}
                          >
                            <Checkbox
                              onChange={() => setIsRequiredAll(!isRequiredAll)}
                              checked={isAllRequiredSelected}
                              disabled={!hasPermission(Permissions.editIntegrationFields)}
                              sx={{
                                width: '18px',
                                height: '18px',
                                marginLeft: '10px',
                                marginRight: '3px',
                              }}
                            />

                            <Box component="span">Select All</Box>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              flexWrap: 'nowrap',
                              flexDirection: 'row',
                            }}
                          >
                            <Checkbox
                              onChange={() => setIsHashAll(!isHashAll)}
                              checked={isAllHashSelected}
                              disabled={!hasPermission(Permissions.editIntegrationFields)}
                              sx={{
                                width: '18px',
                                height: '18px',
                                marginLeft: '10px',
                                marginRight: '3px',
                              }}
                            />

                            <Box component="span">Select All</Box>
                          </FormControl>
                        </TableCell>
                        {hasPermission(Permissions.editIntegrationFields) ? <TableCell>Action</TableCell> : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isFieldByDataTypeLoading && fieldByDataType && Object.keys(fieldByDataType)?.length
                        ? Object.keys(fieldByDataType).map((section, index) => (
                            <CreateCampaignForm
                              mode={mode}
                              sectionName={section}
                              key={section}
                              // @ts-ignore
                              data={fieldByDataType[section]}
                              isHashAll={isHashAll}
                              setIsTableLoading={setIsTableLoading}
                              isRequiredAll={isRequiredAll}
                              type={index === 0 ? 'reset' : 'update'}
                              isUploadedFile={isUploadedFile}
                            />
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {isTableLoading && <Loading />}
                </TableContainer>
                <Permission permission={Permissions.editIntegrationFields}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '24px',
                      marginBottom: '4px',
                    }}
                  >
                    <CustomButton
                      data-test="campaign-details-tab-general-information-button-cancel"
                      variant="outlined"
                      onClick={() => navigate('/products', { replace: true })}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      data-test="campaign-details-tab-general-information-button-save"
                      type="submit"
                      disabled={isUpdateCampaignLoading}
                      loading={isUpdateCampaignLoading}
                    >
                      Save
                    </CustomButton>
                  </Box>
                </Permission>
              </form>
            </FormProvider>
          ) : null}
        </MainCard>
        {open && <XmlModal show={open} onHide={setOpen} dataFieldType={1} setIsUploadedFile={setIsUploadedFile} />}
      </Box>
    </React.Suspense>
  );
};

export default CreateCampaign;
