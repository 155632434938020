import { useState } from 'react';
import { Box, Checkbox, Dialog, Switch, TextField, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { CustomButton } from '../../shared';

import useCapModal from './hooks/useCapModal';

export interface ICapModalProps {
  index: number;
}

const CapModal = () => {
  const { onClose, onConfirm } = useCapModal();
  const [capChecked, setCapChecked] = useState<boolean>(true);
  const [limit, setLimit] = useState<string>('');
  const [bypass, setBypass] = useState<boolean>(true);
  const [reject, setReject] = useState<boolean>(false);

  return (
    <Dialog data-test="cap-modal" open onClose={onClose} maxWidth="lg">
      <Box padding="20px 40px">
        <Box display="flex" alignItems="center">
          <Switch
            data-test="cap-modal-switch-cap"
            checked={capChecked}
            onChange={() => {
              setCapChecked((prev) => !prev);
            }}
            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
          />
          <Typography>CAP</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography marginRight="10px">Daily Limit</Typography>
          <TextField
            data-test="cap-modal-input-daily-limit"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            placeholder="Type"
            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            data-test="cap-modal-checkbox-bypass"
            checked={bypass}
            onChange={() => {
              setBypass((prev) => !prev);
            }}
            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
          />
          <Typography>Bypass if the cap is exceeded</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox
            data-test="cap-modal-checkbox-reject"
            checked={reject}
            onChange={() => {
              setReject((prev) => !prev);
            }}
            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
          />
          <Typography>Reject if the cap is exceeded</Typography>
        </Box>
        <Permission permission={Permissions.changeDecisionTreeFilter}>
          <Box display="flex" alignItems="center">
            <CustomButton data-test="cap-modal-button-cancel" onClick={onClose} variant="outlined">
              Cancel
            </CustomButton>
            <CustomButton data-test="cap-modal-button-save" onClick={onConfirm}>
              Save
            </CustomButton>
          </Box>
        </Permission>
      </Box>
    </Dialog>
  );
};

export default CapModal;
