import { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Loading from 'components/UI/Loading';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { IInputTag, ITenantSettings } from '@types';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../redux/slices/settings';

import InputWithTags from './InputWithTags';

/**
  We don't use form here, because in InputWithTags component we always have form, where we trigger Enter button
 */

const TenantSettings = ({ settings }: { settings: ITenantSettings }) => {
  const { dailyCapReached, dailyCapReachedDate } = settings;
  const errorMessage = `Daily CAP has been reached at ${dailyCapReachedDate}`;

  const tenantSettingsLoading = useAppSelector(settingsSelector.tenantSettingsLoading);
  const [tags, setTags] = useState<IInputTag[]>(settings.tenantEmailAddress);
  const [cap, setCap] = useState(settings.dailyCapEnabled ? 'enabled' : 'disabled');
  const [amount, setAmount] = useState<number | null>(settings.dailyCap);
  const [isError, setIsError] = useState(false);

  const onSubmit = () => {
    if (amount && amount >= 1000) {
      dispatch(
        settingsMiddleware.updateTenantSettings({
          dailyCap: amount,
          dailyCapEnabled: cap === 'enabled',
          dailyCapReached,
          dailyCapReachedDate,
          tenantEmailAddress: tags,
        }),
      );
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleDisable = () => {
    setCap('disabled');

    if (!amount || amount < 1000) {
      setAmount(settings.dailyCap);
      setIsError(false);
    }
  };

  const openConfirm = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ResetDailyCapModal,
        props: {},
      }),
    );
  };

  if (tenantSettingsLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
      }}
    >
      <Typography fontSize="20px" fontWeight={700}>
        Tenant Settings
      </Typography>
      <Box marginTop="20px">
        <Typography>Daily CAP</Typography>
        <Box>
          <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} defaultValue={cap}>
            <FormControlLabel
              control={<Radio onClick={handleDisable} value="disabled" color="secondary" name="cap" />}
              label="Disabled"
            />
            <FormControlLabel
              control={<Radio onClick={() => setCap('enabled')} value="enabled" color="secondary" name="cap" />}
              label="Enabled"
            />
          </RadioGroup>
          {dailyCapReached && dailyCapReachedDate && cap === 'enabled' && (
            <>
              <Alert severity="error">
                <AlertTitle
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    color: '#FF497F',
                  }}
                >
                  {errorMessage}
                </AlertTitle>
              </Alert>
              <Box textAlign="right">
                <Button variant="text" color="secondary" onClick={openConfirm}>
                  Reset
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box>
        <FormControl required fullWidth>
          <InputLabel>CAP amount</InputLabel>
          <OutlinedInput
            disabled={cap === 'disabled'}
            value={amount}
            startAdornment="$"
            type="number"
            onChange={(e) => setAmount(e.target.value ? Number(e.target.value) : null)}
          />
          {isError && <FormHelperText error>A minimum CAP amount of $1,000 is required.</FormHelperText>}
        </FormControl>
      </Box>
      <Box marginTop="20px">
        <InputLabel>External Recipients</InputLabel>
        <InputWithTags tags={tags} setTags={setTags} disabled={cap === 'disabled'} />
      </Box>
      <Box>
        <Button variant="outlined" color="secondary" onClick={onSubmit}>
          SAVE
        </Button>
      </Box>
    </Box>
  );
};

export default TenantSettings;
