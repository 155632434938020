import { TextField } from '@mui/material';

interface IInputLabelProps {
  shrink: boolean;
}
export interface ICustomTextFieldProps {
  dataTest: string;
  className?: string;
  id?: string;
  label?: string;
  inputLabelProps?: IInputLabelProps;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  helperText?: string;
  value?: string | number | null;
  name?: string;
  fullWidth?: boolean;
  error?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const TableTextField = ({
  onChange,
  onKeyDown,
  label,
  className,
  id,
  inputLabelProps,
  placeholder,
  name,
  required,
  dataTest,
  helperText,
  value,
  fullWidth,
  error,
  type,
  ...props
}: ICustomTextFieldProps) => (
  <TextField
    {...props}
    name={name}
    placeholder={placeholder}
    label={label}
    InputLabelProps={inputLabelProps}
    type={type}
    className={className}
    id={id}
    error={error}
    value={value}
    required={required}
    data-test={dataTest}
    fullWidth={fullWidth}
    helperText={helperText}
    onChange={onChange}
    InputProps={{onKeyDown}}
    sx={{
      '& input': {
        backgroundColor: 'white',
      },
    }}
  />
);

export default TableTextField;
