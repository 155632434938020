import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import dayjs from 'dayjs';

import { CustomButton, CustomPlusButton } from '../../shared';

import useScheduleModal from './hooks/useScheduleModal';

export interface IScheduleModalProps {
  index: number;
}

const InitialScheduleData = [
  {
    weekDays: [1, 2, 3, 4, 5, 6, 7],
    startDate: '',
    endDate: '',
  },
];

const ScheduleModal = () => {
  const { onClose } = useScheduleModal();

  const { control, watch, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: { data: InitialScheduleData },
  });

  const handleChange = (event: SelectChangeEvent<number[]>, index: number) => {
    const {
      target: { value },
    } = event;

    if (typeof value !== 'string') {
      setValue(`data.${index}.weekDays`, value);
    }
  };

  const { fields, remove, append } = useFieldArray({ control, name: 'data' });

  const scheduleDateDropdownOptions = [
    {
      id: 1,
      label: 'Monday',
    },
    {
      id: 2,
      label: 'Tuesday',
    },
    {
      id: 3,
      label: 'Wednesday',
    },
    {
      id: 4,
      label: 'Thursday',
    },
    {
      id: 5,
      label: 'Friday',
    },
    {
      id: 6,
      label: 'Saturday',
    },
    {
      id: 7,
      label: 'Sunday',
    },
  ];

  const getOptionLabels = (selected: number[]) => {
    const labels: string[] = [];

    selected.forEach((el: number) => {
      const singleLabel = scheduleDateDropdownOptions.find((item) => item.id === el)?.label;

      if (singleLabel) {
        labels.push(singleLabel);
      }
    });

    return labels.join(', ');
  };

  const onFieldRemove = (index: number) => {
    remove(index);
  };

  const onFieldAppend = () => {
    append(InitialScheduleData);
  };

  return (
    <Dialog data-test="schedule-modal" open onClose={onClose} maxWidth="lg">
      <Box sx={{ padding: '12px 24px', overflow: 'hidden' }}>
        <Typography sx={{ fontSize: '16px' }}>SCHEDULE: All of the following are true…</Typography>
        <form>
          <Box sx={{ marginTop: '16px', maxHeight: '400px', overflowX: 'hidden', overflowY: 'auto' }}>
            {fields.map((item, index) => (
              <Box marginTop="8px" data-test={`schedule-modal-table-id-${index}`}>
                <FormControl sx={{ m: 1, marginLeft: 0, marginTop: '0px !important', width: 300 }}>
                  <InputLabel sx={{ marginBottom: '7px' }} id="demo-multiple-checkbox-label">
                    Days of week
                  </InputLabel>
                  <Select
                    data-test={`schedule-modal-table-id-${index}-dropdown-week-days`}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={watch(`data.${index}.weekDays`)}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={getOptionLabels}
                    disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                  >
                    {scheduleDateDropdownOptions.map((day) => (
                      <MenuItem key={day.id} value={day.id}>
                        <Checkbox checked={watch(`data.${index}.weekDays`).indexOf(day.id) > -1} />
                        <ListItemText primary={day.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl sx={{ minWidth: '70px', margin: '0 8px' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Start time</InputLabel>
                    <TimePicker
                      data-test={`schedule-modal-table-id-${index}-input-start`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Start"
                          sx={{
                            border: 0,
                            minWidth: '60px !important',
                            '& .MuiInputBase-root': {
                              height: '40px',
                            },
                          }}
                        />
                      )}
                      onChange={(value) => {
                        setValue(`data.${index}.startDate`, dayjs(value).format('HH:mm'));
                      }}
                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                      value={
                        dayjs()
                          .set('hours', Number(watch(`data.${index}.startDate`).split(':')[0]))
                          .set('minutes', Number(watch(`data.${index}.startDate`).split(':')[1])) ?? ''
                      }
                    />
                  </FormControl>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl sx={{ minWidth: '70px', margin: '0 8px' }}>
                    <InputLabel id="demo-multiple-checkbox-label">End time</InputLabel>
                    <TimePicker
                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                      data-test={`schedule-modal-table-id-${index}-input-end`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="End"
                          sx={{
                            border: 0,
                            minWidth: '60px !important',
                            '& .MuiInputBase-root': {
                              height: '40px',
                            },
                          }}
                        />
                      )}
                      onChange={(value) => setValue(`data.${index}.endDate`, dayjs(value).format('HH:mm'))}
                      value={
                        dayjs()
                          .set('hours', Number(watch(`data.${index}.endDate`).split(':')[0]))
                          .set('minutes', Number(watch(`data.${index}.endDate`).split(':')[1])) ?? ''
                      }
                    />
                  </FormControl>
                </LocalizationProvider>
                <Permission permission={Permissions.changeDecisionTreeFilter}>
                  <IconButton
                    data-test={`schedule-modal-table-id-${index}-button-delete`}
                    sx={{
                      paddingTop: '30px',
                      paddingRight: 0,
                      width: '24px',
                      '&:hover': {
                        background: 'inherit !important',
                      },
                    }}
                    onClick={() => {
                      onFieldRemove(index);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Permission>
              </Box>
            ))}
            <CustomPlusButton
              sx={{ margin: 0, padding: 0, paddingTop: '8px', justifyContent: 'flex-start' }}
              data-test="schedule-modal-button-add-condition"
              title="Add condition"
              onClick={onFieldAppend}
            />
          </Box>
          <Permission permission={Permissions.changeDecisionTreeFilter}>
            <Box display="flex" justifyContent="center" marginTop="24px" gap="16px">
              <CustomButton
                data-test="schedule-modal-button-cancel"
                variant="outlined"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test="schedule-modal-button-submit"
                type="submit"
                onClick={() => {
                  onClose();
                }}
                sx={{ marginLeft: '0 !important' }}
              >
                Submit
              </CustomButton>
            </Box>
          </Permission>
        </form>
      </Box>
    </Dialog>
  );
};

export default ScheduleModal;
