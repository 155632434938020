import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Breadcrumbs,
  FormControl as MuiFormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import FormTextField from 'components/shared/TextField/FormTextField';
import MainCard from 'components/UI/MainCard';

import { CustomButton } from '../../components';
import { Permissions } from '../../components/Permissions/constants';
import FormSelect, { IControlledSelectOptions } from '../../components/shared/Select/FormSelect';
import { StyledLink } from '../../components/shared/StyledComponents';
import Loading from '../../components/UI/Loading';
import { trimProperties } from '../../helpers';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { createOrEditSourceChannel } from '../../validation/affiliate/createOrEditSourceChannel';

import classes from '../../assets/scss/campaigns.module.scss';

export interface AffiliateChannelFormValues {
  id?: string;
  campaignId: string;
  channelName: string;
  affiliateId: string;
  dataFormat: number;
  CAP: number;
  timeout: string;
  status: number;
  notes: string;
  overallCAP: boolean;
}

const StatusOptions = [
  { value: 0, label: 'Inactive' },
  { value: 1, label: 'Active' },
];

const AffiliatesChannelCreatePage = ({
  defaultValue,
  mode,
}: {
  defaultValue?: AffiliateChannelFormValues;
  mode?: 'EDIT' | 'CREATE';
}) => {
  const navigate = useNavigate();
  const { id: affiliateChannelId } = useParams();

  const dataFormats = useAppSelector(affiliatesSelector.dataFormats);
  const successfully = useAppSelector(affiliatesSelector.successfully);
  const isUpdateAffiliateChannelLoading = useAppSelector(affiliatesSelector.isUpdateAffiliateChannelLoading);
  const isCreateAffiliateChannelLoading = useAppSelector(affiliatesSelector.isCreateAffiliateChannelLoading);
  const affiliates = useAppSelector(affiliatesSelector.simplifiedAffiliates);

  const campaigns = useAppSelector(campaignsSelector.simplifiedCampaigns);
  const isCreateAffiliateLoading = useAppSelector(campaignsSelector.isCreateAffiliateLoading);
  const isAffiliatesLoading = useAppSelector(campaignsSelector.isAffiliatesLoading);
  const isCampaignsListLoading = useAppSelector(campaignsSelector.isCampaignsListLoading);

  const methods = useForm<AffiliateChannelFormValues>({
    defaultValues: {
      ...(mode === 'EDIT' ? { id: defaultValue?.id ?? '' } : {}),
      campaignId: defaultValue?.campaignId,
      channelName: defaultValue?.channelName ?? '',
      affiliateId: defaultValue?.affiliateId ?? '0',
      dataFormat: defaultValue?.dataFormat ?? 1,
      timeout: defaultValue?.timeout ?? '',
      notes: defaultValue?.notes ?? '',
      status: defaultValue?.status ?? 0,
    },
    mode: 'onSubmit',
    resolver: yupResolver(createOrEditSourceChannel),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const isCampaignLoading = isCreateAffiliateLoading || isAffiliatesLoading || isCampaignsListLoading;

  const isLoading = isUpdateAffiliateChannelLoading || isCreateAffiliateChannelLoading;
  const isDisabled = isLoading;

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchDataFormatsList());
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
  }, []);

  useEffect(() => {
    if (mode !== 'EDIT') {
      setValue('campaignId', (campaigns && campaigns[0].id) ?? '0');
      setValue('affiliateId', (affiliates && affiliates[0].id) || '0');
    }
  }, [campaigns, affiliates]);

  useEffect(() => {
    if (!affiliates) {
      dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    }
  }, [affiliates]);

  useEffect(() => {
    if (!campaigns) {
      dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
    }
  }, [campaigns]);

  const onSubmit = (data: AffiliateChannelFormValues) => {
    if (mode === 'EDIT') {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmAffiliateChannelEditSaveModal,
          props: {
            data: trimProperties(data, ['channelName', 'notes']),
            affiliateChannelId,
          },
        }),
      );
    } else {
      const creatingData = {
        campaignId: data.campaignId,
        name: data.channelName,
        affiliateId: data.affiliateId,
        dataFormat: data.dataFormat,
        status: data.status,
        affiliateChannelKey: '',
        affiliateChannelPassword: '',
        affiliateChannelActions: [],
        notes: data.notes,
      };

      dispatch(affiliatesMiddleware.fetchCreateAffiliateChannel(trimProperties(creatingData, ['name', 'notes'])));
    }
  };

  useEffect(() => {
    if (successfully) {
      dispatch(affiliatesMiddleware.fetchHasSuccessfully(false));
    }
  }, [successfully]);

  return (
    <Box sx={{ border: 0, flex: 1 }}>
      {mode !== 'EDIT' ? (
        <Breadcrumbs
          sx={{
            color: '#707EAE',
            marginY: '10px',
          }}
        >
          <StyledLink to="/sources-channel">Source Channels</StyledLink>
          <Typography sx={{ fontWeight: 'bold' }}>Create Source Channel</Typography>
        </Breadcrumbs>
      ) : null}
      <MainCard
        contentClass={classes.root}
        contentSX={{ padding: mode === 'EDIT' ? 0 : '24px', height: '100%', paddingBottom: '0 !important' }}
        sx={{ border: 0, paddingTop: mode === 'EDIT' ? '24px' : 0, height: '100%', paddingBottom: '0 !important' }}
        border={false}
      >
        <Box
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <MainCard
                contentSX={{
                  padding: 0,
                  overflow: 'auto',
                  height: 'calc(100vh - 409px)',
                  paddingRight: '10px !important',
                }}
                sx={{ border: 0 }}
              >
                <Typography
                  component="p"
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#454256',
                    marginBottom: '24px',
                  }}
                >
                  Source Channel
                </Typography>
                <Grid container xs={2} sm={2} md={4} item>
                  <FormLabel required>Product</FormLabel>
                  <MuiFormControl id="campaignId" fullWidth>
                    {isCampaignLoading ? (
                      <Loading />
                    ) : (
                      <FormSelect
                        width="100%"
                        valueKey="id"
                        nameKey="name"
                        dataTest={
                          mode === 'EDIT'
                            ? 'affiliate-channel-edit-page-tab-information-dropdown-campaign'
                            : 'affiliate-channel-create-page-dropdown-campaign'
                        }
                        options={campaigns as IControlledSelectOptions[]}
                        name="campaignId"
                        permission={Permissions.sourceChannelInformationChange}
                        defaultValue={
                          (mode === 'EDIT' ? defaultValue?.campaignId : campaigns && campaigns[0].id) as string
                        }
                      />
                    )}
                    <FormHelperText error>{errors?.campaignId?.message ?? ' '}</FormHelperText>
                  </MuiFormControl>
                </Grid>
                <Typography
                  sx={{
                    marginTop: '20px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '19px',
                    marginBottom: '30px',
                  }}
                >
                  General Information
                </Typography>
                <Grid container rowSpacing={1} rowGap={5} columnSpacing={{ xs: 2, sm: 3, md: 4 }} alignItems="center">
                  <Grid item xs={2} sm={2} md={4} columnSpacing={1}>
                    <FormLabel required>Channel Name</FormLabel>
                    <FormTextField
                      placeholder="Channel Name"
                      name="channelName"
                      permission={Permissions.sourceChannelInformationChange}
                      dataTest={
                        mode === 'EDIT'
                          ? 'affiliate-channel-edit-page-tab-information-input-channel-name'
                          : 'affiliate-channel-create-page-input-channel-name'
                      }
                    />
                    <FormHelperText error>{errors?.channelName?.message ?? ' '}</FormHelperText>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} columnSpacing={1}>
                    <FormLabel required>Source</FormLabel>
                    {isCampaignLoading ? (
                      <Loading />
                    ) : (
                      <FormSelect
                        width="100%"
                        valueKey="id"
                        nameKey="name"
                        dataTest={
                          mode === 'EDIT'
                            ? 'affiliate-channel-edit-page-tab-information-dropdown-affiliate'
                            : 'affiliate-channel-create-page-dropdown-affiliate'
                        }
                        options={affiliates as IControlledSelectOptions[]}
                        name="affiliateId"
                        permission={Permissions.sourceChannelInformationChange}
                        defaultValue={
                          (mode === 'EDIT' ? defaultValue?.affiliateId : affiliates && affiliates[0].id) as string
                        }
                      />
                    )}
                    <FormHelperText error>{errors?.affiliateId?.message ?? ' '}</FormHelperText>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} columnSpacing={1}>
                    <FormLabel required>Data Format</FormLabel>
                    {isCampaignLoading ? (
                      <Loading />
                    ) : (
                      <FormSelect
                        width="100%"
                        valueKey="id"
                        nameKey="name"
                        dataTest={
                          mode === 'EDIT'
                            ? 'affiliate-channel-edit-page-dropdown-data-format'
                            : 'affiliate-channel-create-page-dropdown-data-format'
                        }
                        options={dataFormats ?? []}
                        name="dataFormat"
                        permission={Permissions.sourceChannelInformationChange}
                        defaultValue={
                          (mode === 'EDIT' ? defaultValue?.dataFormat : dataFormats && dataFormats[0].id) ?? 1
                        }
                      />
                    )}
                    <FormHelperText error>{errors?.dataFormat?.message ?? ' '}</FormHelperText>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} columnSpacing={1}>
                    <FormLabel required>Status</FormLabel>
                    <FormSelect
                      width="100%"
                      dataTest={
                        mode === 'EDIT'
                          ? 'affiliate-channel-edit-tab-information-page-dropdown-status'
                          : 'affiliate-channel-create-page-dropdown-status'
                      }
                      options={StatusOptions}
                      name="status"
                      permission={Permissions.sourceChannelInformationChange}
                      defaultValue={mode === 'EDIT' ? defaultValue?.status : StatusOptions[0].value}
                    />
                    <FormHelperText error>{errors?.status?.message ?? ' '}</FormHelperText>
                  </Grid>
                  <Grid item xs={2} sm={2} md={12} columnSpacing={1}>
                    <FormLabel>Notes</FormLabel>
                    <FormTextField
                      dataTest={
                        mode === 'EDIT'
                          ? 'affiliate-channel-edit-page-tab-information-dropdown-notes'
                          : 'affiliate-channel-create-page-dropdown-notes'
                      }
                      name="notes"
                      rowCount={4}
                      permission={Permissions.sourceChannelInformationChange}
                    />
                  </Grid>
                </Grid>
              </MainCard>
            </FormProvider>
          </form>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
              marginTop: '53px',
            }}
          >
            {mode !== 'EDIT' ? (
              <Box
                sx={{
                  marginBottom: '24px',
                }}
              >
                <CustomButton
                  data-test="affiliate-channel-create-page-button-create"
                  type="submit"
                  loading={isLoading}
                  disabled={isDisabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </CustomButton>
              </Box>
            ) : (
              <>
                <CustomButton
                  data-test="affiliate-channel-edit-page-tab-information-button-cancel"
                  variant="outlined"
                  onClick={() => navigate('/sources-channel', { replace: true })}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  data-test="affiliate-channel-edit-page-tab-information-button-save-changes"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save changes
                </CustomButton>
              </>
            )}
          </Box>
        </Box>
      </MainCard>
    </Box>
  );
};

export default AffiliatesChannelCreatePage;
