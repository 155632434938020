import axios from 'axios';
import { dateTime } from 'helpers';
import { dispatch } from 'redux/hooks';

import { IValidationTree, IValidationTreeActionsReqBody, ValidationTreeItem } from '@types';

import { CreateValidationTreeFields } from '../../../helpers/CreateValidationTreeFields';
import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import {
  API_AFFILIATE_CHANNELS_ACTIONS,
  API_AFFILIATE_CHANNELS_ACTIONS_GET,
  API_AFFILIATE_CHANNELS_TREE_CHANGE,
  API_AFFILIATE_CHANNELS_TREES,
  API_AFFILIATE_CHANNELS_TREES_GET,
  API_GET_CONDITIONS,
  API_MANUAL_REVIEW_REASONS_LIST,
  API_THIRD_PARTY_API,
  API_VALIDATION_TREE_GET,
  API_VALIDATION_TREE_TYPES,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { ICampaign } from '../../../types/campaigns';
import { SeveritiesType } from '../../../types/snackbar';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import slice from './slice';

const {
  setValidationTreeNameSuccess,
  setValidationTree,
  setValidationTreeLoading,
  setSingleValidationTree,
  hasError,
  setValidationTreeActions,
  setValidationTreeSuccessfully,
  setValidationTreesByIdLoading,
  setValidationTreeDeleteIsLoading,
  setValidationTreesById,
  getConditions,
  getListConditions,
  setManualReviewReasonsList,
  setValidationTreesUpdateLoading,
  setManualReviewReasonsListLoading,
  setExperianFields,
  setValidationTreeTypes,
  setValidationTreeTypesLoading,
} = slice.actions;

const fetchCreateValidationTreeName =
  ({
    affiliateChannelId,
    creditUnion,
    name,
    channelTreeType,
  }: {
    affiliateChannelId: string;
    creditUnion: number;
    name: string;
    channelTreeType: number;
  }) =>
  async () => {
    try {
      dispatch(setValidationTreeLoading(true));

      const { data: response } = await DataService.post(API_AFFILIATE_CHANNELS_TREES(), {
        affiliateChannelId,
        creditUnion,
        name,
        channelTreeType,
      });

      dispatch(
        viewsMiddleware.setRedirectionState({
          path: '/sources-channel/create/decision-rules',
          params: '',
          apply: true,
        }),
      );
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: 'Source Channel Decision Rule Name Successfully Created',
          },
        }),
      );
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelRuleNameModal));
      dispatch(setValidationTreeNameSuccess(true));
      dispatch(setValidationTree(response));
    } catch (error) {
      responseErrorHandler(error);
      dispatch(setValidationTreeNameSuccess(false));
    } finally {
      dispatch(setValidationTreeLoading(false));
    }
  };

const fetchGetValidationTreeById = (id: string) => async () => {
  try {
    dispatch(setValidationTreeLoading(true));

    const { data: response } = await DataService.getData(API_VALIDATION_TREE_GET(id));
    const showingData = CreateValidationTreeFields(response);

    dispatch(setSingleValidationTree(showingData ?? response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setValidationTreeLoading(false));
  }
};

const fetchCreateAffiliateChannelActions =
  (id: string, mode: string, data: IValidationTreeActionsReqBody[], channelId: string) => async () => {
    try {
      dispatch(setValidationTreeLoading(true));

      const { data: response } = await DataService.post(API_AFFILIATE_CHANNELS_ACTIONS(id), [...data]);

      dispatch(setValidationTreeActions(response));
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: `Decision Rules successfully ${mode === 'CREATE' ? 'created' : 'edited'}`,
          },
        }),
      );
      dispatch(
        viewsMiddleware.setRedirectionState({
          path: `/sources-channel/${channelId}`,
          params: '',
          apply: true,
        }),
      );
      dispatch(setSingleValidationTree([]));
      dispatch(setValidationTreeSuccessfully(true));
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.error,
              description: Object.keys(error?.response?.data?.errors)
                .map(
                  (el) =>
                    `${1 + +el.split('[').join('').split(']')[0]} ${error?.response?.data?.errors[el].map(
                      (element: string) => element,
                    )}`,
                )
                .join(' '),
            },
          }),
        );
      } else if (axios.isAxiosError(error)) {
        const errorText = (error?.response?.data as any)?.error
          ? (error?.response?.data as any)?.error
          : (error?.response?.data as any);

        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.error,
              description: errorText ?? 'Something went wrong.',
            },
          }),
        );
      }

      dispatch(setValidationTreeSuccessfully(false));
    } finally {
      dispatch(setValidationTreeLoading(false));
    }
  };

const fetchGetAffiliateChannelValidationTree = (id: string) => async () => {
  try {
    dispatch(setValidationTreeLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE_CHANNELS_ACTIONS_GET(id));

    dispatch(setValidationTreeActions(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setValidationTreeLoading(false));
  }
};

const fetchAffiliateChannelTreesById =
  (id: string, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(setValidationTreesByIdLoading(true));

      const { data: response } = await DataService.getData(API_AFFILIATE_CHANNELS_TREES_GET(id), {
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
      });

      response?.data?.forEach((item: ICampaign) => {
        item.updatedDate = dateTime(item.updatedDate);
        item.createdDate = dateTime(item.createdDate);
      });
      dispatch(setValidationTreesById(response));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setValidationTreesByIdLoading(false));
    }
  };

const fetchDeleteAffiliateChannelTree = (id: string, affiliateChannelId: string) => async () => {
  try {
    dispatch(setValidationTreeDeleteIsLoading(true));
    await DataService.delete(API_AFFILIATE_CHANNELS_TREES(id));

    dispatch(fetchAffiliateChannelTreesById(affiliateChannelId));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelTreeDeleteModal));
    dispatch(setValidationTreeDeleteIsLoading(false));
  }
};

const fetchUpdateValidationTree =
  (
    validationTree: ValidationTreeItem,
    data: any,
    MODE: 'CREATE' | 'EDIT',
    channelId: string,
    channelTreeType: number,
  ) =>
  async () => {
    try {
      dispatch(setValidationTreesUpdateLoading(true));

      await DataService.put(API_AFFILIATE_CHANNELS_TREE_CHANGE(validationTree.id), {
        name: validationTree.name,
        creditUnion: validationTree.creditUnion,
        channelTreeType,
      });
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: 'Source Channel Decision Rule Name Successfully Edited',
          },
        }),
      );
      await dispatch(fetchCreateAffiliateChannelActions(validationTree.id, MODE, data, channelId));
    } catch (error) {
      responseErrorHandler(error);
      dispatch(hasError(error));
    } finally {
      dispatch(setValidationTreesUpdateLoading(false));
    }
  };

const fetchConditions = () => async () => {
  try {
    const { data: response } = await DataService.getJson(API_GET_CONDITIONS());

    dispatch(getConditions(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchListConditions = (status?: string) => async () => {
  try {
    const { data: response } = await DataService.getJson(API_GET_CONDITIONS(status));

    dispatch(getListConditions(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchSetValidationTreeSuccessfully = (value: boolean) => async () => {
  try {
    dispatch(setValidationTreeSuccessfully(value));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchSetValidationTree = (value: IValidationTree) => async () => {
  try {
    dispatch(setValidationTree(value));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchSearchValidationTrees =
  (searchPattern: string, channelId: string, pageNumber = 1, pageSize = 10) =>
  async () => {
    try {
      dispatch(setValidationTreesByIdLoading(true));

      const { data: response } = await DataService.getData(
        API_AFFILIATE_CHANNELS_TREES(
          `${channelId}/search-pattern?inputValue=${searchPattern}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        ),
      );

      response?.data?.forEach((item: IValidationTree) => {
        item.updatedDate = dateTime(item.updatedDate);
        item.createdDate = dateTime(item.createdDate);
      });

      dispatch(setValidationTreesById(response));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setValidationTreesByIdLoading(false));
    }
  };

const fetchManualReviewReasonsList = () => async () => {
  try {
    dispatch(setManualReviewReasonsListLoading(true));

    const { data: response } = await DataService.getData(API_MANUAL_REVIEW_REASONS_LIST());

    dispatch(setManualReviewReasonsList(response));
  } catch (error) {
    hasError(error);
  } finally {
    dispatch(setManualReviewReasonsListLoading(false));
  }
};

const fetchExperianFields = () => async () => {
  try {
    const { data: response } = await DataService.getData(API_THIRD_PARTY_API());
    const ExperianList = response.map((el: string) => ({ id: el, name: el }));

    dispatch(setExperianFields(ExperianList));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const getValidationTreeTypes = () => async () => {
  try {
    dispatch(setValidationTreeTypesLoading(true));

    const { data: response } = await DataService.getData(API_VALIDATION_TREE_TYPES());

    dispatch(setValidationTreeTypes(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setValidationTreeTypesLoading(false));
  }
};

export default {
  fetchSetValidationTree,
  fetchSetValidationTreeSuccessfully,
  fetchCreateValidationTreeName,
  fetchGetValidationTreeById,
  fetchCreateAffiliateChannelActions,
  fetchGetAffiliateChannelValidationTree,
  fetchAffiliateChannelTreesById,
  fetchDeleteAffiliateChannelTree,
  fetchUpdateValidationTree,
  fetchConditions,
  fetchListConditions,
  fetchSearchValidationTrees,
  fetchManualReviewReasonsList,
  fetchExperianFields,
  getValidationTreeTypes,
};
