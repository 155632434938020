import moment from 'moment';

import { ACCESS_TOKEN } from '../lib/constants';

import { CookieManager } from './CookieManager';
import { PersistManager } from './PersistManager';

export const dateTime = (date: string) => moment(date).format('ll LTS');

export const isMe = (userId: string) => CookieManager.getUser()?.id === userId;

export const handleApiError = (error: any) => {
  if (error.response.status === 401) {
    CookieManager.removeCookie(ACCESS_TOKEN);
    PersistManager.clear();
    window.location.reload();
  }

  throw error;
};

export const trimProperties = (object: any, properties: string[]): any => {
  const trimmedObject = { ...object };

  properties.forEach((property: string) => {
    if (typeof trimmedObject[property] === 'string') {
      trimmedObject[property] = trimmedObject[property].trim();
    }
  });

  return trimmedObject;
};

export const isDeepEqual = (obj1: any, obj2: any) => {
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  return obj1 === obj2;
};

export const hidePiiDisabledFields = ({
  object,
  properties,
  hideBorrower = false,
}: {
  object: any;
  properties: string[];
  hideBorrower?: boolean;
}): any => {
  const changedObject = { ...object };

  properties.forEach((el) => {
    changedObject[el] = 'Hidden';

    if (hideBorrower) {
      changedObject.borrower = 'Hidden';
    }
  });

  return changedObject;
};
