import axios from 'axios';
import { dateTime, trimProperties } from 'helpers';
import { dispatch } from 'redux/hooks';

import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import {
  API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID,
  API_CAMPAIGN_BY_ID,
  API_CAMPAIGN_FIELDS,
  API_CAMPAIGNS,
  API_FIELDS,
  API_GET_DATA_TIME_FORMATS,
  API_GET_HISTORY_CAMPAIGNS,
  API_GET_VALIDATORS,
  API_SIMPLIFIED_CAMPAIGNS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { campaignModel, ICampaign, ICampaignDetails, ICampaignField } from '../../../types/campaigns';
import { SeveritiesType } from '../../../types/snackbar';
import store from '../../store';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import slice from './slice';

const {
  hasError,
  hasSuccessfully,
  setTimeFormatSuccess,
  setHistory,
  setValidatorsLoading,
  setCampaignHistoryLoading,
  setCampaignsListLoading,
  setTimeFormatSuccessLoading,
  setDeleteCampaignLoading,
  setUpdateCampaignStatusLoading,
  setCreateCampaignLoading,
  setUpdateCampaignLoading,
  setUpdateCampaignDetailsLoading,
  setAffiliateChannelsByCampaignIdLoading,
  setSystemFieldsByCampaignIdLoading,
  setCampaignFieldsLoading,
  setFieldByDataTypeLoading,
  setCurrentCampaignByIdLoading,
  setValidatorsSuccess,
  setFieldsByDataTypeSuccess,
  setCampaignFields,
  setCurrentCampaignByIdSuccess,
  setCampaignsList,
  setSimplifiedCampaigns,
  setCampaignsListCount,
  setIsCampaignCreated,
  setAffiliateChannelsByCampaignId,
  setCampaignFilters,
} = slice.actions;

const fetchSetFieldsByDataTypeSuccess = (value: ICampaignField[] | null) => async () => {
  setFieldsByDataTypeSuccess(value);
};

const fetchTimeFormatSuccess = () => async () => {
  try {
    dispatch(setTimeFormatSuccessLoading(true));

    const { data: response } = await DataService.getJson(API_GET_DATA_TIME_FORMATS());

    dispatch(setTimeFormatSuccess(response));
    dispatch(setTimeFormatSuccessLoading(response));
    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setTimeFormatSuccessLoading(false));
  }
};

const fetchCampaignHistory =
  (campaignId: string, pageNumber = 1, pageSize = 10) =>
  async () => {
    try {
      dispatch(setCampaignHistoryLoading(true));

      const { data: response } = await DataService.getData(API_GET_HISTORY_CAMPAIGNS(campaignId), {
        pageNumber,
        pageSize,
      });

      dispatch(setHistory(response));
      dispatch(hasError(null));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setCampaignHistoryLoading(false));
    }
  };

const fetchFieldByDataType = (XML: string) => async () => {
  try {
    dispatch(setFieldByDataTypeLoading(true));

    if (!XML) {
      return;
    }

    const response = await DataService.post(API_FIELDS(), {
      dataFieldType: 1,
      dataTemplate: `${XML}`,
      entityId: '0',
    });

    if (response && response.fields && response.fields?.length) {
      const formData: { [key: string]: ICampaignField[] } = {};

      response.fields.forEach((item: ICampaignField) => {
        if (formData[item.parentField]) {
          formData[item.parentField].push(item);
        } else {
          formData[item.parentField] = [item];
        }
      });
      dispatch(setFieldsByDataTypeSuccess(formData));
    }

    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setFieldByDataTypeLoading(false));
  }
};

const fetchCampaignFieldsByCampaignId =
  (id: string, isCloned: boolean = false) =>
  async () => {
    try {
      dispatch(setCampaignFieldsLoading(true));

      const { data: response } = await DataService.getJson(API_CAMPAIGN_FIELDS(id));

      if (response) {
        const formData: { [key: string]: ICampaignField[] } = {};

        response.forEach((item: ICampaignField) => {
          if (isCloned) {
            item.id = 0;
            item.campaignId = 0;
          }

          if (formData[item.parentField]) {
            formData[item.parentField].push(item);
          } else {
            formData[item.parentField] = [item];
          }
        });

        const fieldsWithoutXml = response.map((el: ICampaignField) => {
          const { validatorSettingsXml, ...field } = el;

          return field;
        });

        if (!isCloned) {
          dispatch(setCampaignFields(fieldsWithoutXml));
        }

        dispatch(setFieldsByDataTypeSuccess(formData));
      }

      dispatch(hasError(null));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setCampaignFieldsLoading(false));
    }
  };

const fetchCurrentCampaignById = (id: string) => async () => {
  try {
    dispatch(setCurrentCampaignByIdLoading(true));

    const { data: response } = await DataService.getJson(API_CAMPAIGN_BY_ID(id));

    dispatch(setCurrentCampaignByIdSuccess(response));

    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setCurrentCampaignByIdLoading(false));
  }
};

const removeAsyncFieldByDataType = (sectionName: string, templateField: string) => async () => {
  try {
    const { fieldByDataType } = store.getState().campaigns;

    const formData = fieldByDataType as { [key: string]: ICampaignField[] } | null;
    const newData =
      formData &&
      formData[sectionName]
        .map((item: ICampaignField) => (item?.templateField !== templateField ? item : null))
        ?.filter((item) => item);

    if (Array.isArray(newData)) {
      dispatch(
        setFieldsByDataTypeSuccess({
          ...formData,
          [sectionName]: [...newData],
        }),
      );
    }

    dispatch(hasError(null));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(hasError(error?.message));
    }
  }
};

const addAsyncFieldByDataType = (sectionName: string) => async () => {
  try {
    const { fieldByDataType } = store.getState().campaigns;

    const formData = fieldByDataType as { [key: string]: ICampaignField[] } | null;

    if (Array.isArray(formData)) {
      dispatch(
        setFieldsByDataTypeSuccess({
          ...formData,
          [sectionName]: [...formData[sectionName], campaignModel],
        }),
      );
    }

    dispatch(hasError(null));

    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchValidators = () => async () => {
  try {
    dispatch(setValidatorsLoading(true));

    const { data: response } = await DataService.getJson(API_GET_VALIDATORS());

    dispatch(setValidatorsSuccess(response));
    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setValidatorsLoading(false));
  }
};

const fetchCampaignsList =
  (status = -1, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean, name?: string) =>
  async () => {
    try {
      dispatch(setCampaignsListLoading(true));

      const { data: response } = await DataService.getData(API_CAMPAIGNS(), {
        status,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
        ...(name ? { name } : {}),
      });

      dispatch(
        setCampaignFilters({
          status,
          pageNumber,
          pageSize,
          sortOrderKey,
          sortOrderValue,
          name,
        }),
      );

      response.data?.forEach((item: ICampaign) => {
        item.updatedDate = dateTime(item.updatedDate);
        item.createdDate = dateTime(item.createdDate);
      });
      dispatch(setCampaignsList(response.data));
      dispatch(setCampaignsListCount(response.itemsCount));
      dispatch(hasError(null));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(setCampaignsListLoading(false));
      }, 100);
    }
  };

const fetchSimplifiedCampaigns = () => async () => {
  try {
    dispatch(setCampaignsListLoading(true));

    const { data: response } = await DataService.getData(API_SIMPLIFIED_CAMPAIGNS());

    dispatch(setSimplifiedCampaigns(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setCampaignsListLoading(false));
  }
};

const fetchDeleteCampaign =
  (
    id: string,
    status: number,
    pageNumber: number,
    pageSize: number,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setDeleteCampaignLoading(true));
      await DataService.delete(API_CAMPAIGNS(id));

      dispatch(fetchCampaignsList(status, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));
      dispatch(hasError(null));
    } catch (error: any) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description:
              'We were unable to delete this product because it has active channels. Please confirm all active channels are deleted and retry',
          },
        }),
      );
      dispatch(hasError(error));
    } finally {
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmCampaignDeleteModal));
      dispatch(setDeleteCampaignLoading(false));
    }
  };

const fetchUpdateCampaignStatus =
  (
    id: string,
    status: number,
    defaultStatus: number,
    pageNumber = 1,
    pageSize = 10,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setUpdateCampaignStatusLoading(true));
      await DataService.put(API_CAMPAIGNS(`${id}/status?status=${status}`));

      dispatch(fetchCampaignsList(defaultStatus, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));

      dispatch(hasError(null));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setUpdateCampaignStatusLoading(false));
    }
  };

const fetchUpdateCampaignDetails = (updatedCampaign: ICampaignDetails) => async () => {
  try {
    dispatch(setUpdateCampaignDetailsLoading(true));

    updatedCampaign = trimProperties(updatedCampaign, ['name']);

    await DataService.put(API_CAMPAIGN_BY_ID(updatedCampaign.id), updatedCampaign);

    dispatch(fetchCurrentCampaignById(updatedCampaign.id));
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Product successfully updated',
        },
      }),
    );
    dispatch(hasError(null));
  } catch (error) {
    responseErrorHandler(error);
  } finally {
    dispatch(setUpdateCampaignDetailsLoading(false));
  }
};

const fetchIsCampaignSuccessfully = (isSuccessfully: boolean) => async () => {
  try {
    dispatch(hasSuccessfully(isSuccessfully));
    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchIsCampaignCreated = (isCampaignCreated: boolean) => async () => {
  try {
    dispatch(setIsCampaignCreated(isCampaignCreated));
    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchCreateCampaign = (campaignData: ICampaignDetails) => async () => {
  try {
    dispatch(setCreateCampaignLoading(true));
    dispatch(setFieldsByDataTypeSuccess(null));
    dispatch(setCurrentCampaignByIdSuccess(null));

    campaignData = trimProperties(campaignData, ['name']);

    const { data: response } = await DataService.postStatus(API_CAMPAIGNS(), campaignData);

    if (response?.isSuccess) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: 'Product successfully created',
          },
        }),
      );
      dispatch(
        viewsMiddleware.setRedirectionState({
          path: `/products/${response.data.id}`,
          params: '',
          apply: true,
        }),
      );
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmCreateCampaignModal));
      dispatch(hasSuccessfully(true));
      dispatch(hasError(null));
    } else {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: 'Something went wrong.',
          },
        }),
      );
      dispatch(hasSuccessfully(false));
      dispatch(hasError(response));
    }
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasSuccessfully(false));
  } finally {
    dispatch(setCreateCampaignLoading(false));
  }
};

const fetchUpdateCampaignFields =
  (campaignFields: ICampaignField[], campaignId: string, mode: 'EDIT' | 'CREATE') => async () => {
    try {
      dispatch(setUpdateCampaignLoading(true));

      if (campaignFields.length > 0) {
        campaignFields = campaignFields.map((el) => trimProperties(el, ['description', 'templateField']));
      }

      await DataService.putStatus(API_CAMPAIGN_FIELDS(campaignId), campaignFields);

      await dispatch(fetchCurrentCampaignById(campaignId));
      dispatch(setIsCampaignCreated(true));
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.success,
            description: `Product successfully ${mode === 'EDIT' ? 'edited' : 'created'}`,
          },
        }),
      );
      dispatch(
        viewsMiddleware.setRedirectionState({
          path: '/products',
          params: '',
          apply: true,
        }),
      );
    } catch (error) {
      responseErrorHandler(error);
    } finally {
      dispatch(setUpdateCampaignLoading(false));
    }
  };

const getAffiliateChannelsByCampaignId = (id: string) => async () => {
  try {
    dispatch(setAffiliateChannelsByCampaignIdLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID(id));

    dispatch(setAffiliateChannelsByCampaignId(response));

    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setAffiliateChannelsByCampaignIdLoading(false));
  }
};

const getSystemFieldsByCampaignId = (id: string) => async () => {
  try {
    dispatch(setSystemFieldsByCampaignIdLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID(id));

    dispatch(setAffiliateChannelsByCampaignId(response));

    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setSystemFieldsByCampaignIdLoading(false));
  }
};

const fetchEmptyCampaignFields = () => async () => {
  try {
    dispatch(setCampaignFields(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default {
  fetchSetFieldsByDataTypeSuccess,
  fetchTimeFormatSuccess,
  fetchCampaignHistory,
  fetchFieldByDataType,
  fetchCampaignFieldsByCampaignId,
  fetchCurrentCampaignById,
  removeAsyncFieldByDataType,
  addAsyncFieldByDataType,
  fetchValidators,
  fetchCampaignsList,
  fetchSimplifiedCampaigns,
  fetchDeleteCampaign,
  fetchUpdateCampaignStatus,
  fetchUpdateCampaignDetails,
  fetchIsCampaignSuccessfully,
  fetchIsCampaignCreated,
  fetchCreateCampaign,
  fetchUpdateCampaignFields,
  getAffiliateChannelsByCampaignId,
  getSystemFieldsByCampaignId,
  fetchEmptyCampaignFields,
};
