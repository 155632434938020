// @ts-nocheck
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconPlus } from '@tabler/icons';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import { ActionItemProps } from 'components/UI/Action';
import Loading from 'components/UI/Loading';
import MainCard from 'components/UI/MainCard';
import { gridSpacing } from 'lib/constants/grid';

import {
  IValidationTreeFormValues,
  IValidationTreeModel,
  validationTreeFilterModel,
  ValidationTreeModalType,
  validationTreeModel,
} from '../../../components/dataModels/validationTree';
import { CustomButton, CustomPlusButton } from '../../../components/shared';
import NestedSelectManualReview from '../../../components/shared/NestedSelect/NestedSelectManualReview';
import CustomSelect from '../../../components/shared/Select/CustomSelect';
import { StyledLink } from '../../../components/shared/StyledComponents';
import { dispatch, useAppSelector } from '../../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../../../redux/slices/campaigns';
// import { externalServicesMiddleware, externalServicesSelector } from '../../../redux/slices/externalServices'; @deprecated
import { listsSelector } from '../../../redux/slices/lists';
// import { listsMiddleware, listsSelector } from '../../../redux/slices/lists';  @deprecated
import {
  validationTreeMiddleware,
  validationTreeSelector,
  validationTreeSlice,
} from '../../../redux/slices/validationTree';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { ValidationTreeActionType } from '../../../types';
import { SeveritiesType } from '../../../types/snackbar';

import FilterSubItem from './FilterSubItem';
import ValidationTreeMoreActionModal from './ValidationTreeMoreActionMenu';

import classes from './validationTree.module.scss';

const esprima = require('esprima');

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 314,
      left: 79,
    },
  },
};

const filterTypes = [
  // { Id: 4, Name: '3rd party APIs' }, @deprecated
  // { Id: 2, Name: 'List Integration' },
  { Id: 1, Name: 'Product Fields' },
  { Id: 7, Name: 'Expression' },
  // { Id: 6, Name: 'Experian' } @deprecated
];

const getMaxHeight = (MODE: string) => {
  let maxHeight;

  if (MODE === 'CREATE') {
    maxHeight = 'calc(100vh - 350px)';
  } else {
    maxHeight = 'calc(100vh - 297px)';
  }

  return maxHeight;
};

const StyledTextareaAutosize = styled(TextareaAutosize)({
  padding: '5px 10px',
  border: 'none',
  borderRadius: '8px',
  backgroundColor: '#FAFAFA',
  maxHeight: '300px',
  overflow: 'auto',
  resize: 'vertical',
  minHeight: '30px',
});

const ValidationTreeCreateOrEdit: FC<{
  defaultValues?: IValidationTreeFormValues;
  MODE: 'CREATE' | 'EDIT';
}> = ({ defaultValues, MODE }) => {
  const [isValidationTree, setIsValidationTree] = useState<boolean>(false);
  const actionMenuItems = (Object.values(ValidationTreeModalType) as Array<keyof typeof ValidationTreeModalType>).map(
    (value) => value,
  );

  const navigate = useNavigate();
  // TODO implement daily cap
  const [validationTreeName, setValidationTreeName] = useState<string>('');
  const [conditionsAlreadyIn, setConditionsAlreadyIn] = useState<ValidationTreeActionType[]>([]);
  const [waiting, setWaiting] = useState(true);
  // const [externalServices, setExternalServices] = useState([]); @deprecated
  // Go To logic
  // const [showingTreesList, setShowingTreesList] = useState<any>([]);
  const [externalServiceResponseFields, setExternalServiceResponseFields] = useState<
    { id: string; index: number | string; value: [] }[]
  >([]);
  const [campaignFields, setCampaignFields] = useState<{ id: string; name: string }[] | []>([]);
  // const [rowIndex, setRowIndex] = useState<number | string>(0); @deprecated

  const channel = useAppSelector(affiliatesSelector.channel);
  // const externalServiceById = useAppSelector(affiliatesSelector.externalServiceById); @deprecated
  const validationTreeUpdateLoading = useAppSelector(validationTreeSelector.validationTreeUpdateLoading);
  const validationTree = useAppSelector(validationTreeSelector.validationTree);
  const validationTreeActions = useAppSelector(validationTreeSelector.validationTreeActions);
  const validationTreeSuccessfully = useAppSelector(validationTreeSelector.validationTreeSuccessfully);
  const validationTreeTypes = useAppSelector(validationTreeSelector.validationTreeTypes);
  const validationTreeTypesLoading = useAppSelector(validationTreeSelector.validationTreeTypesLoading);

  const conditions = useAppSelector(validationTreeSelector.conditions);

  const listConditions = useAppSelector(validationTreeSelector.listConditions);
  const [creditUnion, setCreditUnion] = useState<number>(validationTree.creditUnion);
  const [type, setType] = useState(validationTree.channelTreeType ?? 1);
  const [validationTreeNameError, setValidationTreeNameError] = useState(false);

  // Go To logic
  // const validationTreesById = useAppSelector(validationTreeSelector.validationTreesById);
  const singleValidationTree = useAppSelector(validationTreeSelector.singleValidationTree);
  const manualReviewReasonsList = useAppSelector(validationTreeSelector.manualReviewReasonsList);
  const isValidationTreeLoading = useAppSelector(validationTreeSelector.validationTreeLoading);
  // const experianFields = useAppSelector(validationTreeSelector.experianFields);
  const currentCampaignFields = useAppSelector(campaignsSelector.campaignFields);
  // const simplifiedExternalServices = useAppSelector(externalServicesSelector.simplifiedExternalServices);
  const lists = useAppSelector(listsSelector.lists);
  const creditUnions = useAppSelector(affiliatesSelector.creditUnions);
  const creditUnionsLoading = useAppSelector(affiliatesSelector.creditUnionsLoading);

  const { id: channelId } = useParams();

  const methods = useForm<IValidationTreeFormValues>({
    defaultValues: {},
    mode: 'onSubmit',
  });

  const { register, control, handleSubmit, setValue, watch } = methods;

  const TreeName = validationTree.name ? `${validationTree.name}` : `${defaultValues?.TreeName}`;

  const mockFieldData = [
    {
      name: 'No Data',
      id: 0,
    },
  ];

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: `DecisionTree`,
  });

  const getFieldName = (FilterType: string) => {
    switch (FilterType) {
      case ValidationTreeActionType.ExternalServices:
        return 'ResponseFieldId';
      case ValidationTreeActionType.ListIntegration:
        return 'BWListId';
      case ValidationTreeActionType.CampaignFields:
        return 'CampaignFieldId';
      case ValidationTreeActionType.Experian:
        return 'ResponseField';
      default:
        return 'Field';
    }
  };

  const validJavaScript = (value) => {
    try {
      esprima.parseScript(value);

      return false;
    } catch {
      return true;
    }
  };

  const createSendingData = (values: IValidationTreeFormValues) => {
    let errorMessage: string | null = null;
    const data = values.DecisionTree?.map((item: IValidationTreeModel, index: number) => {
      if (item.Expression) {
        const expressionValue = item.Expression.trim();

        if (validJavaScript(expressionValue)) {
          errorMessage = 'Invalid JavaScript Syntax';

          return null;
        }

        if (expressionValue.length > 1500) {
          errorMessage = 'Your input is too long. It should not exceed 1500 characters.';

          return null;
        }

        item.Expression = expressionValue;
      }

      return {
        id: '0',
        parentId: '',
        affiliateChannelId: channel?.id,
        actionType: item.FilterType,
        affiliateChannelTreeId: validationTree?.id
          ? validationTree.id
          : validationTreeActions?.[0]?.affiliateChannelTreeId,
        orderNum: index,
        logicalOperator: 2,
        description: item.description,
        aanDescription: item.aanDescription,
        scheduleItems: [
          {
            dayOfWeek: [],
            fromTime: '00:00',
            toTime: '00:00',
          },
        ],
        trees: item.trees ? item.trees : [],
        actionSettings: [
          ...(item.FilterType !== ValidationTreeActionType.Expression
            ? [
                {
                  Name: 'Condition',
                  Value: item.Condition,
                },
                {
                  Name: getFieldName(item.FilterType),
                  Value: item.Field,
                },
                ...(item.Value
                  ? [
                      {
                        Name: 'Values',
                        Value: item.Value,
                      },
                    ]
                  : []),
                ...(item.CampaignField
                  ? [
                      {
                        Name: 'CampaignFieldId',
                        Value: item.CampaignField,
                      },
                    ]
                  : []),
              ]
            : []),
          {
            Name: 'ActionResult',
            Value: item.Action,
          },
          ...(item.Name
            ? [
                {
                  Name: 'ExternalServiceId',
                  Value: item.Name,
                },
              ]
            : []),
          ...(item.Reason
            ? [
                {
                  Name: 'Reason',
                  Value: item.Reason,
                },
              ]
            : []),
          ...(item.FilterType === ValidationTreeActionType.Expression && item.Expression
            ? [
                {
                  Name: 'Expression',
                  Value: item.Expression,
                },
              ]
            : []),
          ...(item.aanDescription
            ? [
                {
                  Name: 'aanDescription',
                  Value: item.aanDescription,
                },
              ]
            : []),
          ...(item.description
            ? [
                {
                  Name: 'description',
                  Value: item.description,
                },
              ]
            : []),
        ],
        children: item.filters?.map((child, childIndex) => ({
          Id: '',
          parentId: index,
          orderNum: childIndex,
          affiliateChannelId: channel?.id,
          actionType: item.FilterType,
          affiliateChannelTreeId: validationTree?.id
            ? validationTree.id
            : validationTreeActions?.[0].affiliateChannelTreeId,
          logicalOperator: 2,
          scheduleItems: [
            {
              dayOfWeek: [],
              fromTime: '00:00',
              toTime: '00:00',
            },
          ],
          children: [],
          actionSettings: [
            ...(item.FilterType !== ValidationTreeActionType.Expression
              ? [
                  {
                    Name: 'Condition',
                    Value: child.Condition,
                  },
                  {
                    Name: getFieldName(item.FilterType),
                    Value: child.Field,
                  },
                  ...(child.CampaignField
                    ? [
                        {
                          Name: 'CampaignFieldId',
                          Value: child.CampaignField,
                        },
                      ]
                    : []),
                ]
              : []),
            ...(child.Name
              ? [
                  {
                    Name: 'ExternalServiceId',
                    Value: child.Name,
                  },
                ]
              : []),
            ...(child.Value
              ? [
                  {
                    Name: 'Values',
                    Value: child.Value,
                  },
                ]
              : []),
            ...(item.FilterType === ValidationTreeActionType.Expression && child.Expression
              ? [
                  {
                    Name: 'Expression',
                    Value: child.Expression,
                  },
                ]
              : []),
          ],
        })),
      };
    });

    return { errorMessage, data };
  };

  const onSubmit = (values: IValidationTreeFormValues) => {
    const { errorMessage, data } = createSendingData(values);

    if (errorMessage) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: errorMessage,
          },
        }),
      );
    } else if (
      validationTree.name !== validationTreeName ||
      validationTree.creditUnion !== creditUnion ||
      validationTree.channelTreeType !== type
    ) {
      if (validationTreeName.length <= 50) {
        dispatch(
          validationTreeMiddleware.fetchUpdateValidationTree(
            { ...validationTree, name: validationTreeName.trim(), creditUnion },
            data,
            MODE,
            channel?.id,
            type,
          ),
        );
        setValidationTreeNameError(false);
      } else {
        setValidationTreeNameError(true);
      }
    } else {
      dispatch(validationTreeMiddleware.fetchCreateAffiliateChannelActions(validationTree.id, MODE, data, channel?.id));
    }
  };

  const getInputLabel = (index: number) => {
    switch (watch(`DecisionTree.[${index}].FilterType`)) {
      case ValidationTreeActionType.ExternalServices:
        return 'Response Fields';
      case ValidationTreeActionType.ListIntegration:
        return 'Lists';
      case ValidationTreeActionType.CampaignFields:
        return 'Product Fields';
      case ValidationTreeActionType.Experian:
        return 'Response Fields';
      default:
        return 'Fields';
    }
  };

  const getFilterTypeData = (index: number, id: string) => {
    switch (watch(`DecisionTree.[${index}].FilterType`)) {
      case ValidationTreeActionType.ExternalServices:
        return externalServiceResponseFields?.find((item) => item.id === id)?.value.length
          ? externalServiceResponseFields?.find((item) => item.id === id)?.value
          : mockFieldData;
      case ValidationTreeActionType.ListIntegration:
        return lists.length ? lists : mockFieldData;
      case ValidationTreeActionType.CampaignFields:
        return campaignFields.length ? campaignFields : mockFieldData;
      default:
        return mockFieldData;
    }
  };

  const onFilterClick = (item: { id: number | string }, index: number) => {
    setValue(`DecisionTree.${index}.filters`, [...watch(`DecisionTree.${index}.filters`), validationTreeFilterModel]);
  };

  // Go To logic
  // const onActionsSave = (data: number[], value: number, index: number) => {
  //   setValue(`DecisionTree.${index}.Action`, value);
  //   setValue(`DecisionTree.${index}.trees`, data);
  // };

  const onActionsSave = (data, value, index) => {
    setValue(`DecisionTree.${index}.Action`, value);
    setValue(`DecisionTree.${index}.Reason`, data);
  };

  const handleModalSelect = (modalType, index) => {
    if (modalType) {
      switch (modalType) {
        case ValidationTreeModalType.Schedule:
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.ScheduleModal,
              props: {
                index,
              },
            }),
          );
          break;
        case ValidationTreeModalType.Cap:
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.CapModal,
              props: {
                index,
              },
            }),
          );
          break;
        case ValidationTreeModalType.Pause:
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.PauseModal,
              props: {
                index,
              },
            }),
          );
          break;
        case ValidationTreeModalType.Aan:
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.AanModal,
              props: {
                aan: fields[index].aanDescription,
                setAan: (aan: string) => {
                  setValue(`DecisionTree.${index}.aanDescription`, aan);
                },
              },
            }),
          );
          break;
        case ValidationTreeModalType.Description:
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.DescriptionModal,
              props: {
                description: watch(`DecisionTree.${index}.description`),
                setDescription: (description: string) => {
                  setValue(`DecisionTree.${index}.description`, description);
                },
              },
            }),
          );
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    setValidationTreeName(validationTree.name);
  }, [validationTree]);

  useEffect(() => {
    if (!channel) {
      if (MODE === 'CREATE') {
        navigate(`/sources-channel`);
      } else {
        navigate(`/sources-channel/${channelId}`);
      }
    }

    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, [MODE]);

  useEffect(() => {
    if (validationTreeSuccessfully) {
      if (MODE === 'CREATE') {
        navigate(`/sources-channel/${channel ? channel.id : channelId}`);
      }

      dispatch(validationTreeMiddleware.fetchSetValidationTreeSuccessfully(false));
    }
  }, [validationTreeSuccessfully]);

  useEffect(() => {
    if (fields?.length) {
      setWaiting(false);
    }
  }, [fields]);

  useEffect(() => {
    if (!waiting && MODE === 'EDIT') {
      fields.forEach((el: IValidationTreeModel, index) => {
        if (!conditionsAlreadyIn.includes(el.FilterType)) {
          setConditionsAlreadyIn((prev) => [...prev, el.FilterType]);
        }

        if (el.FilterType === ValidationTreeActionType.ExternalServices) {
          (async () => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            await onExternalServiceSelect(
              {
                id: el.Name ?? 0,
                name: 'name',
              },
              index,
            );
            el.filters?.forEach((child, childIndex) => {
              (async () => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                await onExternalServiceSelect(
                  {
                    id: child.Name ?? 0,
                    name: 'name',
                  },
                  `${index}-${childIndex}`,
                );
              })();
            });
          })();
        }
      });
    }
  }, [waiting]);

  // useEffect(() => { @deprecated
  //   setExternalServices(
  //     simplifiedExternalServices?.map((item: { id: string; name: string }) => ({
  //       name: item.label,
  //       id: item.id
  //     }))
  //   );
  // }, [simplifiedExternalServices]);

  useEffect(() => {
    if (MODE === 'EDIT') {
      fields.forEach((el) => {
        if (!conditionsAlreadyIn.includes(el.FilterType)) {
          setConditionsAlreadyIn((prev) => [...prev, el.FilterType]);
        }
      });
    }
  }, [fields]);

  // @deprecated
  // const onExternalServiceSelect = async (value: { id: string | string; name: string }, index: number | string) => {
  //   setRowIndex(index);
  //   await dispatch(affiliatesMiddleware.fetchGetExternalServiceById(value?.id));
  // };

  const getConditionsFields = (conditionType?: number) => {
    if (conditionType === 2) {
      return listConditions;
    }

    return conditions;
  };

  const addFilterGroup = () => {
    append({
      ...validationTreeModel,
      Field: `${campaignFields[0]?.id}`,
      Action: 1,
    });
  };

  // useEffect(() => { @deprecated
  //   const newFields = {
  //     id: externalServiceById?.id,
  //     index: rowIndex,
  //     value: externalServiceById?.responseFields?.map((item: { templateField: string; id: string }) => ({
  //       id: item.id,
  //       name: item.templateField
  //     }))
  //   };

  //   setExternalServiceResponseFields((prev) => [...prev, newFields]);
  // }, [externalServiceById]);

  useEffect(() => {
    if (`${TreeName}`?.length && MODE === 'EDIT' && singleValidationTree?.length) {
      setValue('DecisionTree', singleValidationTree);
    }

    // Go To logic
    // if (singleValidationTree && channel && validationTreesById) {
    //   setShowingTreesList(
    //     createShowingValidationTreeList({
    //       validationTreesById: validationTreesById.data,
    //       singleValidationTree,
    //       validationTree
    //     })
    //   );
    // }
  }, [singleValidationTree]);

  useEffect(() => {
    if (defaultValues?.TreeName) {
      register(`${defaultValues?.TreeName}`);
      defaultValues.DecisionTree?.forEach((item, index) => {
        update(index, item);
      });
    }
  }, [`${TreeName}`]);

  useEffect(() => {
    if (currentCampaignFields?.length) {
      setCampaignFields(
        currentCampaignFields.map((item: { templateField: string; id: string }) => ({
          name: item.templateField,
          id: item.id,
        })),
      );
    }
  }, [currentCampaignFields]);

  useEffect(() => {
    setIsValidationTree(true);

    if (channel) {
      dispatch(campaignsMiddleware.fetchCampaignFieldsByCampaignId(channel?.campaignId));
    }

    dispatch(affiliatesMiddleware.fetchCreditUnions());
    // dispatch(validationTreeMiddleware.fetchExperianFields()); @deprecated
    dispatch(validationTreeMiddleware.fetchManualReviewReasonsList());
    dispatch(validationTreeMiddleware.fetchConditions());
    dispatch(validationTreeMiddleware.fetchListConditions('?validator=5'));
    dispatch(campaignsMiddleware.fetchValidators());
    dispatch(validationTreeMiddleware.getValidationTreeTypes());
    // dispatch(listsMiddleware.fetchGetLists(0)); @deprecated
    // dispatch(externalServicesMiddleware.fetchSimplifiedExternalServices()); @deprecated
    setIsValidationTree(false);
    setExternalServiceResponseFields([]);

    return () => {
      dispatch(validationTreeSlice.actions.setSingleValidationTree([]));
      setValidationTreeNameError(false);
    };
  }, []);

  if (isValidationTree) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 115px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {MODE === 'CREATE' ? (
        <Breadcrumbs
          sx={{
            color: '#707EAE',
            marginY: '10px',
          }}
        >
          <StyledLink to="/sources-channel">Source channel</StyledLink>
          <StyledLink to="/sources-channel/decision-rules">Decision Rules</StyledLink>
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            Create Decision Rules
          </Typography>
        </Breadcrumbs>
      ) : null}
      {(MODE === 'EDIT' && isValidationTree) || isValidationTreeLoading ? (
        <Loading />
      ) : (
        <MainCard
          sx={{ width: '100%', height: '100%', flex: 1 }}
          border={null}
          spacing={gridSpacing}
          contentSX={{ height: '100%' }}
        >
          <Box onSubmit={handleSubmit(onSubmit)} className={classes.root} sx={{ height: '100%' }} component="form">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box sx={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid sx={{ mt: 2, width: '100%', display: 'flex', gap: '20px' }}>
                    <Box width="300px">
                      <FormControl fullWidth>
                        <InputLabel>Tree Name:</InputLabel>
                        <TextField
                          sx={{ marginTop: '5px' }}
                          data-test={`decision-tree-${
                            MODE === 'EDIT' ? 'details' : 'create'
                          }-page-input-decision-tree-name`}
                          onChange={(e) => {
                            setValidationTreeName(e.target.value.trim());
                          }}
                          required
                          defaultValue={validationTree.name}
                          disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                        />
                        <FormHelperText error>
                          {validationTreeNameError ? 'The number of characters should not exceed 50.' : ''}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {!validationTreeTypesLoading ? (
                      <CustomSelect
                        width="300px"
                        valueKey="id"
                        nameKey="name"
                        defaultValue={type}
                        dataTest={`decision-tree-${MODE === 'EDIT' ? 'details' : 'create'}-page-select-decision-type`}
                        options={validationTreeTypes ?? []}
                        label="Type"
                        permission={Permissions.changeDecisionTreeFilter}
                        labelSx={{ color: '#a0aec0', fontSize: '13px', marginBottom: '10px' }}
                        onSelect={(value) => {
                          if (value?.id) {
                            setType(+value.id);
                          }
                        }}
                      />
                    ) : (
                      <Box display="flex" alignItems="end" marginLeft="20px">
                        <CircularProgress />
                      </Box>
                    )}
                    {type === 2 && !!creditUnions.length && (
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel>Credit Union</InputLabel>
                          <Select
                            data-test="confirm-affiliate-channel-tree-name-modal-dropdown-decision-tree-credit-union"
                            sx={{ mt: '5px' }}
                            id="credit-unions-dropdown"
                            defaultValue={validationTree.creditUnion}
                            fullWidth
                            onChange={(e) => setCreditUnion(+e.target.value)}
                            disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                          >
                            {!creditUnionsLoading ? (
                              creditUnions?.map((cUnion: ICreditUnion) => (
                                <MenuItem key={cUnion?.id} value={cUnion?.id}>
                                  {cUnion?.name}
                                </MenuItem>
                              ))
                            ) : (
                              <Loading />
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Grid>
                </Box>
                {!`${TreeName}` && MODE === 'CREATE' ? (
                  <Box />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      overflow: 'auto',
                      maxHeight: getMaxHeight(MODE),
                    }}
                  >
                    <Box className={classes.content}>
                      {fields && fields?.length
                        ? fields?.map((item, index) => (
                            <Fragment key={item.id}>
                              <Box
                                data-test={`decision-tree-${
                                  MODE === 'EDIT' ? 'details' : 'create'
                                }-page-table-id-${index}`}
                                className={
                                  watch(`DecisionTree.[${index}].FilterType`) === ValidationTreeActionType.Expression
                                    ? classes.expression
                                    : classes.children
                                }
                                key={`${item.id}`}
                              >
                                <FormControl className={classes.firstItem}>
                                  <Typography className={classes.groupCount}>
                                    {index + 1}
                                    <DragIndicatorIcon sx={{ color: '#A3AED0' }} />
                                  </Typography>
                                  <Box className={classes.inputLabel}>
                                    <InputLabel>FilterType</InputLabel>
                                    <Select
                                      data-test={`decision-tree-${
                                        MODE === 'EDIT' ? 'details' : 'create'
                                      }-page-table-id-${index}-filter-type`}
                                      {...register(`DecisionTree.${index}.FilterType`, {
                                        onChange: (event) => {
                                          if (event.target.value === ValidationTreeActionType.ListIntegration) {
                                            setValue(`DecisionTree.${index}.Field`, lists[0].id);
                                            setValue(`DecisionTree.${index}.CampaignField`, `${campaignFields[0].id}`);
                                          } else if (event.target.value === ValidationTreeActionType.CampaignFields) {
                                            setValue(`DecisionTree.${index}.Field`, `${campaignFields[0].id}`);
                                          }
                                        },
                                      })}
                                      placeholder="type"
                                      value={watch(`DecisionTree.${index}.FilterType`)}
                                      MenuProps={MenuProps}
                                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                    >
                                      {filterTypes.map((option) => (
                                        <MenuItem
                                          key={option.Id}
                                          value={option.Id}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            update(index, {
                                              ...fields[index],
                                              // eslint-disable-next-line no-unsafe-optional-chaining
                                              FilterType: +option?.Id,
                                            });
                                          }}
                                        >
                                          {option.Name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Box>
                                </FormControl>
                                <FormControl
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    gap: '4px',
                                  }}
                                  className={classes.item}
                                >
                                  <>
                                    {/* {watch(`DecisionTree[${index}].FilterType`) === @deprecated
                                    ValidationTreeActionType.ExternalServices ? (
                                      <Box className={classes.inputLabel} sx={{ maxWidth: '50%' }}>
                                        <InputLabel>Service Name</InputLabel>
                                        <Select
                                          defaultValue={watch(`DecisionTree[${index}].Name`)}
                                          {...register(`DecisionTree[${index}].Name`, {})}
                                          MenuProps={MenuProps}
                                        >
                                          {externalServices?.map((option: { id: string; name: string }) => (
                                            <MenuItem
                                              key={option.id}
                                              onClick={() => {
                                                onExternalServiceSelect(option, index);
                                              }}
                                              value={option.id}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Box>
                                    ) : null} */}
                                    {watch(`DecisionTree.[${index}].FilterType`) ===
                                    ValidationTreeActionType.Expression ? (
                                      <FormControl
                                        fullWidth
                                        className={classes.item}
                                        data-test={`decision-tree-${
                                          MODE === 'EDIT' ? 'details' : 'create'
                                        }-page-table-id-${index}-expression-value`}
                                      >
                                        <InputLabel>Value</InputLabel>
                                        <StyledTextareaAutosize
                                          defaultValue={watch(`DecisionTree.${index}.Expression`)}
                                          placeholder="Enter a value"
                                          {...register(`DecisionTree.${index}.Expression`, {})}
                                          disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                        />
                                      </FormControl>
                                    ) : (
                                      <Box
                                        sx={{
                                          width:
                                            watch(`DecisionTree.[${index}].FilterType`) ===
                                            ValidationTreeActionType.ExternalServices
                                              ? '50%'
                                              : '100%',
                                        }}
                                      >
                                        <InputLabel>{getInputLabel(index)}</InputLabel>
                                        <Select
                                          data-test={`decision-tree-${
                                            MODE === 'EDIT' ? 'details' : 'create'
                                          }-page-table-id-${index}-${getInputLabel(index)}`}
                                          defaultValue={watch(`DecisionTree[${index}].Field`)}
                                          {...register(`DecisionTree.${index}.Field`, {})}
                                          MenuProps={MenuProps}
                                          disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                        >
                                          {getFilterTypeData(index, watch(`DecisionTree[${index}].Name`))?.map(
                                            // eslint-disable-next-line @typescript-eslint/no-shadow
                                            (item: { id: string; name: string }) => (
                                              <MenuItem value={item.id} disabled={item.id === 0} key={item.id}>
                                                {item.name}
                                              </MenuItem>
                                            ),
                                          )}
                                        </Select>
                                      </Box>
                                    )}
                                  </>
                                </FormControl>
                                {watch(`DecisionTree[${index}].FilterType`) ===
                                ValidationTreeActionType.ListIntegration ? (
                                  <FormControl className={classes.item}>
                                    <InputLabel>Product Fields</InputLabel>
                                    <Select
                                      data-test={`decision-tree-${
                                        MODE === 'EDIT' ? 'details' : 'create'
                                      }-page-table-id-${index}-product-fields`}
                                      defaultValue={watch(`DecisionTree[${index}].CampaignField`)}
                                      {...register(`DecisionTree[${index}].CampaignField`, {})}
                                      MenuProps={MenuProps}
                                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                    >
                                      {(campaignFields.length ? campaignFields : mockFieldData)?.map(
                                        (option: { id: string; name: string }) => (
                                          <MenuItem value={option.id} key={option.id}>
                                            {option.name}
                                          </MenuItem>
                                        ),
                                      )}
                                    </Select>
                                  </FormControl>
                                ) : null}
                                {watch(`DecisionTree.[${index}].FilterType`) !==
                                  ValidationTreeActionType.Expression && (
                                  <FormControl className={classes.item}>
                                    <InputLabel>Condition</InputLabel>
                                    <Select
                                      data-test={`decision-tree-${
                                        MODE === 'EDIT' ? 'details' : 'create'
                                      }-page-table-id-${index}-condition`}
                                      defaultValue={watch(`DecisionTree[${index}].Condition`)}
                                      placeholder="Conditions"
                                      sx={{ whiteSpace: 'nowrap' }}
                                      {...register(`DecisionTree.${index}.Condition`, {})}
                                      MenuProps={MenuProps}
                                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                    >
                                      {getConditionsFields(watch(`DecisionTree[${index}].FilterType`))?.map(
                                        (condition: { id: string; name: string }) => (
                                          <MenuItem key={condition.id} value={condition.id}>
                                            {condition.name}
                                          </MenuItem>
                                        ),
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                                {watch(`DecisionTree[${index}].FilterType`) !==
                                  ValidationTreeActionType.ListIntegration &&
                                watch(`DecisionTree.[${index}].FilterType`) !== ValidationTreeActionType.Expression ? (
                                  <FormControl className={classes.item}>
                                    <InputLabel>Value</InputLabel>
                                    <TextField
                                      data-test={`decision-tree-${
                                        MODE === 'EDIT' ? 'details' : 'create'
                                      }-page-table-id-${index}-product-fields-value`}
                                      className={classes.item}
                                      defaultValue={watch(`DecisionTree.${index}.Value`)}
                                      placeholder="Enter a value"
                                      {...register(`DecisionTree.${index}.Value`, {})}
                                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                    />
                                  </FormControl>
                                ) : null}
                                <FormControl
                                  className={classes.item}
                                  sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '2fr 1fr 0.25fr 0.25fr',
                                    flexWrap: 'nowrap',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <FormControl className={classes.item}>
                                    <InputLabel>Action</InputLabel>
                                    {/* <NestedSelect */}
                                    {/*  data={[ */}
                                    {/*    { label: 'Accept', value: 1 }, */}
                                    {/*    { label: 'Reject', value: 2 } */}
                                    {/*  ]} */}
                                    {/*  fieldId={singleValidationTree?.[index]?.id} */}
                                    {/*  nestedId={3} */}
                                    {/*  setNestedData={setShowingTreesList} */}
                                    {/*  nestedData={showingTreesList} */}
                                    {/*  selectedValues={item.trees} */}
                                    {/*  defaultValue={item.Action} */}
                                    {/*  onSave={onActionsSave} */}
                                    {/*  index={index} */}
                                    {/* /> */}
                                    <NestedSelectManualReview
                                      data={[
                                        { label: 'Accept', value: 1 },
                                        { label: 'Reject', value: 2 },
                                        { label: 'Manual Review', value: 4 },
                                      ]}
                                      dataTest={`decision-tree-${
                                        MODE === 'EDIT' ? 'details' : 'create'
                                      }-page-table-id-${index}-action`}
                                      nestedId={4}
                                      nestedData={manualReviewReasonsList}
                                      index={index}
                                      defaultValue={watch(`DecisionTree.[${index}].Action`)}
                                      defaultNestedValue={item.Reason}
                                      onSelect={onActionsSave}
                                      disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
                                    />
                                  </FormControl>
                                  <Permission permission={Permissions.changeDecisionTreeFilter}>
                                    <Box
                                      sx={{
                                        borderRight: '1px solid #E2E8F0',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        borderRadius: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <IconButton
                                        data-test={`decision-tree-${
                                          MODE === 'EDIT' ? 'details' : 'create'
                                        }-page-table-id-${index}-button-add-sub-filter`}
                                        sx={{
                                          color: 'gray',
                                          fontSize: '16px',
                                        }}
                                        disabled={!watch(`DecisionTree.${index}.FilterType`)}
                                        onClick={() => onFilterClick(item, index)}
                                      >
                                        <IconPlus />
                                        Filter
                                      </IconButton>
                                    </Box>
                                  </Permission>
                                  <Permission permission={Permissions.changeDecisionTreeFilter}>
                                    <Box
                                      sx={{
                                        borderRight: '1px solid #E2E8F0',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        borderRadius: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => remove(index)}
                                        data-test={`decision-tree-${
                                          MODE === 'EDIT' ? 'details' : 'create'
                                        }-page-table-id-${index}-button-remove-row`}
                                      >
                                        <DeleteOutlineOutlinedIcon
                                          width="24px"
                                          height="24px"
                                          sx={{ color: '#95A1B1' }}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Permission>
                                  <Box
                                    sx={{
                                      borderRight: '1px solid #E2E8F0',
                                      whiteSpace: 'nowrap',
                                      width: '100%',
                                      borderRadius: 0,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box>
                                      <ValidationTreeMoreActionModal
                                        dataTest={`decision-tree-${
                                          MODE === 'EDIT' ? 'details' : 'create'
                                        }-page-table-id-${index}-button-more`}
                                        items={actionMenuItems as ActionItemProps[]}
                                        setSelectedItem={(name: string) => handleModalSelect(name, index)}
                                      />
                                    </Box>
                                  </Box>
                                </FormControl>
                              </Box>
                              <FilterSubItem
                                dataTest={`decision-tree-${
                                  MODE === 'EDIT' ? 'details' : 'create'
                                }-page-table-id-${index}-sub-filters`}
                                campaignFields={campaignFields}
                                setValue={setValue}
                                externalServiceResponseFields={externalServiceResponseFields}
                                rootId={item.id}
                                // externalServices={externalServices} @deprecated
                                serviceName={ValidationTreeActionType[watch(`DecisionTree[${index}].FilterType`)]}
                                rootName="DecisionTree"
                                conditions={conditions ?? []}
                                listConditions={listConditions ?? []}
                                // onExternalServiceSelect={onExternalServiceSelect} @deprecated
                                register={register}
                                // experianFields={experianFields} @deprecated
                                watch={watch}
                                control={control}
                                lists={lists}
                                index={index}
                                mockFieldData={mockFieldData}
                              />
                            </Fragment>
                          ))
                        : null}
                    </Box>
                    <Permission permission={Permissions.changeDecisionTreeFilter}>
                      <Box sx={{ width: '11%' }}>
                        <CustomPlusButton
                          sx={{
                            margin: 0,
                            padding: 0,
                            marginTop: '16px',
                            display: 'flex',
                            justifyContent: 'stretch',
                          }}
                          data-test={`decision-tree-${
                            MODE === 'EDIT' ? 'details' : 'create'
                          }-page-button-add-filter-group`}
                          title="Filter Group"
                          onClick={addFilterGroup}
                        />
                      </Box>
                    </Permission>
                  </Box>
                )}
              </Box>
              <Permission permission={Permissions.changeDecisionTreeFilter}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto',
                    marginTop: '24px',
                  }}
                >
                  <CustomButton
                    data-test={`decision-tree-${MODE === 'EDIT' ? 'details' : 'create'}-page-button-cancel`}
                    variant="outlined"
                    onClick={() =>
                      navigate(`/sources-channel/${channel.id}`, {
                        replace: true,
                      })
                    }
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    data-test={`decision-tree-${MODE === 'EDIT' ? 'details' : 'create'}-page-button-${
                      MODE === 'CREATE' ? 'Create' : 'Save'
                    }`}
                    disabled={validationTreeUpdateLoading}
                    loading={validationTreeUpdateLoading}
                    type="submit"
                  >
                    {MODE === 'CREATE' ? 'Create' : 'Save'}
                  </CustomButton>
                </Box>
              </Permission>
            </Box>
          </Box>
        </MainCard>
      )}
    </Box>
  );
};

export default ValidationTreeCreateOrEdit;
