import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, FormHelperText, Grid } from '@mui/material';
import TitleWithBreadcrumbs from 'components/shared/TitleWithBreadcrumbs';
import BaseTabs from 'components/UI/Tabs';
import { dispatch } from 'redux/hooks';
import { affiliatesSelector } from 'redux/slices/affiliates';
import { buttonsWrapper } from 'styles/styles';

import { CustomButton } from '../../../components';
import AffiliatesAffiliateChannels from '../../../components/AffiliatesAffiliateChannels';
import { Permissions } from '../../../components/Permissions/constants';
import Permission from '../../../components/Permissions/Permission';
import FormSelect, { IControlledSelectOptions } from '../../../components/shared/Select/FormSelect';
import CustomTextField from '../../../components/shared/TextField/CustomTextField';
import FormTextField from '../../../components/shared/TextField/FormTextField';
import Loading from '../../../components/UI/Loading';
import { useAppSelector } from '../../../redux/hooks';
import { affiliatesMiddleware } from '../../../redux/slices/affiliates';
import { countriesMiddleware, countriesSelector } from '../../../redux/slices/countries';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { ICountriesProps } from '../../../types';
import { createOrEditSource } from '../../../validation/affiliate/createOrEditSource';

export interface FormValues {
  id: string;
  name: string;
  countryId: string;
  city: string;
  managers: string;
  webhookToken: string;
  registrationDate: string;
  statusWebhookUrl: string;
  offersWebhookUrl: string;
  stateProvinceId: string;
  stipulationsWebhookUrl: string;
  secretKey: string;
  status: number | { [key: string]: string | number };
  addressLine1?: string;
  addressLine2?: string;
  companyEmail?: string;
  companyPhone?: string;
  zipPostalCode?: string;
}

const StatusOptions = [
  { value: 0, label: 'Inactive' },
  { value: 1, label: 'Active' },
];

export enum AffiliateTabs {
  integration = 'INTEGRATION',
  channels = 'CHANNELS',
}

const AffiliatesDetails = () => {
  const [tabValue, setTabValue] = useState<number | string>(AffiliateTabs.integration);
  const [countryName, setCountryName] = useState<string>('');

  const currentAffiliateById = useAppSelector(affiliatesSelector.currentAffiliateById);
  const isAffiliatesLoading = useAppSelector(affiliatesSelector.isAffiliatesLoading);
  const isLoading = useAppSelector(affiliatesSelector.isLoading);
  const countries = useAppSelector(countriesSelector.countries);
  const navigate = useNavigate();
  const { id: affiliateId } = useParams();

  // @TODO: Need to remove these inform keys, as form hook has corresponding functional
  const informationKeys = useMemo(
    () => [
      'id',
      'name',
      'countryId',
      'companyEmail',
      'city',
      'status',
      'companyPhone', // only for support BE update
      'zipPostalCode', // only for support BE update
      'stateProvinceId', // only for support BE update
      'webhookToken',
      'statusWebhookUrl',
      'offersWebhookUrl',
      'stipulationsWebhookUrl',
      'secretKey',
      'addressLine1',
    ],
    [],
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      id: currentAffiliateById?.id ?? '',
      name: currentAffiliateById?.name ?? '',
      countryId: `${currentAffiliateById?.countryId}` ?? '',
      city: currentAffiliateById?.city ?? '',
      managers: currentAffiliateById?.managers ?? '',
      status: currentAffiliateById?.status ?? 0,
      stateProvinceId: currentAffiliateById?.stateProvinceId ?? '',
      webhookToken: currentAffiliateById?.webhookToken ?? '',
      statusWebhookUrl: currentAffiliateById?.statusWebhookUrl ?? '',
      offersWebhookUrl: currentAffiliateById?.offersWebhookUrl ?? '',
      stipulationsWebhookUrl: currentAffiliateById?.stipulationsWebhookUrl ?? '',
      secretKey: currentAffiliateById?.secretKey ?? '',
      addressLine1: currentAffiliateById?.addressLine1 ?? '',
      addressLine2: currentAffiliateById?.addressLine2 ?? '',
      companyEmail: currentAffiliateById?.companyEmail ?? '',
      companyPhone: currentAffiliateById?.companyPhone ?? '',
      zipPostalCode: currentAffiliateById?.zipPostalCode ?? '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(createOrEditSource),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (countries) {
      const country = countries.find((item: ICountriesProps) => item.id === currentAffiliateById?.countryId);

      if (country?.name) {
        setCountryName(country.name);
      }
    }
  }, [countries]);

  const onSubmit = async (values: FormValues) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateDetailsSaveChangesModal,
        props: { values, affiliateId },
      }),
    );
  };

  useEffect(() => {
    if (tabValue === AffiliateTabs.integration && currentAffiliateById) {
      informationKeys?.forEach((item) => {
        if (item !== 'Status') {
          // @ts-ignore
          setValue(item as keyof FormValues, currentAffiliateById[item]);
        }
      });
    }
  }, [currentAffiliateById, tabValue]);

  useEffect(() => {
    switch (tabValue) {
      case AffiliateTabs.channels:
        dispatch(affiliatesMiddleware.fetchAffiliateChannelByDetailsId(affiliateId ?? '0'));
        break;
      default:
        dispatch(countriesMiddleware.fetchCountries());
        dispatch(affiliatesMiddleware.fetchCurrentAffiliateById(affiliateId ?? '0'));
    }
  }, [tabValue]);

  if (isAffiliatesLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <TitleWithBreadcrumbs
          title="Sources List"
          dataTest="affiliate-details-page-header-affiliates-list"
          link="/sources"
          linkTitle="Sources List"
          currentTitle={`Source ${affiliateId}`}
        />
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            borderRadius: '17px',
            padding: '20px 30px',
            height: '100%',
          }}
        >
          <Grid item container display="flex" xs={12} alignItems="start" flexDirection="column">
            <BaseTabs
              tabs={[
                { id: 'INTEGRATION', label: 'Integration', permission: Permissions.viewIntegration },
                { id: 'CHANNELS', label: 'Channels', permission: Permissions.viewChannelsForSource },
              ]}
              dataTest={['affiliate-details-button-tab-integration', 'affiliate-details-button-tab-channels']}
              tabValue={tabValue}
              sx={{ width: '100%' }}
              setTabValue={setTabValue}
              component="div"
            />
            {tabValue === AffiliateTabs.integration && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                  <Grid container sx={{ width: '100%', maxWidth: '469px', gap: '20px', marginTop: '20px' }}>
                    <Grid item xs={12}>
                      <FormTextField
                        name="id"
                        permission={Permissions.changeIntegrationFields}
                        label="Source Id"
                        dataTest="affiliate-details-tab-integration-input-affiliate-id"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="name"
                        permission={Permissions.changeIntegrationFields}
                        label="Source Name"
                        dataTest="affiliate-details-tab-integration-input-affiliate-name"
                        placeholder="Source Name"
                        required
                      />
                      {errors?.name?.message ? <FormHelperText error>{errors?.name?.message}</FormHelperText> : null}
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        permission={Permissions.changeIntegrationFields}
                        value={countryName}
                        label="Country"
                        disabled
                        dataTest="affiliate-details-tab-integration-input-country"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="city"
                        permission={Permissions.changeIntegrationFields}
                        label="City"
                        dataTest="affiliate-details-tab-integration-input-city"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="companyEmail"
                        permission={Permissions.changeIntegrationFields}
                        label="Email"
                        dataTest="affiliate-details-tab-integration-input-email"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="secretKey"
                        permission={Permissions.changeIntegrationFields}
                        label="Secret Key"
                        dataTest="affiliate-details-tab-integration-input-stipulations-secret-key"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        label="Status"
                        width="100%"
                        options={StatusOptions as unknown as IControlledSelectOptions[]}
                        name="status"
                        permission={Permissions.changeIntegrationFields}
                        dataTest="affiliate-details-tab-integration-dropdown-status"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Status Webhook URL"
                        placeholder="Status Webhook URL"
                        name="statusWebhookUrl"
                        permission={Permissions.changeIntegrationFields}
                        dataTest="affiliate-details-tab-integration-input-offers-webhook-url"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Offers Webhook URL"
                        placeholder="Offers Webhook URL"
                        name="offersWebhookUrl"
                        permission={Permissions.changeIntegrationFields}
                        dataTest="affiliate-details-tab-integration-input-offers-webhook-url"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Stipulations Webhook URL"
                        placeholder="Stipulations Webhook URL"
                        name="stipulationsWebhookUrl"
                        permission={Permissions.changeIntegrationFields}
                        dataTest="affiliate-details-tab-integration-input-stipulations-webhook-url"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        label="Webhook Token"
                        placeholder="Webhook Token"
                        name="webhookToken"
                        permission={Permissions.changeIntegrationFields}
                        dataTest="affiliate-details-tab-integration-input-webhook-token"
                      />
                    </Grid>
                    <Permission permission={Permissions.changeIntegrationFields}>
                      <Grid item sx={buttonsWrapper} width={469}>
                        <CustomButton
                          data-test="affiliate-details-button-cancel"
                          variant="outlined"
                          onClick={() => navigate('/sources', { replace: true })}
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          loading={isLoading}
                          data-test="affiliate-details-tab-integration-button-save-changes"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Save changes
                        </CustomButton>
                      </Grid>
                    </Permission>
                  </Grid>
                </FormProvider>
              </form>
            )}
            {tabValue === AffiliateTabs.channels && <AffiliatesAffiliateChannels />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AffiliatesDetails;
