import { dateTime } from 'helpers';
import { dispatch } from 'redux/hooks';

import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import {
  API_AFFILIATE,
  API_AFFILIATE_CHANNEL_BY_AFFILIATE_ID,
  API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID,
  API_AFFILIATE_CHANNEL_BY_ID,
  API_AFFILIATE_CHANNEL_STATUS,
  API_AFFILIATE_CHANNELS,
  API_AFFILIATE_STATUS,
  API_CREDIT_UNIONS,
  API_EXTERNAL_SERVICES,
  API_GET_AFFILIATE_CHANNEL_POSTING_DETAILS_BY_ID,
  API_GET_DATA_FORMATS,
  API_GET_HISTORY_AFFILIATE_CHANNELS,
  API_PATCH_AFFILIATE_CHANNEL_RESET_PASSWORD,
  API_SIMPLIFIED_AFFILIATE,
  API_SIMPLIFIED_AFFILIATE_CHANNELS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import {
  IAffiliateChannelProps,
  IAffiliateProps,
  ICreateAffiliateChannelReqTemplate,
  ICreateAffiliateReqTemplate,
} from '../../../types';
import { SeveritiesType } from '../../../types/snackbar';
import store from '../../store';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import slice from './slice';

const {
  getAffiliatesListSuccess,
  getCurrentAffiliateIdSuccess,
  hasError,
  hasSuccessfully,
  getLoading,
  getAffiliateChannelByIdSuccess,
  setAffiliatesLoading,
  getAffiliatesListCount,
  setAffiliateChannelsLoading,
  getAffiliateChannelListCount,
  setDeleteAffiliateChannelLoading,
  setUpdateAffiliateChannelStatusLoading,
  getAffiliateChannelListSuccess,
  setCreateAffiliateLoading,
  setCreateAffiliateChannelLoading,
  setUpdateAffiliateStatusLoading,
  setDeleteAffiliateLoading,
  getDataFormatsSuccess,
  setUpdateAffiliateChannelLoading,
  getExternalServiceByIdSuccess,
  setAffiliateChannelPostingDetailsLoading,
  getAffiliateChannelPostingDetailsSuccess,
  setAffiliateChannelHistoryLoading,
  setAffiliateChannelHistory,
  setSimplifiedAffiliatesChannel,
  setSimplifiedAffiliates,
  setCreditUnions,
  setCreditUnionsLoading,
  setCampaignsAffiliateChannelsCount,
  setAffiliateChannelHistoryCount,
  setAffiliateFilters,
  setAffiliateChannelFilters,
} = slice.actions;

const fetchHasSuccessfully = (value: boolean) => async () => {
  try {
    dispatch(hasSuccessfully(value));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchAffiliates =
  (status = -1, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean, name?: string) =>
  async () => {
    try {
      dispatch(setAffiliatesLoading(true));

      const { data: response } = await DataService.getJson(API_AFFILIATE(), {
        status,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
        ...(name ? { name } : {}),
      });

      dispatch(
        setAffiliateFilters({
          status,
          pageNumber,
          pageSize,
          sortOrderKey,
          sortOrderValue,
          name,
        }),
      );

      response.data?.forEach((item: IAffiliateProps) => {
        item.updatedDate = dateTime(item.updatedDate);
        item.createdDate = dateTime(item.createdDate);
      });
      dispatch(getAffiliatesListSuccess(response.data));
      dispatch(getAffiliatesListCount(response.itemsCount));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(setAffiliatesLoading(false));
      }, 100);
    }
  };

const fetchSimplifiedAffiliates = () => async () => {
  try {
    const { data: response } = await DataService.getJson(API_SIMPLIFIED_AFFILIATE(), {});

    dispatch(setSimplifiedAffiliates(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchCreditUnions =
  (mode = 'validation') =>
  async () => {
    try {
      dispatch(setCreditUnionsLoading(true));

      const { data: response } = await DataService.getData(API_CREDIT_UNIONS());

      dispatch(setCreditUnions(mode === 'validation' ? [{ id: 0, name: 'Select' }, ...response] : response));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setCreditUnionsLoading(false));
    }
  };

const fetchAffiliateChannels =
  (
    status: number = -1,
    pageNumber = 1,
    pageSize = 10,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setAffiliateChannelsLoading(true));

      const { data: response } = await DataService.getJson(API_AFFILIATE_CHANNELS(), {
        status,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
        ...(name ? { name } : {}),
      });

      dispatch(
        setAffiliateChannelFilters({
          status,
          pageNumber,
          pageSize,
          sortOrderKey,
          sortOrderValue,
          name,
        }),
      );

      if (response.data && response.data[0]) {
        response.data?.forEach((item: IAffiliateChannelProps) => {
          item.updatedDate = dateTime(item.updatedDate);
          item.createdDate = dateTime(item.createdDate);
        });
        dispatch(getAffiliateChannelListSuccess(response.data));
        dispatch(getAffiliateChannelListCount(response.itemsCount));
      } else {
        dispatch(getAffiliateChannelListSuccess(null));
      }
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      setTimeout(() => {
        dispatch(setAffiliateChannelsLoading(false));
      }, 100);
    }
  };

const fetchSimplifiedAffiliateChannels = () => async () => {
  try {
    const { data: response } = await DataService.getJson(API_SIMPLIFIED_AFFILIATE_CHANNELS());

    dispatch(setSimplifiedAffiliatesChannel(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchDeleteAffiliateChannel =
  (
    id: string,
    status: number,
    pageNumber: number,
    pageSize: number,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setDeleteAffiliateChannelLoading(true));
      await DataService.delete(API_AFFILIATE_CHANNELS(id));

      dispatch(fetchAffiliateChannels(status, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));
      dispatch(setDeleteAffiliateChannelLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setDeleteAffiliateChannelLoading(false));
    } finally {
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelDeleteModal));
      dispatch(setDeleteAffiliateChannelLoading(false));
    }
  };

const fetchAffiliateChannelByCampaignId =
  (id: string, status: number = -1, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(setAffiliateChannelsLoading(true));

      const { data: response } = await DataService.getData(API_AFFILIATE_CHANNEL_BY_CAMPAIGN_ID(id), {
        status,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
      });

      dispatch(getAffiliateChannelListSuccess(response?.data));
      dispatch(setCampaignsAffiliateChannelsCount(response?.itemsCount));
      dispatch(setAffiliateChannelsLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setAffiliateChannelsLoading(false));
    }
  };

const fetchAffiliateChannelByDetailsId =
  (id: string, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(setAffiliateChannelsLoading(true));

      const { data: response } = await DataService.getData(API_AFFILIATE_CHANNEL_BY_AFFILIATE_ID(id), {
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
      });

      response?.data?.forEach((item: IAffiliateProps) => {
        item.updatedDate = dateTime(item.updatedDate);
        item.createdDate = dateTime(item.createdDate);
      });
      dispatch(getAffiliateChannelListSuccess(response?.data));
      dispatch(getAffiliateChannelListCount(response?.itemsCount));
      dispatch(setAffiliateChannelsLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setAffiliateChannelsLoading(false));
    }
  };

const fetchUpdateAffiliateChannelStatus =
  ({
    id,
    status,
    defaultStatus,
    affiliateId,
    pageNumber = 1,
    pageSize = 10,
    sortOrderKey,
    sortOrderValue,
    name,
    campaignId,
  }: {
    id: string;
    status: number;
    defaultStatus?: number;
    affiliateId?: string | null;
    pageNumber?: number;
    pageSize?: number;
    sortOrderKey?: string;
    sortOrderValue?: boolean;
    name?: string;
    campaignId?: string;
  }) =>
  async () => {
    try {
      dispatch(setUpdateAffiliateChannelStatusLoading(true));
      await DataService.put(API_AFFILIATE_CHANNEL_STATUS(id, status));

      dispatch(getAffiliateChannelListSuccess(null));

      if (affiliateId) {
        dispatch(fetchAffiliateChannelByDetailsId(affiliateId, pageNumber, pageSize, sortOrderKey, sortOrderValue));
      } else if (campaignId) {
        dispatch(
          fetchAffiliateChannelByCampaignId(
            campaignId,
            defaultStatus,
            pageNumber,
            pageSize,
            sortOrderKey,
            sortOrderValue,
          ),
        );
      } else {
        dispatch(fetchAffiliateChannels(defaultStatus, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));
      }

      dispatch(setUpdateAffiliateChannelStatusLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setUpdateAffiliateChannelStatusLoading(false));
    }
  };

const fetchCurrentAffiliateById = (id: string) => async () => {
  try {
    dispatch(setAffiliatesLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE(id));

    dispatch(getCurrentAffiliateIdSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setAffiliatesLoading(false));
  }
};

const fetchCurrentAffiliateChannelById = (id: string) => async () => {
  try {
    dispatch(getLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE_CHANNELS(id));

    dispatch(getAffiliateChannelByIdSuccess(response));
  } catch (error) {
    responseErrorHandler(error);
  } finally {
    dispatch(getLoading(false));
  }
};

const fetchUpdateAffiliateByName = (affiliateData: IAffiliateProps, affiliateId: string) => async () => {
  try {
    dispatch(getLoading(true));

    const { secretKey, ...newData } = affiliateData;

    await DataService.post(API_AFFILIATE(), newData);
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Source successfully edited',
        },
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateDetailsSaveChangesModal));
    dispatch(fetchCurrentAffiliateById(affiliateId));
  } catch (error: any) {
    if (error?.response?.status === 500) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: 'Something went wrong',
          },
        }),
      );
    } else if (Array.isArray(error?.response?.data) && error?.response?.data?.length) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error?.response?.data[0]?.errorMessage,
          },
        }),
      );
      dispatch(hasError(error));
    } else if (error?.response?.data) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error?.response?.data,
          },
        }),
      );
    } else {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: 'Something went wrong',
          },
        }),
      );
    }
  } finally {
    dispatch(getLoading(false));
  }
};

const fetchUpdateAffiliateByStatus = (id: string, affiliateStatus: number) => async () => {
  try {
    dispatch(getLoading(true));

    await DataService.put(API_AFFILIATE_STATUS(id, affiliateStatus));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(getLoading(false));
  }
};

const fetchCreateAffiliate = (affiliateData: ICreateAffiliateReqTemplate) => async () => {
  try {
    dispatch(setCreateAffiliateLoading(true));
    await DataService.post(API_AFFILIATE(), {
      ...affiliateData,
    });
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Source successfully created',
        },
      }),
    );
    dispatch(hasSuccessfully(true));
  } catch (error: any) {
    responseErrorHandler(error);
    // if (error?.response?.status === 500) { @todo error handling for create source need to have custom error handling, need to keep this comment for checking whole cases
    //   dispatch(
    //     viewsMiddleware.setToastNotificationPopUpState({
    //       open: true,
    //       props: {
    //         severityType: SeveritiesType.error,
    //         description: 'Something went wrong',
    //       },
    //     }),
    //   );
    // } else if (Array.isArray(error?.response?.data) && error?.response?.data?.length) {
    //   dispatch(
    //     viewsMiddleware.setToastNotificationPopUpState({
    //       open: true,
    //       props: {
    //         severityType: SeveritiesType.error,
    //         description: error?.response?.data[0]?.errorMessage,
    //       },
    //     }),
    //   );
    //   dispatch(hasError(error));
    // } else if (error?.response?.data) {
    //   dispatch(
    //     viewsMiddleware.setToastNotificationPopUpState({
    //       open: true,
    //       props: {
    //         severityType: SeveritiesType.error,
    //         description: error?.response?.data,
    //       },
    //     }),
    //   );
    // } else {
    //   dispatch(
    //     viewsMiddleware.setToastNotificationPopUpState({
    //       open: true,
    //       props: {
    //         severityType: SeveritiesType.error,
    //         description: 'Something went wrong',
    //       },
    //     }),
    //   );
    // }

    dispatch(getLoading(false));
  } finally {
    dispatch(setCreateAffiliateLoading(false));
  }
};

const fetchCreateAffiliateChannel = (data: ICreateAffiliateChannelReqTemplate) => async () => {
  try {
    dispatch(setCreateAffiliateChannelLoading(true));

    const { data: response } = await DataService.post(API_AFFILIATE_CHANNELS(), {
      ...data,
    });

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Source Channel successfully created',
        },
      }),
    );
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateChannelCreateSuccessModal,
        props: null,
      }),
    );
    dispatch(hasSuccessfully(true));
    dispatch(getAffiliateChannelByIdSuccess(response));
  } catch (error) {
    responseErrorHandler(error);

    dispatch(hasSuccessfully(false));
  } finally {
    dispatch(setCreateAffiliateChannelLoading(false));
  }
};

const fetchUpdateAffiliateStatus =
  (
    id: string,
    status: number,
    defaultStatus: number,
    pageNumber = 1,
    pageSize = 10,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setUpdateAffiliateStatusLoading(true));

      await DataService.put(API_AFFILIATE_STATUS(id, status));

      dispatch(fetchAffiliates(defaultStatus, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setUpdateAffiliateStatusLoading(false));
    }
  };

const fetchDeleteAffiliate =
  (
    id: string,
    defaultStatus: number,
    pageNumber: number,
    pageSize: number,
    sortOrderKey?: string,
    sortOrderValue?: boolean,
    name?: string,
  ) =>
  async () => {
    try {
      dispatch(setDeleteAffiliateLoading(true));
      await DataService.delete(API_AFFILIATE(id));

      dispatch(fetchAffiliates(defaultStatus, pageNumber, pageSize, sortOrderKey, sortOrderValue, name));
    } catch (error) {
      responseErrorHandler(error);
    } finally {
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateDeleteModal));
      dispatch(setDeleteAffiliateLoading(false));
    }
  };

const fetchSearchAffiliates = (inputValue: string) => async () => {
  try {
    dispatch(getLoading(true));

    const { data: response } = await DataService.getData(API_AFFILIATE('search-pattern'), {
      inputValue,
    });

    dispatch(getAffiliatesListSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(getLoading(false));
  }
};

const fetchIsAffiliateSuccessfully = (isSuccessfully: boolean) => async () => {
  try {
    dispatch(hasSuccessfully(isSuccessfully));
    dispatch(hasError(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchDataFormatsList = () => async () => {
  try {
    const { data: response } = await DataService.getData(API_GET_DATA_FORMATS());

    dispatch(getDataFormatsSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchUpdateAffiliateChannel = (id: string, data: ICreateAffiliateChannelReqTemplate) => async () => {
  try {
    dispatch(setUpdateAffiliateChannelLoading(true));

    await DataService.putStatus(API_AFFILIATE_CHANNEL_BY_ID(id), {
      ...data,
    });

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Source Channel successfully edited',
        },
      }),
    );

    dispatch(fetchCurrentAffiliateChannelById(id));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasSuccessfully(false));
  } finally {
    dispatch(setUpdateAffiliateChannelLoading(false));
  }
};

const fetchGetExternalServiceById = (id: string) => async () => {
  try {
    const { data: response } = await DataService.getData(API_EXTERNAL_SERVICES(id));

    dispatch(getExternalServiceByIdSuccess(response));
  } catch (e) {
    dispatch(hasError(e));
  }
};

const fetchAffiliateChannelPostingDetails = (id: string) => async () => {
  try {
    dispatch(setAffiliateChannelPostingDetailsLoading(true));

    const { data: response } = await DataService.getJson(API_GET_AFFILIATE_CHANNEL_POSTING_DETAILS_BY_ID(id));

    dispatch(getAffiliateChannelPostingDetailsSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setAffiliateChannelPostingDetailsLoading(false));
  }
};

const fetchAffiliateChannelResetPassword = (id: string) => async () => {
  try {
    const { data: password } = await DataService.patch(API_PATCH_AFFILIATE_CHANNEL_RESET_PASSWORD(id), { id });

    const { affiliateChannelPostingDetails } = store.getState().affiliates;

    dispatch(
      getAffiliateChannelPostingDetailsSuccess({
        ...affiliateChannelPostingDetails,
        password,
      }),
    );
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchAffiliateChannelHistory =
  (id: string, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(setAffiliateChannelHistoryLoading(true));

      const { data: response } = await DataService.getData(API_GET_HISTORY_AFFILIATE_CHANNELS(id), {
        pageNumber,
        pageSize,
        sortOrderKey,
        sortOrderValue,
      });

      dispatch(setAffiliateChannelHistory(response?.data));
      dispatch(setAffiliateChannelHistoryCount(response?.itemsCount));
      dispatch(hasError(null));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(setAffiliateChannelHistoryLoading(false));
    }
  };

export default {
  fetchHasSuccessfully,
  fetchAffiliates,
  fetchSimplifiedAffiliates,
  fetchAffiliateChannels,
  fetchSimplifiedAffiliateChannels,
  fetchDeleteAffiliateChannel,
  fetchAffiliateChannelByCampaignId,
  fetchUpdateAffiliateChannelStatus,
  fetchCurrentAffiliateById,
  fetchCurrentAffiliateChannelById,
  fetchAffiliateChannelByDetailsId,
  fetchUpdateAffiliateByName,
  fetchUpdateAffiliateByStatus,
  fetchCreateAffiliate,
  fetchCreateAffiliateChannel,
  fetchUpdateAffiliateStatus,
  fetchDeleteAffiliate,
  fetchSearchAffiliates,
  fetchIsAffiliateSuccessfully,
  fetchCreditUnions,
  fetchDataFormatsList,
  fetchUpdateAffiliateChannel,
  fetchGetExternalServiceById,
  fetchAffiliateChannelPostingDetails,
  fetchAffiliateChannelResetPassword,
  fetchAffiliateChannelHistory,
};
