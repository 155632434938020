import { Permissions } from 'components/Permissions/constants';
import { ManualReviewItems } from 'components/shared/NestedSelect/NestedSelectManualReview';
import { dateTime, trimProperties } from 'helpers';
import { CookieManager } from 'helpers/CookieManager';
import { responseErrorHandler } from 'helpers/ErrorHandler';
import { renderUserName } from 'helpers/renderValue';
import { dispatch, getStateBySelector } from 'redux/hooks';
import DataService from 'service/data.service';
import { SeveritiesType } from 'types/snackbar';

import { ILeadsManualReviewReason } from '@types';

import { hasPermission } from '../../../components/Permissions/Permission';
import { MANUAL_REVIEW_LIST_KEY, PersistManager } from '../../../helpers/PersistManager';
import {
  API_AAN_REASON,
  API_AAN_REASON_CREATE,
  API_ASSIGN_REVIEWER,
  API_LEADS_MANUAL_REVIEW,
  API_LEADS_MANUAL_REVIEW_REASON,
  API_LEADS_MANUAL_REVIEW_RESOLVE,
  API_MANUAL_REVIEW_DOCUMENT_DELETE,
  API_MANUAL_REVIEW_DOCUMENT_SUBTYPES,
  API_MANUAL_REVIEW_REASONS_SUBMIT,
  API_MANUAL_REVIEWS,
  API_MANUAL_REVIEWS_SOLAR_PANEL_DETAILS,
  API_MERCHANT_NAMES,
  API_UNDERWRITERS,
} from '../../../lib/constants/api.constants.simple';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { manualReviewMiddleware } from './index';
import { IManualReview, IManualReviewListFilters } from './initialState';
import slice from './slice';

const {
  setIsLoading,
  setManualReviewsList,
  setManualReviews,
  setManualReviewsLoading,
  setManualReviewReason,
  setManualReviewReasonLoading,
  setManualReviewUnderwriters,
  setManualReviewUnderwritersLoading,
  setMerchantNames,
  setMerchantNamesLoading,
  setManualReviewListFilters,
  setDocumentTypes,
  setDocumentTypesLoading,
  setUpdateSolarPanelDetailsLoading,
  setDeleteFileLoading,
  setAanReasons,
  setAanReasonsLoading,
} = slice.actions;

const resolveManualReviewReason =
  (leadId: string, reason: ILeadsManualReviewReason, status: number, notes?: string, aanReasonIds?: string[]) =>
  async () => {
    try {
      dispatch(setManualReviewReasonLoading(true));

      const { value: trimmedNotes } = trimProperties({ value: notes }, ['value']);

      await DataService.post(API_LEADS_MANUAL_REVIEW_RESOLVE(reason.id), {
        notes: trimmedNotes,
        leadId,
        reviewLink: reason.reviewLink,
        manualReviewStatus: status,
        aanReasonIds,
      });

      if (status === 2) {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.success,
              description: 'Manual Review was rejected.',
            },
          }),
        );
        dispatch(viewsMiddleware.closeModal(ModalName.RejectApplicantModal));
      } else {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.success,
              description: 'Manual Review was successfully accepted.',
            },
          }),
        );
      }

      dispatch(manualReviewMiddleware.fetchManualReviewsReasonByLeadId(`${reason.id}`));
    } catch (error: any) {
      responseErrorHandler(error);

      console.log(error);
    } finally {
      dispatch(setManualReviewReasonLoading(false));
    }
  };

const fetchManualReviewsList = (data?: IManualReviewListFilters) => async () => {
  try {
    if (!data) {
      data = PersistManager.getData(MANUAL_REVIEW_LIST_KEY);
    }

    if (!data) {
      dispatch(setManualReviewListFilters(null));
    } else {
      dispatch(setIsLoading(true));

      const oldFilters = getStateBySelector('manualReview').manualReviewListFilters;

      const filters = { ...oldFilters, ...data };

      const sortedFilters = {
        ...filters,
        applicationId: filters.applicationId ? filters.applicationId : null,
        status: filters.status !== '0' ? filters.status : null,
      };

      const { data: response } = await DataService.post(API_MANUAL_REVIEWS(), sortedFilters);

      PersistManager.setData(MANUAL_REVIEW_LIST_KEY, sortedFilters);

      dispatch(setManualReviewListFilters(filters));

      response?.data?.forEach(
        (item: { date: string; requestAmount: string; firstname: string; lastname: string; borrower: string }) => {
          item.borrower = renderUserName(
            item?.firstname,
            item?.lastname,
            response?.piiProperties?.includes('firstname') || response?.piiProperties?.includes('lastname'),
          );
          item.date = dateTime(item.date);
          item.requestAmount = item.requestAmount ? `$ ${item.requestAmount}` : '';
        },
      );

      dispatch(setManualReviewsList(response));
    }
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 100);
  }
};

const fetchManualReviewsByLeadId = (leadId: string) => async () => {
  try {
    dispatch(setManualReviewsLoading(true));

    const { data: response } = await DataService.getData(API_LEADS_MANUAL_REVIEW(leadId));

    response?.reasons?.forEach((item: { date: string; name: string }) => {
      item.date = dateTime(item.date);
      item.name = ManualReviewItems[item.name as keyof typeof ManualReviewItems] ?? item.name;
    });
    dispatch(setManualReviews(response));
  } catch (error) {
    responseErrorHandler(error);
    console.log(error);
  } finally {
    dispatch(setManualReviewsLoading(false));
  }
};

const clearManualReviews = () => () => {
  dispatch(setManualReviews(null));
};

const fetchManualReviewsReasonByLeadId = (id: string) => async () => {
  try {
    dispatch(setManualReviewReasonLoading(true));

    const { data: response } = await DataService.getData(API_LEADS_MANUAL_REVIEW_REASON(id));

    dispatch(setManualReviewReason(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setManualReviewReasonLoading(false));
  }
};

const fetchManualReviewUnderwriters = () => async () => {
  const user = CookieManager.getUser();

  if (user) {
    const [firstName, lastName] = user.name.split(' ');

    const options = [
      { id: null, firstName: 'Unassigned', lastName: ' ', email: '' },
      { id: user.id, firstName, lastName: `${lastName} (Assign to me)`, email: user.email },
    ];

    if (!hasPermission(Permissions.assignReason)) {
      dispatch(setManualReviewUnderwriters(options));
    } else {
      try {
        dispatch(setManualReviewUnderwritersLoading(true));

        const { data: response } = await DataService.getData(API_UNDERWRITERS());

        dispatch(setManualReviewUnderwriters([...options, ...response]));
      } catch (error: any) {
        console.log(error);
      } finally {
        dispatch(setManualReviewUnderwritersLoading(false));
      }
    }
  }
};

const fetchManualReviewUnderwriterAssign =
  (reasonId: string, assignee: { userId: string | null; name: string }) => async () => {
    try {
      dispatch(setManualReviewUnderwritersLoading(true));

      await DataService.post(API_ASSIGN_REVIEWER(reasonId), { userId: assignee.userId });

      const user = CookieManager.getUser();

      const oldManualReviewReason = getStateBySelector('manualReview').manualReviewReason;

      dispatch(
        setManualReviewReason({
          ...oldManualReviewReason,
          reviewerId: assignee.userId,
          reviewerName: user?.id === assignee.userId ? user?.name : assignee.name,
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setManualReviewUnderwritersLoading(false));
    }
  };

const fetchManualReviewReasonSubmit = (reasonId: string, data: any) => async () => {
  try {
    dispatch(setManualReviewReasonLoading(true));

    await DataService.post(API_MANUAL_REVIEW_REASONS_SUBMIT(reasonId), data);

    dispatch(fetchManualReviewsReasonByLeadId(reasonId));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setManualReviewReasonLoading(false));
  }
};

const fetchMerchantNames = () => async () => {
  try {
    dispatch(setMerchantNamesLoading(true));

    const { data: response } = await DataService.getData(API_MERCHANT_NAMES());
    const MerchantList = response.map((el: string) => ({ id: el, name: el }));

    dispatch(setMerchantNames(MerchantList));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setMerchantNamesLoading(false));
  }
};

const updateManualReviewList = (data: IManualReview[]) => async () => {
  const { manualReviewsList } = getStateBySelector('manualReview');

  dispatch(setManualReviewsList({ ...manualReviewsList, data }));
};

const getManualReviewDocumentsTypes = (id: string) => async () => {
  try {
    dispatch(setDocumentTypesLoading(true));

    const { data: response } = await DataService.getData(API_MANUAL_REVIEW_DOCUMENT_SUBTYPES(id));

    dispatch(setDocumentTypes(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setDocumentTypesLoading(false));
  }
};

const deleteManualReviewReasonFile = (id: string, reasonId: string) => async () => {
  try {
    dispatch(setDeleteFileLoading(true));

    await DataService.delete(API_MANUAL_REVIEW_DOCUMENT_DELETE(id));

    dispatch(manualReviewMiddleware.fetchManualReviewsReasonByLeadId(reasonId));

    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'File successfully deleted.',
        },
      }),
    );
  } catch (error: any) {
    // TODO this is workaround, change this after BE is ready for one type of error messages, checking the type for avoid problems when the data can be array or object
    responseErrorHandler(error);

    console.log(error);
  } finally {
    dispatch(setDeleteFileLoading(false));
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteFileModal));
  }
};

const updateSolarPanelDetails = (reasonId: string, data: any) => async () => {
  try {
    dispatch(setUpdateSolarPanelDetailsLoading(true));

    await DataService.put(API_MANUAL_REVIEWS_SOLAR_PANEL_DETAILS(reasonId), data);

    dispatch(fetchManualReviewsReasonByLeadId(reasonId));

    dispatch(viewsMiddleware.closeModal(ModalName.SolarPanelEditModal));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setUpdateSolarPanelDetailsLoading(false));
  }
};

const getAanReasons = (reasonId: string) => async () => {
  try {
    dispatch(setAanReasonsLoading(true));

    const { data: response } = await DataService.getData(API_AAN_REASON(reasonId));

    dispatch(setAanReasons(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setAanReasonsLoading(false));
  }
};

const createAanReason = (reasonId: string, name: string) => async () => {
  try {
    dispatch(setAanReasonsLoading(true));

    const { data: response } = await DataService.post(API_AAN_REASON_CREATE(), {
      name,
      manualReviewReason: reasonId,
    });

    const { aanReasons } = getStateBySelector('manualReview');

    dispatch(setAanReasons([...aanReasons, response]));

    dispatch(viewsMiddleware.closeModal(ModalName.CreateReasonModal));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setAanReasonsLoading(false));
  }
};

export default {
  fetchManualReviewsList,
  resolveManualReviewReason,
  fetchManualReviewsByLeadId,
  fetchManualReviewsReasonByLeadId,
  clearManualReviews,
  fetchManualReviewUnderwriters,
  fetchManualReviewUnderwriterAssign,
  fetchManualReviewReasonSubmit,
  fetchMerchantNames,
  updateManualReviewList,
  getManualReviewDocumentsTypes,
  deleteManualReviewReasonFile,
  updateSolarPanelDetails,
  getAanReasons,
  createAanReason,
};
