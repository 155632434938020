import { dispatch } from 'redux/hooks';

import { API_SYSTEM_FIELDS } from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';

import slice from './slice';

const { getSystemFieldsSuccess, hasError, getLoading } = slice.actions;

const fetchGetSystemFields = () => async () => {
  try {
    dispatch(getLoading(true));

    const response = await DataService.getJson(API_SYSTEM_FIELDS());

    dispatch(getSystemFieldsSuccess(response));
    dispatch(getLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(getLoading(false));
  }
};

export default {
  fetchGetSystemFields,
};
