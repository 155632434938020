import { ILeadsDetails } from '@types';

import { UpdateTableData } from '../../../helpers/UpdateTableData';
import {
  API_TAGS,
  API_TAGS_CHANGE,
  API_TAGS_REMOVE,
  API_TAGS_TYPES,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { SeveritiesType } from '../../../types/snackbar';
import { dispatch, getStateBySelector } from '../../hooks';
import { fundingMiddleware } from '../funding';
import { IFundingList } from '../funding/initialState';
import { leadsMiddleware } from '../leads';
import { manualReviewMiddleware } from '../manualReviews';
import { IManualReview } from '../manualReviews/initialState';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { ITag } from './initialState';
import slice from './slice';

const { setTags, setTagsLoading } = slice.actions;

const fetchTags = () => async () => {
  try {
    dispatch(setTagsLoading(true));

    const { data: response } = await DataService.getData(API_TAGS_TYPES());

    dispatch(setTags(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setTagsLoading(false));
  }
};

const fetchDataAfterTagsChange =
  (
    page: number,
    targetId: string,
    type: 'add' | 'create' | 'remove' | 'delete' | 'update',
    tagName: string,
    color: number,
    oldName?: string,
  ) =>
  async () => {
    try {
      switch (page) {
        case 1: {
          const { leads } = getStateBySelector('leads');

          const changedList = UpdateTableData(type, leads, tagName, color, targetId, oldName);

          dispatch(leadsMiddleware.updateLeadsList(changedList as ILeadsDetails[]));

          if (type === 'delete') {
            dispatch(fetchTags());
          }

          break;
        }

        case 2: {
          const manualReviewsList = getStateBySelector('manualReview').manualReviewsList?.data;

          const changedManualReviewsList = UpdateTableData(
            type,
            manualReviewsList,
            tagName,
            color,
            targetId,
            oldName,
            'leadId',
          );

          dispatch(manualReviewMiddleware.updateManualReviewList(changedManualReviewsList as IManualReview[]));

          if (type === 'delete') {
            dispatch(fetchTags());
          }

          break;
        }

        case 4: {
          const fundingListData = getStateBySelector('funding').fundingList?.data;

          const changedFundingList = UpdateTableData(
            type,
            fundingListData,
            tagName,
            color,
            targetId,
            oldName,
            'leadId',
          );

          dispatch(fundingMiddleware.updateFundingList(changedFundingList as IFundingList[]));

          if (type === 'delete') {
            dispatch(fetchTags());
          }

          break;
        }

        case 5: {
          const { leadsDetails } = getStateBySelector('leads');

          if (type === 'add') {
            const changedLead = {
              ...leadsDetails,
              tags: [
                ...leadsDetails.tags,
                {
                  name: tagName,
                  color,
                  targetId,
                },
              ],
            };

            dispatch(leadsMiddleware.updateLeadDetails(changedLead));
          }

          if (type === 'remove') {
            const changedLead = {
              ...leadsDetails,
              tags: leadsDetails.tags.filter((tag: ITag) => tag.name !== tagName),
            };

            dispatch(leadsMiddleware.updateLeadDetails(changedLead));
          }

          if (type === 'delete') {
            const changedLead = {
              ...leadsDetails,
              tags: leadsDetails.tags.filter((tag: ITag) => tag.name !== tagName),
            };

            dispatch(leadsMiddleware.updateLeadDetails(changedLead));
            dispatch(fetchTags());
          }

          if (type === 'update') {
            const changedLead = {
              ...leadsDetails,
              tags: leadsDetails.tags.map((tag: ITag) =>
                tag.name === oldName ? { ...tag, name: tagName, color } : tag,
              ),
            };

            dispatch(leadsMiddleware.updateLeadDetails(changedLead));
          }

          break;
        }

        default:
          dispatch(fetchTags());
      }
    } catch (error) {
      console.log(error);
    }
  };

const fetchSelectTag =
  (
    tagName: string,
    color: number,
    targetId: string,
    page: number,
    type?: 'add' | 'create',
    setLoading?: (value: boolean) => void,
  ) =>
  async () => {
    try {
      dispatch(setTagsLoading(true));

      if (type === 'add') {
        await DataService.post(API_TAGS(), {
          name: tagName,
          color,
          targetId,
        });

        dispatch(fetchDataAfterTagsChange(page, targetId, 'add', tagName, color));

        if (setLoading) {
          setLoading(false);
        }
      } else {
        await DataService.post(API_TAGS(), {
          name: tagName,
          color,
          targetId: null,
        });

        await DataService.post(API_TAGS(), {
          name: tagName,
          color,
          targetId,
        });

        dispatch(fetchDataAfterTagsChange(page, targetId, 'add', tagName, color));

        dispatch(fetchTags());
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setTagsLoading(false));
    }
  };

const fetchChangeTag =
  (
    color: number,
    name: string,
    id: string,
    page: number,
    targetId: string,
    setLoading?: (value: boolean) => void,
    oldName?: string,
  ) =>
  async () => {
    try {
      dispatch(setTagsLoading(true));

      await DataService.patch(API_TAGS_CHANGE(id), {
        color,
        name,
        targetId: null,
      });

      dispatch(fetchDataAfterTagsChange(page, targetId, 'update', name, color, oldName));

      dispatch(fetchTags());

      if (setLoading) {
        setLoading(false);
      }
    } catch (error: any) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error?.response?.data,
          },
        }),
      );
    } finally {
      dispatch(setTagsLoading(false));
    }
  };

const fetchRemoveTag =
  (
    tagName: string,
    color: number,
    page: number,
    targetId: string,
    type?: 'remove' | 'delete',
    setLoading?: (value: boolean) => void,
  ) =>
  async () => {
    try {
      dispatch(setTagsLoading(true));

      await DataService.post(API_TAGS_REMOVE(), {
        name: tagName,
        color,
        targetId: type === 'remove' ? targetId : null,
      });

      if (type === 'remove') {
        dispatch(fetchDataAfterTagsChange(page, targetId, 'remove', tagName, color));
      } else {
        dispatch(viewsMiddleware.closeModal(ModalName.DeleteTagModal));
        dispatch(fetchDataAfterTagsChange(page, targetId, 'delete', tagName, color));
      }

      if (setLoading) {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setTagsLoading(false));
    }
  };

export default {
  fetchTags,
  fetchSelectTag,
  fetchChangeTag,
  fetchRemoveTag,
  fetchDataAfterTagsChange,
};
